import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { NetworkServicesService } from "../../../_services/network-services.service";
import { Observable } from "rxjs";
import { MessageService } from "primeng/api";
import { NetworkProductsService } from "../../../_services/network-products.service";
import { EndCustomerCircuitsService } from "src/app/features/main-application/_services/entities/end-customer-circuits.service";
import { TableMetaData } from "src/app/_share/_models/components/table-meta-data.model";

@Component({
	selector: "app-services-management-actions",
	templateUrl: "./services-management-actions.component.html",
	styleUrls: ["./services-management-actions.component.scss"],
})
export class ServicesManagementActionsComponent implements OnInit {
	data: any;
	selected_action: string = "";
	selectedDate: any;
	loading: boolean = false;
	dataToSubmit: any = {};
	products: any[] = [];
	selectedProduct: any;
	circuit_no: any;
	circuits: any[] = [];
	selectedCircuit: any;

	constructor(
		public config: DynamicDialogConfig,
		private nss: NetworkServicesService,
		private ref: DynamicDialogRef,
		private messageService: MessageService,
		private nps: NetworkProductsService,
		private eccs: EndCustomerCircuitsService
	) {}

	ngOnInit(): void {
		this.data = this.config.data.selectedRow;
		this.selected_action = this.config.data.selectedAction;
		this.getCircuits();
		this.getProducts();
		this.circuit_no = this.data.end_customer_circuit[0].circuit_no;
		this.selectedProduct = this.data.recurring_product.id;
	}

	getCircuits() {
		this.eccs.getEndCustomerCircuits().subscribe((res: any) => {
			this.circuits = res.data;
			console.log("circuits", this.circuits);
		});
	}

	getProducts() {
		const queryParams = {
			filter: ["MRC"], // Always include "MRC" in the filter
		};
		this.nps.getNetworkPortalProducts(queryParams).subscribe((res: any) => {
			this.products = res;
			console.log("products", this.products);
		});
	}

	submitAction() {
		if (this.selected_action !== "Create") {
			const actionMap: {
				[key: string]: (data: any, id: any) => Observable<any>;
			} = {
				activate: this.nss.activateNetworkService.bind(this.nss),
				regrade: this.nss.regradeNetworkService.bind(this.nss),
				suspend: this.nss.suspendNetworkService.bind(this.nss),
				cancel: this.nss.cancelNetworkService.bind(this.nss),
			};

			const action = actionMap[this.selected_action.toLowerCase()];
			this.dataToSubmit = {
				date_actioned: this.selectedDate,
				circuit_no: this.circuit_no,
				recurring_product_id: this.selectedProduct,
			};

			if (action) {
				this.loading = true;
				action(this.dataToSubmit, this.data.id).subscribe({
					next: (res) => {
						console.log(`${this.selected_action} success`, res);
						this.loading = false;
						this.ref.close(res);
						this.showSuccessMessage(
							`Service ${this.selected_action}d successfully
            `
						);
					},
					error: (err) => {
						console.error(`${this.selected_action} failed`, err);
						this.loading = false;
						// Optionally, show a toast message or alert
						this.showErrorMessage(
							`Failed to ${this.selected_action} the service. Please try again.`
						);
					},
				});
			}
		} else {
			this.loading = true;
			const data = {
				recurring_product_id: this.selectedProduct,
				date_activated: this.selectedDate,
			};
			this.nss.createNetworkService(data, this.selectedCircuit).subscribe({
				next: (res) => {
					console.log("Create success", res);
					this.loading = false;
					this.ref.close(res);
					this.showSuccessMessage("Service created successfully");
				},
				error: (err) => {
					console.error("Create failed", err);
					this.loading = false;
					// Optionally, show a toast message or alert
					this.showErrorMessage(
						"Failed to create the service. Please try again."
					);
				},
			});
		}
	}

	cancelAction() {
		this.ref.close();
	}

	private showErrorMessage(message: string) {
		this.messageService.add({
			severity: "error",
			summary: "Error",
			detail: message,
		});
	}

	private showSuccessMessage(message: string) {
		this.messageService.add({
			severity: "success",
			summary: "Success",
			detail: message,
		});
	}
}
