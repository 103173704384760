import { Injectable } from '@angular/core';

import { map } from 'rxjs';
import { ApiService } from 'src/app/_core/_services/api.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private api: ApiService) {}

  getAll() {
    return this.api.getAPI('users').pipe(
      map((data: any) => {
        return data.body;
      })
    );
  }

  createSystemUser(data: any) {
    return this.api.postAPI('user', data).pipe(
      map((data: any) => {
        return data.body;
      })
    );
  }

  updateSystemUser(data: any, id: number) {
    return this.api.patchAPI(`user/${id}`, data).pipe(
      map((data: any) => {
        return data.body;
      })
    );
  }

  disableSystemUser(id: number) {
    return this.api.patchAPI(`user/${id}/disable`, {}).pipe(
      map((data: any) => {
        return data.body;
      })
    );
  }
}
