<p-toast key="portal"></p-toast>
<ng-container *ngIf="portalConfig$ | async as portalConfig">
  <ng-container [ngSwitch]="vno">
    <!-- portal body -->
    <ng-container *ngSwitchCase="'Benington'">
      <!-- getting started section -->
      <div
        class="surface-section px-4 py-8 md:px-6 lg:px-8 geologica-benington"
      >
        <div class="w-full max-w-2xl">
          <div
            class="p-6 shadow-3 flex flex-column align-items-center justify-content-between"
            style="
              border-radius: 1rem;
              background: linear-gradient(
                  0deg,
                  rgba(99, 175, 237, 0.6),
                  rgba(73, 129, 236, 0.4)
                ),
                linear-gradient(92.54deg, #4fadea 47.88%, #ffffff 100.01%);
            "
          >
            <div class="pr-6">
              <div>
                <img
                  src="/assets/portal/images/benington/park-bench.svg"
                  height="64px"
                />
              </div>
              <div
                *ngIf="!(portalUser$ | async)"
                class="text-blue-100 font-medium text-xl my-2"
              >
                Welcome to
              </div>
              <div
                *ngIf="portalUser$ | async as user"
                class="text-blue-100 font-medium text-xl my-2"
              >
                <!-- todo: retrieve customer details -->
                Hi {{ user.first_name }}, welcome to
              </div>
              <ng-container *ngIf="!isParkLoading; else parkName">
                <p-skeleton height="3rem"></p-skeleton>
              </ng-container>
              <ng-template #parkName>
                <div
                  class="text-white font-medium text-5xl geologica-benington"
                >
                  {{ this.park?.name }}
                </div>
              </ng-template>
              <!-- <img src="/assets/portal/images/benington/benington-logo.svg" alt="Benington" height="100px" /> -->
              <p class="p-1 text-lg text-gray-800 text-center">
                Choose whether you are a new or returning customer and let's get
                you connected to our Wi-Fi network.
              </p>
              <!-- <div>
                <img src='/assets/portal/images/benington/guest-wifi.svg' height="64px" />
              </div> -->
            </div>
            <div
              *ngIf="!(portalUser$ | async)"
              class="mt-4 mr-auto md:mt-0 md:mr-0 mb-4 align-content-center align-items-center justify-content-center"
            >
              <button
                pButton
                pRipple
                label="New Customer"
                (click)="showRegisterDialogUK($event, 'register')"
                class="font-bold px-5 py-3 mx-1 my-1 lg:my-0 p-button-info bg-pink-500 p-button-rounded p-button-raised white-space-nowrap hover:bg-pink-200"
              ></button>
              <button
                pButton
                pRipple
                label="Returning Customer"
                (click)="showLoginDialogUK($event)"
                class="font-bold px-5 py-3 mx-1 my-1 lg:my-0 p-button-info bg-pink-500 p-button-rounded p-button-raised white-space-nowrap hover:bg-pink-200"
              ></button>
            </div>
            <div
              *ngIf="portalUser$ | async"
              class="mt-4 mr-auto md:mt-0 md:mr-0 mb-4 align-content-center"
            >
              <button
                pButton
                pRipple
                label="Activate Wi-Fi Access"
                (click)="submitAuthUK($event)"
                class="font-bold px-5 py-3 mx-1 my-1 lg:my-0 p-button-info bg-pink-500 p-button-rounded p-button-raised white-space-nowrap hover:bg-pink-200"
              ></button>
              <button
                pButton
                pRipple
                label="Purchase Wi-Fi Access"
                (click)="showPurchaseDialogUK($event)"
                class="font-bold px-5 py-3 mx-1 my-1 lg:my-0 p-button-info bg-pink-500 p-button-rounded p-button-raised white-space-nowrap hover:bg-pink-200"
              ></button>
              <!-- todo: sign out dialog -->
              <button
                pButton
                pRipple
                label="Logout"
                (click)="showSignOutDialog($event)"
                class="font-bold px-5 py-3 mx-1 my-1 lg:my-0 p-button-info bg-pink-500 p-button-rounded p-button-raised white-space-nowrap hover:bg-pink-200"
              ></button>
            </div>
            <div
              class="p-6 shadow-3 flex flex-column flex-row align-items-center justify-content-between mt-4"
              style="
                border-radius: 1rem;
                background: linear-gradient(
                    0deg,
                    rgba(79, 173, 234, 0.5),
                    rgba(255, 255, 255, 0.5)
                  ),
                  linear-gradient(92.54deg, #ffffff 47.88%, #ffffff 100.01%);
              "
            >
              <div class="pr-6">
                <!-- <div class="text-blue-100 font-medium text-xl mb-3">Welcome to</div> -->
                <div class="text-primary font-medium text-2xl">
                  Getting stuck?
                </div>
                <p class="p-1 text-lg text-gray-600 text-center">
                  Contact our support team for assistance by phoning or sending
                  us an email
                </p>
              </div>
              <div class="mt-4 mr-auto md:mt-0 md:mr-0 mb-4">
                <a
                  pButton
                  pRipple
                  type="button"
                  label="Phone Support"
                  class="align-content-end p-button-link p-button-outlined mx-2 my-1 lg:my-0"
                  icon="pi pi-phone"
                  href="tel:+441327860864"
                  style="text-decoration: none"
                ></a>
                <a
                  pButton
                  pRipple
                  type="button"
                  label="Email Support"
                  class="p-button-link p-button-outlined mx-2 my-1 lg:my-0"
                  icon="pi pi-envelope"
                  href="mailto:support@benington.net"
                  style="text-decoration: none"
                  target="_blank"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="surface-section px-4 py-1 md:px-6 lg:px-8"></div>
      <div
        class="surface-section p-3 flex-grow-1 flex flex-column justify-content-center align-items-center min-h-full"
        *ngIf="false"
      >
        <div class="flex align-items-center justify-content-center">
          <h3 class="text-2xl text-gray-500"></h3>
        </div>
        <div class="flex align-items-center justify-content-center">
          <p class="p-4 text-lg text-gray-800 text-center">
            You can either register and purchase a new package or activate your
            Wi-Fi access using your voucher code.
          </p>
        </div>
        <div class="flex">
          <div
            class="w-12 py-8 text-center flex align-items-center justify-content-center"
          >
            <ul class="list-none p-0 m-0">
              <li class="pb-2 surface-border">
                <button
                  pButton
                  pRipple
                  label="Register and Purchase Wi-Fi Access"
                  icon="pi pi-money-bill"
                  class="p-button-outlined p-button-lg p-button-info"
                  (click)="showRegisterDialogUK($event, 'register')"
                ></button>
              </li>
              <li class="pb-2 surface-border" *ngIf="false">
                <button
                  pButton
                  pRipple
                  label="Top Up Wi-Fi Access"
                  icon="pi pi-money-bill"
                  class="p-button-outlined p-button-lg p-button-info"
                  (click)="showRegisterDialogUK($event, 'topup')"
                ></button>
              </li>
              <li class="py-4">
                <button
                  pButton
                  pRipple
                  label="Activate Wi-Fi Access"
                  class="p-button-success p-button-outlined p-button-lg"
                  (click)="showAuthDialogUK($event)"
                ></button>
              </li>

              <!-- <li class="pb-4 pt-4 border-top-1 surface-border"> -->
              <!-- <button
                *ngIf="!(portalUser$ | async)"
                pButton
                pRipple
                label="Sign In"
                icon="pi pi-user"
                class="mx-2  p-button-success p-button-lg"
                (click)="showSignInDialog($event)"
              ></button> -->
              <!-- <button
                  *ngIf="!(portalUser$ | async)"
                  pButton
                  pRipple
                  label="Register"
                  icon="pi pi-user-plus"
                  class="mx-2 p-button-success p-button-lg"
                  (click)="showRegisterDialogUK($event)"
                ></button>
                <button
                  *ngIf="portalUser$ | async"
                  pButton
                  pRipple
                  label="Sign Out"
                  icon="pi pi-user-plus"
                  class="mx-2 p p-button-success p-button-lg"
                  (click)="showSignOutDialog($event)"
                ></button>
                <button
                  *ngIf="portalUser$ | async"
                  pButton
                  pRipple
                  label="Go to Profile"
                  icon="pi pi-th-large"
                  class="mx-2 p-button-rounded p-button-info p-button-lg p-button-outlined"
                  [routerLink]="['/portal/profile']"
                ></button> -->
              <!-- </li> -->
            </ul>
          </div>
        </div>
      </div>
      <div
        class="surface-section p-3 flex-grow-1 flex flex-row justify-content-center"
      ></div>
    </ng-container>

    <ng-container *ngSwitchCase="'MTD'">
      <div
        class="surface-section p-3 flex-grow-1 flex flex-column justify-content-center align-content-center min-h-full"
      >
        <div class="flex">
          <div
            class="w-12 py-8 text-center flex align-items-center justify-content-center"
          >
            <ul class="list-none p-0 m-0">
              <li class="pb-4 pt-4 surface-border">
                <img
                  src="/assets/portal/images/mtd/billy.svg"
                  alt="Billy"
                  height="100px"
                />
              </li>
              <li class="py-5 surface-border" *ngIf="false">
                <button
                  pButton
                  pRipple
                  label="Purchase Prepaid Access"
                  class="p-button p-button-rounded p-button-lg bg-purple-600 p-4"
                  routerLink="/portal/purchase/package"
                ></button>
                <!-- <p class="text-purple-400">
                    You need to sing in or sign up to purchase a package...
                  </p> -->
              </li>

              <li class="pb-4 pt-4 border-top-1 surface-border" *ngIf="true">
                <button
                  *ngIf="!(portalUser$ | async)"
                  pButton
                  pRipple
                  label="Sign In"
                  icon="pi pi-user"
                  class="mx-2 p-button-rounded p-button-success p-button-lg bg-blue-500 p-4"
                  (click)="showSignInDialog($event)"
                ></button>
                <button
                  *ngIf="!(portalUser$ | async)"
                  pButton
                  pRipple
                  label="Sign Up"
                  icon="pi pi-user-plus"
                  class="mx-2 p-button-rounded p-button-success p-button-lg bg-blue-500 p-4"
                  (click)="showSignUpDialog($event)"
                ></button>
                <button
                  *ngIf="portalUser$ | async"
                  pButton
                  pRipple
                  label="Sign Out"
                  icon="pi pi-user-plus"
                  class="p-button p-button-rounded p-button-lg bg-purple-600 p-4"
                  (click)="showSignOutDialog($event)"
                ></button>
                <button
                  *ngIf="portalUser$ | async"
                  pButton
                  pRipple
                  label="Go to Profile"
                  icon="pi pi-th-large"
                  class="mx-2 p-button-rounded p-button-info p-button-lg p-button-outlined p-4"
                  [routerLink]="['/portal/profile']"
                ></button>
              </li>

              <li class="py-4">
                <button
                  pButton
                  pRipple
                  label="Activate Wi-Fi Access"
                  class="p-button p-button-rounded p-button-outlined p-button-lg p-4"
                  (click)="showAuthDialogZA($event)"
                ></button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'Stem'">
      <div
        class="surface-section p-3 min-h-full flex-column justify-content-center align-content-center"
      >
        <div class="flex">
          <div
            class="w-12 py-8 text-center flex align-items-center justify-content-center"
          >
            <ul class="list-none p-0 m-0">
              <li class="pb-2 surface-border">
                <button
                  pButton
                  pRipple
                  label="Purchase Internet Access"
                  icon="pi pi-money-bill"
                  class="p-button-rounded p-button-outlined p-button-lg"
                  routerLink="/portal/purchase/package"
                ></button>
              </li>

              <li class="py-4">
                <button
                  pButton
                  pRipple
                  label="Complimentary Access"
                  class="p-button-rounded p-button-success p-button-outlined p-button-lg"
                ></button>
              </li>

              <li class="pb-4 pt-4 border-top-1 surface-border">
                <button
                  *ngIf="!(portalUser$ | async)"
                  pButton
                  pRipple
                  label="Sign In"
                  icon="pi pi-user"
                  class="mx-2 p-button-rounded p-button-success p-button-lg"
                  (click)="showSignInDialog($event)"
                ></button>
                <button
                  *ngIf="!(portalUser$ | async)"
                  pButton
                  pRipple
                  label="Sign Up"
                  icon="pi pi-user-plus"
                  class="mx-2 p-button-rounded p-button-success p-button-lg"
                  (click)="showSignUpDialog($event)"
                ></button>
                <button
                  *ngIf="portalUser$ | async"
                  pButton
                  pRipple
                  label="Sign Out"
                  icon="pi pi-user-plus"
                  class="mx-2 p-button-rounded p-button-success p-button-lg"
                  (click)="showSignOutDialog($event)"
                ></button>
                <button
                  *ngIf="portalUser$ | async"
                  pButton
                  pRipple
                  label="Go to Profile"
                  icon="pi pi-th-large"
                  class="mx-2 p-button-rounded p-button-info p-button-lg p-button-outlined"
                  [routerLink]="['/portal/profile']"
                ></button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'Finbond'">
      <div
        class="surface-section p-3 min-h-full flex-column justify-content-center align-content-center"
      >
        <div class="flex">
          <div
            class="w-12 py-8 text-center flex align-items-center justify-content-center"
          >
            <ul class="list-none p-0 m-0">
              <li class="py-4">
                <button
                  pButton
                  pRipple
                  label="Complimentary Wi-Fi Access"
                  (click)="showGuestAcknowledgeDialog($event)"
                  class="p-button-success p-button-lg bg-blue-600 p-4"
                ></button>
              </li>

              <li class="pb-4 pt-4 border-top-1 surface-border" *ngIf="false">
                <button
                  *ngIf="!(portalUser$ | async)"
                  pButton
                  pRipple
                  label="Sign In"
                  icon="pi pi-user"
                  class="mx-2 p-button-rounded p-button-success p-button-lg"
                  (click)="showSignInDialog($event)"
                ></button>
                <button
                  *ngIf="!(portalUser$ | async)"
                  pButton
                  pRipple
                  label="Sign Up"
                  icon="pi pi-user-plus"
                  class="mx-2 p-button-rounded p-button-success p-button-lg"
                  (click)="showSignUpDialog($event)"
                ></button>
                <button
                  *ngIf="portalUser$ | async"
                  pButton
                  pRipple
                  label="Sign Out"
                  icon="pi pi-user-plus"
                  class="mx-2 p-button-rounded p-button-success p-button-lg"
                  (click)="showSignOutDialog($event)"
                ></button>
                <button
                  *ngIf="portalUser$ | async"
                  pButton
                  pRipple
                  label="Go to Profile"
                  icon="pi pi-th-large"
                  class="mx-2 p-button-rounded p-button-info p-button-lg p-button-outlined"
                  [routerLink]="['/portal/profile']"
                ></button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div
        class="surface-section p-3 flex-grow-1 flex flex-column justify-content-center"
      >
        <div class="flex">
          <div
            class="w-12 py-8 text-center flex align-items-center justify-content-center"
          >
            <ul class="list-none p-0 m-0">
              <li class="pb-2 surface-border">
                <button
                  pButton
                  pRipple
                  label="Purchase Internet Access"
                  icon="pi pi-money-bill"
                  class="p-button-rounded p-button-outlined p-button-lg"
                  routerLink="/portal/purchase/package"
                ></button>
              </li>
              <!-- <li class="py-4 border-bottom-1 surface-border">
        <button
          pButton
          pRipple
          label="Activate Access with Voucher"
          icon="pi pi-credit-card"
          class="p-button-rounded p-button-outlined p-button-lg"
        ></button>
      </li> -->
              <li class="py-4">
                <button
                  pButton
                  pRipple
                  label="Complimentary Access"
                  class="p-button-rounded p-button-success p-button-outlined p-button-lg"
                ></button>
              </li>
              <!-- <li class="pt-4">
        <button
          pButton
          pRipple
          label="Education Channel"
          class="p-button-rounded p-button-success p-button-outlined p-button-lg"
        ></button>
      </li> -->
              <li class="pb-4 pt-4 border-top-1 surface-border">
                <button
                  *ngIf="!(portalUser$ | async)"
                  pButton
                  pRipple
                  label="Sign In"
                  icon="pi pi-user"
                  class="mx-2 p-button-rounded p-button-success p-button-lg"
                  (click)="showSignInDialog($event)"
                ></button>
                <button
                  *ngIf="!(portalUser$ | async)"
                  pButton
                  pRipple
                  label="Sign Up"
                  icon="pi pi-user-plus"
                  class="mx-2 p-button-rounded p-button-success p-button-lg"
                  (click)="showSignUpDialog($event)"
                ></button>
                <button
                  *ngIf="portalUser$ | async"
                  pButton
                  pRipple
                  label="Sign Out"
                  icon="pi pi-user-plus"
                  class="mx-2 p-button-rounded p-button-success p-button-lg"
                  (click)="showSignOutDialog($event)"
                ></button>
                <button
                  *ngIf="portalUser$ | async"
                  pButton
                  pRipple
                  label="Go to Profile"
                  icon="pi pi-th-large"
                  class="mx-2 p-button-rounded p-button-info p-button-lg p-button-outlined"
                  [routerLink]="['/portal/profile']"
                ></button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <!-- <div *ngIf="vno == 'Benington'; else otherVNOFooter">
    <div
      class="surface-100 px-4 py-4 md:px-6 lg:px-8 flex flex-column justify-content-between align-items-center"
    >
      <span class="text-600"
        >© {{ getCurrentYear() }}, Captive Portal. Powered by Benington
        Networks.</span
      >
    </div>
  </div>

  <ng-template #otherVNOFooter> </ng-template>
</ng-container> -->
</ng-container>
<!-- 07539019768 07539019768 07375871617 -->
