<!-- heading section -->
<div class="flex geologica-benington">
  <div class="flex-1">
    <h1 class="text-3xl font-semibold text-blue-400">
      Login to access WiFi at
      <span class="font-bold text-green-500">{{
        config.data.dialogDetails.park.name
      }}</span>
    </h1>

    <p class="text-lg text-gray-600">
      Please log in using form below to access or purchase WiFi at
      <span class="font-bold">{{ config.data.dialogDetails.park.name }}</span>
    </p>
  </div>
  <div class="flex">
    <button
      pButton
      class=""
      icon="pi pi-times"
      class="p-button-rounded p-button-danger mt-2 p-button-text"
      (click)="closeLoginDialog()"
    ></button>
  </div>
</div>

<!-- form section -->
<div class="geologica-benington">
  <form [formGroup]="portalLoginForm" (ngSubmit)="onLoginSubmit()">
    <div class="formgrid grid p-fluid">
      <!-- username field -->
      <div class="field col-12">
        <label for="username" class="block text-900 font-medium mb-2"
          >Email</label
        >
        <input
          pInputText
          pAutoFocus
          type="email"
          formControlName="username"
          class="form-control"
          class="w-full"
          (blur)="f['username'].markAsDirty()"
          pKeyFilter="email"
          [autofocus]="true"
        />
        <small
          *ngIf="
            f['username'].hasError('required') &&
            f['username'].touched &&
            f['username'].invalid
          "
          id="email-help"
          class="p-error"
          >This field is required</small
        >
        <small
          *ngIf="
            f['username'].hasError('email') &&
            f['username'].touched &&
            f['username'].invalid
          "
          id="email-help"
          class="p-error"
          >Please enter a valid email address</small
        >
        <!-- <small *ngIf="submitted && f.username.errors && false" class="p-error"
          >Incorrect Username</small
        > -->
      </div>

      <!-- password field -->
      <div class="field col-12">
        <label for="password" class="block text-900 font-medium mb-2"
          >Password</label
        >
        <input
          type="password"
          formControlName="password"
          class="form-control"
          pInputText
          class="w-full"
          (blur)="f['password'].markAsDirty()"
        />
        <small
          *ngIf="
            f['password'].hasError('required') &&
            f['password'].touched &&
            f['password'].invalid
          "
          id="email-help"
          class="p-error"
          >This field is required</small
        >
        <small
          *ngIf="
            f['password'].hasError('minLength') &&
            f['password'].touched &&
            f['password'].invalid
          "
          id="password-help"
          class="p-error"
          >The value entered is too short</small
        >
        <!-- <small
          *ngIf="submitted && f.password.errors && errorMessages"
          class="p-error"
          >Incorrect Password</small
        > -->
      </div>

      <div class="field lg:col-6 col-12 lg:mt-4">
        <button
          pButton
          pRipple
          [disabled]="loading"
          label="Sign In"
          class="w-full p-button-info bg-pink-500 p-button-rounded p-button-raised white-space-nowrap hover:bg-pink-200 mb-3 px-5 py-3 mx-1 my-1 lg:my-0"
          icon="pi pi-user"
          type="submit"
          [loading]="loading"
        ></button>
      </div>
      <div class="field lg:col-6 col-12 lg:mt-4">
        <button
          pButton
          pRipple
          [disabled]="false"
          label="Forgot Password?"
          class="w-full p-button-info p-button-rounded p-button-raised p-buttton-large p-button-outlined mb-3 px-5 py-3 mx-1 my-1 lg:my-0 geologica-benington hover:bg-blue-400 hover:text-white"
          icon="pi pi-question"
          type="button"
          (click)="openPasswordResetDialog($event)"
          [loading]="loading"
        ></button>
      </div>
      <div class="field col-12" *ngIf="errorMessages">
        <!-- <div class="flex align-items-center justify-content-between"> -->
        <p-message severity="error" [text]="errorMessages"></p-message>
        <!-- </div> -->

        <!-- <span class="text-red-500 font-bold pt-3">{{ errorMessages }}</span> -->
        <!-- </div> -->
      </div>
    </div>
    <!-- <div *ngIf="error" class="alert alert-danger mt-3 mb-0">
      {{ error | json }}
    </div> -->
  </form>
</div>
