import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { PortalUsersService } from '../../_services/portal-users.service';
import { MessageService } from 'primeng/api';
import { PortalAuthenticationService } from '../../_services/portal-authentication.service';
import { Router } from '@angular/router';
import { catchError, concatMap, of } from 'rxjs';

@Component({
  selector: 'app-portal-password-reset',
  templateUrl: './portal-password-reset.component.html',
  styleUrls: ['./portal-password-reset.component.scss'],
})
export class PortalPasswordResetComponent implements OnInit {
  constructor(
    public fb: FormBuilder,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private pus: PortalUsersService,
    public messageService: MessageService,
    private pas: PortalAuthenticationService,
    private router: Router,
  ) {}

  ukPhoneNumberRegex: RegExp = /^(?:\+44|0)(?:\d\s?){9,10}\d$/;

  portalPasswordResetForm = this.fb.group({
    username: ['', Validators.required],
    first_name: ['', Validators.required],
    mobile_number: [
      '',
      [Validators.required, Validators.pattern(this.ukPhoneNumberRegex)],
    ],
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirm_password: ['', [Validators.required, Validators.minLength(6)]],
  });

  loading = false;
  submitted = false;
  error = '';
  errorMessages: any;

  get f() {
    return this.portalPasswordResetForm.controls;
  }

  ngOnInit(): void {
    console.log(this.config.data.username);

    // set focus to form control on dialog open
    if (this.config.data.username) {
      this.portalPasswordResetForm.patchValue({
        username: this.config.data.username,
      });
    }
  }

  closePasswordResetDialog() {
    this.ref.close();
  }

  onPasswordResetSubmit() {
    console.log('Form valid: ', this.portalPasswordResetForm.valid);
    console.log('Form values: ', this.portalPasswordResetForm.value);
    console.log('Form errors: ', this.portalPasswordResetForm.errors);

    if (this.portalPasswordResetForm.invalid) {
      this.error = 'Please fill in all required fields';
      return;
    }

    if (this.portalPasswordResetForm.valid) {
      this.loading = true;
      this.submitted = true;
      this.error = '';
      this.errorMessages = {};

      this.pus
        .resetPasswordCombination(this.portalPasswordResetForm.value)
        .pipe(
          concatMap((resetResponse) => {
            console.log('Password reset response:', resetResponse);

            // Perform the login after the password reset
            return this.pas.login(
              this.portalPasswordResetForm.value.username,
              this.portalPasswordResetForm.value.password,
            );
          }),
          catchError((error) => {
            console.error('Error during reset or login:', error);
            this.error = error.error?.detail || 'An unexpected error occurred';
            this.loading = false;
            this.submitted = false;

            // Show error message
            this.messageService.add({
              key: 'portal',
              severity: 'error',
              summary: 'Error',
              detail: this.error,
            });

            // Return an empty observable to terminate further processing
            return of(null);
          }),
        )
        .subscribe(
          (loginResponse) => {
            if (loginResponse) {
              console.log('Login response:', loginResponse);
              this.loading = false;
              this.submitted = false;

              this.messageService.add({
                key: 'portal',
                severity: 'success',
                summary: 'Success',
                detail: 'Password was reset and login was successful',
              });
              this.loading = false;
              this.submitted = false;

              this.ref.close();
              this.router.navigate(['/portal']);
            }
          },
          () => {
            // This block only runs if there is an unhandled error, which should not happen due to `catchError`.
          },
        );
    }
  }
}
