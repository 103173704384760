import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ApiService } from 'src/app/_core/_services/api.service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationOptionsService {
  constructor(private api: ApiService) {}

  getRegistrationOptions(queryParams: any) {
    if (queryParams) {
      queryParams = {
        ...queryParams,
      };
    } else {
      queryParams = {
        limit: 2000,
        offset: 0,
        option_type: 'suburb',
      };
    }
    return this.api
      .getAPI('portal/management/registration-options', queryParams)
      .pipe(
        map((res: any) => {
          return res.body.data;
        })
      );
  }

  addRegistrationOption(data: any) {
    return this.api.postAPI('portal/management/registration-options', data);
  }

  updateRegistrationOption(data: any, registration_option_id: string) {
    return this.api.patchAPI(`portal/management/registration-options/${registration_option_id}`, data);
  }

  deleteRegistrationOption(registration_option_id: number) {
    return this.api.deleteAPI(`portal/management/registration-options`, registration_option_id);
  }
}
