import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-create-option-dialog',
  templateUrl: './create-option-dialog.component.html',
  styleUrls: ['./create-option-dialog.component.scss'],
})
export class CreateOptionDialogComponent implements OnInit {
  registrationOptionForm = this.fb.group({
    label: ['', Validators.required],
    value: ['', Validators.required],
    option_type: ['suburb', Validators.required],
  });

  optionTypes: any[] = [
    { label: 'Suburb', value: 'suburb' },
    { label: 'Complex', value: 'complex' },
    { label: 'Agent', value: 'agent' },
  ];

  constructor(private fb: FormBuilder, private ref: DynamicDialogRef) {}

  ngOnInit(): void {}

  onRegistrationOptionSubmit() {
    console.log(this.registrationOptionForm.value);

    // mark all fields as touched
    this.registrationOptionForm.markAllAsTouched();

    // is valid
    if (this.registrationOptionForm.valid) {
      this.ref.close(this.registrationOptionForm.value);
    }
  }
}
