import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { NotificationService } from 'src/app/_share/_services/notification.service';
import { ParksService } from '../../_services/parks.service';
import { PortalRegisterService } from '../../_services/portal-register.service';
import {
  StripeCardElementChangeEvent,
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import {
  switchMap,
  tap,
  of,
  catchError,
  finalize,
  Observable,
  map,
  BehaviorSubject,
  concatMap,
  throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { PortalAuthenticationService } from '../../_services/portal-authentication.service';

@Component({
  selector: 'app-portal-purchase-uk',
  templateUrl: './portal-purchase-uk.component.html',
  styleUrls: ['./portal-purchase-uk.component.scss'],
})
export class PortalPurchaseUkComponent implements OnInit {
  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private stripeService: StripeService,
    private userRegisterService: PortalRegisterService,
    private notificationService: NotificationService,
    private parksService: ParksService,
    private pas: PortalAuthenticationService,
  ) {}
  // variables
  // ---------
  @ViewChild(StripeCardComponent) card!: StripeCardComponent;
  errorMessage: string = '';
  selectedPackage: any;
  portalUser: any;
  packages: any[] = [
    {
      name: '1 Day - 10 Devices',
      value: '1day-10device',
      price: 6,
      enabled: true,
    },
    {
      name: '3 Days - 10 Devices',
      value: '3day-10device',
      price: 14,
      enabled: true,
    },
    {
      name: '7 Days - 10 Devices',
      value: '7day-10device',
      price: 23,
      enabled: true,
    },
    {
      name: '30 Days - 10 Devices',
      value: '30day-10device',
      price: 45,
    },
    {
      name: 'Seasonal - 10 Devices',
      value: 'Season-10device',
      price: 384,
      enabled: true,
    },
  ];

  public cardOptions: StripeCardElementOptions = {
    disableLink: true,
    hidePostalCode: true,
    style: {
      invalid: {
        color: '#FF0000',
      },
    },

    classes: {
      base: 'p-inputtext',
      complete: 'is-valid',
      empty: 'is-invalid',
      focus: 'is-valid',
      invalid: 'is-invalid',
      webkitAutofill: 'is-valid',
    },
  };

  public elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  purchaseSuccess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  stripe = this.stripeService.stripe;

  paymentLoading: boolean = false;

  paymentForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    amount: [0, Validators.required],
    plan: ['', Validators.required],
    account_code: [''],
  });

  paymentAmount: number = 0.0;

  // methods
  // -------

  ngOnInit(): void {
    this.errorMessage = '';
    if (this.config.data.dialogDetails.park.name == 'Quarry Walk Park') {
      this.packages = [
        {
          name: '1 Day - 5 Devices',
          value: '1day-5device',
          price: 10,
          enabled: true,
        },
        {
          name: '3 Days - 5 Devices',
          value: '3day-5device',
          price: 15,
          enabled: true,
        },
        {
          name: '7 Days - 5 Devices',
          value: '7day-5device',
          price: 25,
          enabled: true,
        },
      ];
    }

    if (this.config.data.portalUser) {
      this.portalUser = this.config.data.portalUser;
    }
  }

  onPay(): void {
    this.paymentLoading = true;
    console.log('Payment form portal user', this.portalUser);

    this.paymentForm.patchValue({
      name:
        this.portalUser.first_name.trim() +
        ' ' +
        this.portalUser.last_name.trim(),
      email: this.portalUser.email,
      amount: this.paymentAmount,
    });

    const paymentFormForSubmit = {
      amount: this.paymentAmount,
      currency: 'gbp',
      email: this.portalUser.email,
      first_name: String(this.portalUser.first_name).trim(),
      last_name: String(this.portalUser.last_name).trim(),
      plan: this.selectedPackage.value,
      mobile_number: String(this.portalUser.mobile_number),
      account_code: '',
    };

    if (!this.paymentForm.valid) {
      this.errorMessage = 'Please check the form for errors.';
      this.paymentLoading = false;

      console.log('Form is invalid', this.paymentForm);
      return;
    }

    if (this.paymentForm.valid) {
      // concat observables to ensure the sequence of operations
      this.parksService
        .findUser({ email: this.portalUser.email })
        .pipe(
          concatMap((user) => {
            if (!user) {
              this.errorMessage = 'User not found, please register first';
              throw new Error('User not found');
            }

            // Step 1: Create Payment Intent
            return this.createPaymentIntent(paymentFormForSubmit);
          }),

          // Step 1: Create Payment Intent
          concatMap((pi) => {
            if (!pi || !pi.client_secret) {
              this.errorMessage = 'Failed to create payment intent';
              this.paymentLoading = false;

              throw new Error('Failed to create payment intent');
            }
            // Step 2: Confirm Card Payment
            return this.stripeService.confirmCardPayment(pi.client_secret, {
              payment_method: {
                card: this.card.element,
                billing_details: {
                  name: `${paymentFormForSubmit.first_name} ${paymentFormForSubmit.last_name}`,
                  email: paymentFormForSubmit.email,
                  phone: this.portalUser.mobile_number,
                },
              },
            });
          }),
          concatMap((result: any) => {
            if (result.error) {
              console.error(result.error.message);
              this.errorMessage = result.error.message;
              throw new Error(result.error.message);
            } else if (result.paymentIntent.status !== 'succeeded') {
              throw new Error('Payment was not successful');
            }

            console.log('Payment Intent Status:', result.paymentIntent.status);
            if (result.paymentIntent.status === 'succeeded') {
              console.log('Payment Successful');

              // Step 3: Buy Package and Register User
              return this.parksService.buyPackage({
                ...paymentFormForSubmit,
                park_name: this.config.data.dialogDetails.park.name,
                product: this.selectedPackage,
              });
            } else {
              return throwError(() => new Error('Payment was not completed.'));
            }
          }),
          catchError((err) => {
            console.error('Error during payment process:', err);
            this.paymentLoading = false;
            this.errorMessage = err.message || 'An unexpected error occurred.';
            return of(null); // Handle the error without breaking the observable chain
          }),
        )
        .subscribe((res) => {
          if (res) {
            console.log('Package Purchase Response:', res);
            this.paymentLoading = false;

            // Send data to status dialog
            this.ref.close({
              ...res.data,
              status: res.status,
              success: true,
              package: this.getPackageDetails(this.selectedPackage),
              customer: this.portalUser.value,
            });
          }
        });
    } else {
      console.log('Invalid Payment Form', this.paymentForm);
    }
  }

  /*
  @deprecated
  */
  payOld(): void {
    this.paymentLoading = true;
    console.log('payment form portal user', this.portalUser);

    this.paymentForm.patchValue({
      name:
        this.portalUser.first_name.trim() +
        ' ' +
        this.portalUser.last_name.trim(),
      email: this.portalUser.email,
      amount: this.paymentAmount,
    });

    // this.paymentLoading = false;
    // return;

    const paymentFormForSubmit = {
      amount: this.paymentAmount,
      currency: 'gbp',
      email: this.portalUser.email,
      first_name: String(this.portalUser.first_name).trim(),
      last_name: String(this.portalUser.last_name).trim(),
      plan: this.selectedPackage.value,
      mobile_number: String(this.portalUser.mobile_number),
      account_code: '',
    };

    console.log('validity', this.paymentForm.valid);
    console.log('card', this.card);
    if (this.paymentForm.valid) {
      this.createPaymentIntent(paymentFormForSubmit)
        .pipe(
          switchMap((pi) =>
            this.stripeService.confirmCardPayment(pi.client_secret, {
              payment_method: {
                card: this.card.element,
                billing_details: {
                  name:
                    paymentFormForSubmit.first_name +
                    ' ' +
                    paymentFormForSubmit.last_name,
                  email: paymentFormForSubmit.email,
                  phone: this.portalUser.mobile_number,
                },
              },
            }),
          ),
        )
        .subscribe((result: any) => {
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            console.log(result.error.message);
            window.alert('Payment was not successful');

            // todo: show customer failed dialog
          } else {
            // The payment has been processed!
            console.log('result', result);
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer
              console.log('success');

              // this.ref.close(this.portalUserRegisterForm.value);
            }
            console.log('package details', this.selectedPackage);
            // todo: show customer success dialog
            this.parksService
              .buyPackageAndRegisterUser({
                ...this.portalUser.value,
                park_name: this.config.data.dialogDetails.park.name,
                product: this.selectedPackage,
              })
              .subscribe(
                (res) => {
                  console.log('res', res);
                  this.paymentLoading = false;

                  // send data to status dialog
                  this.ref.close({
                    ...res.data,
                    status: res.status,
                    success: true,
                    package: this.getPackageDetails(this.selectedPackage),
                    customer: this.portalUser.value,
                  });
                },
                (err) => {
                  console.log('err', err);
                  this.errorMessage =
                    err.error.detail.detail + ' - ' + err.error.detail.message;
                  this.paymentLoading = false;
                  // this.notificationService.failedPortal();
                },
              );
          }
        });
    } else {
      console.log(this.paymentForm);
    }
  }

  // pay(): void {
  //   this.paymentLoading = true;
  //   this.errorMessage = ''; // Reset error message at the start
  //   this.paymentForm.patchValue({
  //     name: `${this.portalUserRegisterForm.get('first_name')?.value} ${this.portalUserRegisterForm.get('last_name')?.value}`,
  //     email: this.portalUserRegisterForm.get('email')?.value,
  //     amount: this.paymentAmount,
  //   });

  //   const paymentFormForSubmit = {
  //     amount: this.paymentAmount,
  //     currency: 'gbp',
  //     email: this.portalUserRegisterForm.get('email')?.value,
  //     first_name: String(
  //       this.portalUserRegisterForm.get('first_name')?.value || '',
  //     ).trim(),
  //     last_name: String(
  //       this.portalUserRegisterForm.get('last_name')?.value || '',
  //     ).trim(),
  //     plan: this.selectedPackage.value,
  //     mobile_number: String(
  //       this.portalUserRegisterForm.get('mobile_number')?.value || '',
  //     ),
  //     account_code: '',
  //   };

  //   if (this.paymentForm.valid) {
  //     this.parksService
  //       .buyPackageAndRegisterUser({
  //         ...this.portalUserRegisterForm.value,
  //         park_name: this.config.data.dialogDetails.park.name,
  //         product: this.selectedPackage,
  //       })
  //       .pipe(
  //         switchMap((res) =>
  //           this.createPaymentIntent(paymentFormForSubmit).pipe(
  //             switchMap((pi) =>
  //               this.stripeService
  //                 .confirmCardPayment(pi.client_secret, {
  //                   payment_method: {
  //                     card: this.card.element,
  //                     billing_details: {
  //                       name:
  //                         paymentFormForSubmit.first_name +
  //                         ' ' +
  //                         paymentFormForSubmit.last_name,
  //                       email: paymentFormForSubmit.email,
  //                       phone:
  //                         this.portalUserRegisterForm.get('mobile_number')
  //                           ?.value,
  //                     },
  //                   },
  //                 })
  //                 .pipe(
  //                   tap((result: any) => {
  //                     if (result.error) {
  //                       console.error(result.error.message);
  //                       this.errorMessage = result.error.message; // Display error message on the form
  //                       throw new Error(result.error.message); // Skip further steps on payment failure
  //                     } else if (result.paymentIntent.status === 'succeeded') {
  //                       console.log('Payment successful');
  //                     }
  //                   }),
  //                   switchMap(() => {
  //                     // Return package details on successful payment
  //                     return of({
  //                       ...res.data,
  //                       status: res.status,
  //                       success: true,
  //                       package: this.getPackageDetails(this.selectedPackage),
  //                       customer: this.portalUserRegisterForm.value,
  //                     });
  //                   }),
  //                 ),
  //             ),
  //           ),
  //         ),
  //         tap((finalResult) => {
  //           if (finalResult) {
  //             this.ref.close(finalResult); // Close dialog with the final result if successful
  //           }
  //         }),
  //         catchError((err) => {
  //           this.errorMessage =
  //             err.message || 'An error occurred during the process.';
  //           return of(null); // Handle the error without further processing
  //         }),
  //         finalize(() => {
  //           this.paymentLoading = false; // Ensure loading state is updated regardless of success/failure
  //         }),
  //       )
  //       .subscribe();
  //   } else {
  //     this.errorMessage = 'Please check the form for errors.';
  //     console.log('Form is invalid', this.paymentForm);
  //   }
  // }

  createPaymentIntent(bodyData: any): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/portal/create-payment-intent`, bodyData)
      .pipe(
        map((res) => {
          console.log('res', res);
          return res;
        }),
      );
  }

  onCardChange(event: StripeCardElementChangeEvent) {
    const displayError = document.getElementById('card-errors');
    if (event.error) {
      displayError!.textContent = event.error.message;
    } else {
      displayError!.textContent = '';
    }
  }

  getPackageDetails(packageValue: string) {
    // console.log('value selected: ', packageValue);
    let packageDetails = { name: '', value: '', price: 0 };
    packageDetails = this.packages.filter((packageItem) => {
      return packageItem.value === packageValue; // Return true or false based on condition
    })[0];
    // console.log('package details: ', packageDetails);
    this.paymentAmount = packageDetails.price;
    return packageDetails;
  }

  closePurchaseDialog() {
    this.ref.close();
  }
}
