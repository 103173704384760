import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { UsersService } from "src/app/_share/_services/users.service";
import { TenantsService } from "src/app/_share/_services/tenants.service";
import { RolesService } from "src/app/_share/_services/roles.service";
import { confirmPasswordValidator } from "src/app/_share/_validators/confirm-password.validator";
import { MessageService } from "primeng/api";

@Component({
	selector: "app-create-dialog",
	templateUrl: "./create-dialog.component.html",
	styleUrls: ["./create-dialog.component.scss"],
})
export class CreateDialogComponent implements OnInit {
	tenants: any[] = [];
	roles: any[] = [];
	portal_tenants: any[] = [];
	selectedPortalTenant: any;
	selectedTenant: any[] = [];
	selectedRole: any;
	loading: boolean = false;
	submitted: boolean = false;
	matchedPasswords: boolean = true;

	userForm: FormGroup = new FormGroup(
		{
			username: new FormControl("", [Validators.required]),
			first_name: new FormControl("", [Validators.required]),
			last_name: new FormControl("", [Validators.required]),
			email: new FormControl("", [Validators.required, Validators.email]),
			enabled: new FormControl(true),
			role_id: new FormControl("", [Validators.required]),
			password: new FormControl(""),
			confirm_password: new FormControl(""),
			portal_tenants: new FormControl([]),
			tenants: new FormControl("", [Validators.required]),
		},
		{ validators: confirmPasswordValidator }
	);

	constructor(
		public ref: DynamicDialogRef,
		public config: DynamicDialogConfig,
		private usersService: UsersService,
		private tenantsService: TenantsService,
		private rolesService: RolesService,
		private messageService: MessageService
	) {}

	ngOnInit(): void {
		this.userForm = new FormGroup(
			{
				username: new FormControl("", [Validators.required]),
				first_name: new FormControl("", [Validators.required]),
				last_name: new FormControl("", [Validators.required]),
				email: new FormControl("", [Validators.required, Validators.email]),
				enabled: new FormControl(true),
				role_id: new FormControl("", [Validators.required]),
				password: new FormControl(""),
				confirm_password: new FormControl(""),
				portal_tenants: new FormControl([]),
				tenants: new FormControl("", [Validators.required]),
			},
			{ validators: confirmPasswordValidator }
		);

		this.tenantsService.getPortalTenants().subscribe((portalTenants) => {
			this.portal_tenants = portalTenants;
		});

		this.tenantsService.getAll().subscribe((tenants) => {
			this.tenants = tenants;
		});

		// this.rolesService.getAll().subscribe((roles) => {
		//   this.roles = roles;
		//   console.log("ROLES", roles);

		// });
		this.userForm.get("role_id")?.setValue(1);
	}

	onCancel() {
		this.ref.close();
	}

	onChangeRole(event: any) {
		console.log(event);
		this.userForm.get("role_id")?.setValue(event.value);
		console.log(this.userForm.value);
	}

	onSubmit() {
		this.loading = true;
		this.submitted = true;

		if (this.userForm.invalid) {
			this.loading = false;

			this.userForm.markAllAsTouched();

			Object.keys(this.userForm.controls).forEach((key) => {
				this.userForm.controls[key].markAsDirty();
			});

			this.messageService.add({
				severity: "error",
				summary: "Error",
				detail: "Please fill in all required fields",
				key: "error-user",
			});

			return;
		}

		if(this.userForm.get("password")?.value === "") {
			this.userForm.removeControl("password");
			this.userForm.removeControl("confirm_password");
		  }

		console.log(this.userForm.value);

		this.usersService.createSystemUser(this.userForm.value).subscribe(
			(data) => {
				this.ref.close(data);
				this.messageService.add({
					severity: "success",
					summary: "Success",
					detail: "User created successfully",
					key: "create-user",
				});
				this.loading = false;
			},
			(error) => {
				this.loading = false;
				this.messageService.add({
					severity: "error",
					summary: "Error",
					detail: "An error occurred while creating user",
					key: "error-user",
				});
			}
		);
	}
}
