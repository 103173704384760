<div class="lg:px-3 px-1 py-5">
  <div class="surface-card p-4 shadow-2 border-round">
    <div class="font-medium text-3xl text-900 mb-3">
      <span class="text-blue-600">{{ tab.name | removeS }} Details</span>
    </div>

    <ul class="list-none p-0 m-0 border-300">
      <li
        *ngFor="let dataField of deviceFieldsToDisplay; let i = index"
        [ngClass]="{ 'surface-ground': i % 2 !== 0 }"
        class="flex align-items-center py-3 px-2 flex-wrap border-bottom-1 border-300"
      >
        <!-- Key -->
        <div class="text-500 w-full md:w-6 font-medium text-center">
          {{ dataField.key }}
        </div>

        <!-- Value -->
        <div class="text-900 w-full md:w-6 text-center">
          {{ dataField.value }}
        </div>
      </li>
    </ul>
  </div>
</div>
