import { Injectable } from '@angular/core';
import { ApiService } from '../../../_core/_services/api.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PortalCustomersService {
  constructor(private api: ApiService) {}

  getPortalCustomers(queryParams?: any) {
    if (queryParams) {
      queryParams = {
        ...queryParams,
      };
    } else {
      queryParams = {
        limit: 100,
        offset: 0,
        q: '',
        filter: [],
      };
    }
    return this.api.getAPI('portal/management/customers', queryParams).pipe(
      map((res: any) => {
        return res.body;
      }),
    );
  }
}
