import { Component, OnInit } from "@angular/core";
import { NetworkServicesService } from "../../_services/network-services.service";
import { BehaviorSubject } from "rxjs";
import { NetworkServices } from "../../_models/network-services.model";
import { MenuItem } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { ServicesManagementActionsComponent } from "./services-management-actions/services-management-actions.component";
import { TableMetaData } from "src/app/_share/_models/components/table-meta-data.model";

@Component({
	selector: "app-services-management",
	templateUrl: "./services-management.component.html",
	styleUrls: ["./services-management.component.scss"],
})
export class ServicesManagementComponent implements OnInit {
	services: any[] = [];
	services$: BehaviorSubject<NetworkServices[]> = new BehaviorSubject<
		NetworkServices[]
	>([]);
	columns: any[] = [];
	selectedRow: any;
	change_logs: any[] = [];
	loading: boolean = false;
	items: MenuItem[] = [];
	isError: boolean = false;
	ref: any;
	status: string = "";
	menuItems: MenuItem[] = [];

	metadata: TableMetaData = {
		totalRecords: 0,
		rows: 100,
		first: 0,
		loading: true,
		globalFilter: "",
		sort: "",
		id: "",
	};

	private initializeColumns(): void {
		const model: NetworkServices = {
			end_customer_circuit: [],
			// Add other fields from the NetworkServices model here
			date_activated: new Date(),
			service_status: "",
		};

		this.columns = Object.keys(model).flatMap((key) => {
			if (Array.isArray((model as any)[key])) {
				return [
					{
						header: "End Customer Circuit Number",
						field: "circuitNumberSearch",
					},
				];
			}
			return [{ header: this.formatColumnName(key), field: key }];
		});

		console.log("nmmp columns", this.columns);
	}

	private formatColumnName(key: string): string {
		return key
			.split("_")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
	}

	constructor(
		private nss: NetworkServicesService,
		private dialogService: DialogService
	) {}

	ngOnInit(): void {
		this.getNetworkServices();
		this.initializeColumns();
		this.change_logs = [];
	}

	openMenu($event: any): MenuItem[] {
		console.log("nmmp open menu", event);
		
		const status = $event.service_status;
		return [
			...(status !== "suspended" && status !== "cancelled"
				? [
						{
							label: "Regrade",
							icon: "pi pi-refresh",
							command: () => this.openServicesManagementActions("Regrade"),
						},
				  ]
				: []),
			...(status !== "suspended"
				? [
						{
							label: "Suspend",
							icon: "pi pi-times",
							command: () => this.openServicesManagementActions("Suspend"),
						},
				  ]
				: []),
			...(status !== "active"
				? [
						{
							label: "Activate",
							icon: "pi pi-check",
							command: () => this.openServicesManagementActions("Activate"),
						},
				  ]
				: []),
			...(status !== "cancelled"
				? [
						{
							label: "Cancel",
							icon: "pi pi-trash",
							command: () => this.openServicesManagementActions("Cancel"),
						},
				  ]
				: []),
		];
	}


	showContextAction($event: any) {
		console.log("nmmp show context action", $event);
		this.selectedRow = $event;
		this.menuItems = this.openMenu($event);
	}

	openServicesManagementActions(event: any) {
		this.ref = this.dialogService.open(ServicesManagementActionsComponent, {
			header: "Service Management Actions",
			width: "70%",
			data: {
				selectedRow: this.selectedRow,
				selectedAction: event,
			},
			showHeader: false,
		});

		this.ref.onClose.subscribe((data: any) => {
			if (data) {
				console.log("nmmp data", data);
				this.getNetworkServices();
			}
		});
	}

	openCreateServiceDialog() {
		this.ref = this.dialogService.open(ServicesManagementActionsComponent, {
			header: "Create Service",
			width: "70%",
			data: {
				selectedAction: "Create",
			},
			showHeader: false,
		});

		this.ref.onClose.subscribe((data: any) => {
			if (data) {
				console.log("nmmp data", data);
				this.getNetworkServices();
			}
		});
	}

	getNetworkServices() {
		this.loading = true;
		this.nss
			.getNetworkServices({
				limit: this.metadata.rows,
				offset: this.metadata.first,
				q: this.metadata.globalFilter,
				sort: this.metadata.sort,
				id: this.metadata.id,
			})
			.subscribe(
				(res: any) => {
					const mappedServices = res.data.map((service: any) => ({
						...service,
						circuitNumberSearch:
							service.end_customer_circuit
								?.map((circuit: any) => circuit.circuit_no) // Extract circuit numbers
								.join(", ") || "", // Convert to string for filtering
					}));

					this.services = mappedServices;
					this.services$.next(mappedServices);

					if (this.selectedRow) {
						const selectedService = mappedServices.find(
							(service: any) => service.id === this.selectedRow.id
						);
						if (selectedService && selectedService.change_logs) {
							this.change_logs = selectedService.change_logs;
						}
					}

					console.log("nmmp services", mappedServices);
					this.metadata.loading = false;
					this.metadata.totalRecords = res.count;
				},
				
			);
	}

	getNumberOfColumns(): number {
		return this.columns.length;
	}

	onRowSelect($event: any) {
		console.log("nmmp row selected", $event);
		this.change_logs = $event.change_logs;
		console.log("nmmp change logs", this.change_logs);
	}
	
}
