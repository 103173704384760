import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

interface EntityField {
  field: any;
  header: string;
  type:
    | 'text'
    | 'date'
    | 'string'
    | 'number'
    | 'currency'
    | 'status'
    | 'boolean';
}

@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.scss'],
})
export class ViewDetailsComponent implements OnInit {
  tab: any;
  entityData: any;
  schema: any;
  entityActions: any[] = [];
  trafficData: any;
  signalData: any;

  basicOptions: any;
  signalOptions: any;
  trafficOptions: any;

  deviceFieldsToDisplay: {key: string, value: any}[] = []

  constructor(
    public config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.entityData = this.config.data.entityData;
    this.tab = this.config.data.tab;
    this.schema = this.config.data.schema;

    this.deviceFieldsToDisplay = Object.entries(this.entityData).map(([key, value]) => {
      return { key: key, value: value };
    });

    this.entityActions = [
      {
        label: 'Assign Subscription Package',
        icon: 'pi pi-plus',
        styleClass: 'p-button-success',
      },
      {
        label: 'Upgrade Subscription',
        icon: 'pi pi-pencil',
        styleClass: 'p-button-danger',
      },
      {
        label: 'Cancel Subscription',
        icon: 'pi pi-times',
        styleClass: 'p-button-warning',
      },
      {
        label: 'Edit Subscription Details',
        icon: 'pi pi-pencil',
        styleClass: 'p-button-success',
      },
    ];

    this.basicOptions = {
      plugins: {
        legend: {
          display: true,
        },
        title: {
          display: true,
          text: 'My Title',
          fontSize: 16,
        },
      },
    };

    this.trafficData = this.generateFakeTrafficData(new Date(), 30);

    this.trafficOptions = {
      ...this.basicOptions,
      plugins: {
        title: { display: true, text: 'Traffic Data', fontSize: 16 },
      },
    };

    this.signalData = this.generateFakeSignalData(new Date(), 30);

    this.signalOptions = {
      ...this.basicOptions,
      plugins: {
        title: { display: true, text: 'Signal Data', fontSize: 16 },
      },
    };
  }

  getFieldValue(field: any): any {
    const fieldParts = field.split('.');
    let value: any = this.entityData;
    for (const part of fieldParts) {
      if (value) {
        value = value[part];
      } else {
        return null;
      }
    }
    return value;
  }

  generateFakeTrafficData(startDate: Date, days: number = 30) {
    let labels = [];
    let downloadData = [];
    let uploadData = [];
    let currentDate = new Date(startDate);

    for (let i = 0; i < days; i++) {
      // Format the date as YYYY-MM-DD
      let dateString = currentDate.toISOString().split('T')[0];
      labels.push(dateString);

      // Generate random data for download and upload in Mbps
      let downloadSpeed = Math.random() * (60 - 40) + 40; // Download speed between 20 and 100 Mbps
      let uploadSpeed = Math.random() * (30 - 20) + 20; // Upload speed between 5 and 50 Mbps

      downloadData.push(downloadSpeed.toFixed(2));
      uploadData.push(uploadSpeed.toFixed(2));

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return {
      labels: labels,
      datasets: [
        {
          label: 'Download (daily average)',
          data: downloadData,
          fill: false,
          borderColor: '#42A5F5',
          tension: 0.1,
        },
        {
          label: 'Upload (daily average)',
          data: uploadData,
          fill: false,
          borderColor: '#FFA726',
          tension: 0.1,
        },
      ],
    };
  }

  generateFakeSignalData(startDate: Date, days: number = 30) {
    let labels = [];
    let signalData = [];
    let currentDate = new Date(startDate);

    for (let i = 0; i < days; i++) {
      // Format the date as YYYY-MM-DD
      let dateString = currentDate.toISOString().split('T')[0];
      labels.push(dateString);

      // Generate random signal data in dBm between -25 and -20
      let signalStrength = Math.random() * (-20 - -25) + -25;

      signalData.push(signalStrength.toFixed(2));

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return {
      labels: labels,
      datasets: [
        {
          label: 'Signal Strength (daily average)',
          data: signalData,
          fill: false,
          borderColor: '#42A5F5',
          tension: 0.1,
        },
      ],
    };
  }
}
