<div class="flex flex-column h-full min-h-full max-h-full">
  <!-- nav -->
  <div
    class="px-3 sm:px-7 py-3 flex flex-wrap align-items-stretch justify-content-between relative"
    [ngStyle]="{ 'background-color': '#ffffff', height: '96px' }"
  >
    <div
      class="flex flex-column align-items-center justify-content-center py-3"
    >
      <img
        src="/assets/portal/images/stem/logo-blue.png"
        alt="Stem Logo"
        [height]="48"
      />
    </div>
  </div>
  <div class="flex-1 overflow-y-auto">
    <iframe
      width="100%"
      height="100%"
      src="https://workflow.intentio.co.za/form-test/a857b374-2da9-45ba-be57-1e31ab1f1372"
    ></iframe>
  </div>
  <div
    class="surface-100 px-4 py-4 md:px-6 lg:px-8 flex flex-column justify-content-between align-items-center"
  >
    <span class="text-600">Stem Connect 2025 ©</span>
  </div>
</div>
