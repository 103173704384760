import { Component, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { PortalSubscriptionService } from "src/app/features/portal/_services/portal-subscription.service";
import { SubscriptionDetailsComponent } from "./subscription-details/subscription-details.component";
import { DialogService } from "primeng/dynamicdialog";
import { Table } from "primeng/table";
import { TableMetaData } from "src/app/_share/_models/components/table-meta-data.model";
import { MenuItem } from "primeng/api";

@Component({
	selector: "app-subscriptions",
	templateUrl: "./subscriptions.component.html",
	styleUrls: ["./subscriptions.component.scss"],
})
export class SubscriptionsComponent implements OnInit, OnDestroy {
	subscriptions: any[] = [];
	subscriptions$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	subscriptionQuery$: Subscription = new Subscription();

	isLoading: boolean = true;
	isError: boolean = false;
	menuItems: MenuItem[] = [];

	columns: any[] = [
		{ field: "subscription_account_reference", header: "Account Reference" },
		{ field: "date_activated", header: "Start Date" },
		{ field: "date_billing_next", header: "Next Billing Date" },
		// { field: 'billing_cycle_type', header: 'Billing Cycle' },
		{ field: "subscription_reference", header: "Subscription Reference" },
		{ field: "date_initialised", header: "Date Initialised" },
		{ field: "date_activated", header: "Date Activated" },
		{ field: "subscription_status", header: "Service Status" },
		{ field: "account_status", header: "Account Status" },
	];

	metadata: TableMetaData = {
		totalRecords: 0,
		rows: 100,
		first: 0,
		loading: true,
		globalFilter: "",
		sort: "",
		id: "",
	};

	constructor(
		private portalSubscriptionsService: PortalSubscriptionService,
		private dialogService: DialogService
	) {}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.subscriptions$.unsubscribe();
		this.subscriptionQuery$.unsubscribe();
	}

	retrievePortalSubscriptions() {
		this.isLoading = true;
		this.subscriptionQuery$ = this.portalSubscriptionsService
			// fixme: update this 300 hard coded value
			.getPortalSubscriptions({
				limit: this.metadata.rows,
				offset: this.metadata.first,
				q: this.metadata.globalFilter,
				sort: this.metadata.sort,
				id: this.metadata.id,
			})
			.subscribe(
				(res: any) => {
					this.subscriptions = res.data;
					this.subscriptions$.next(this.subscriptions);

					this.metadata.loading = false;
					this.metadata.totalRecords = res.count;
				},
				(error) => {
					console.log("Error ", error);
					this.isLoading = false;
					this.isError = true;
				}
			);
	}

	openMenu(event: any): MenuItem[] {
		return [
			{
				label: "View",
				icon: "pi pi-fw pi-eye",
				command: () => {
					this.showSubscriptionDetailsDialog(event);
				},
			}
		];
	}

	showContextAction($event: any) {
		this.menuItems = this.openMenu($event);
	}

	showSubscriptionDetailsDialog(subscription: any) {
		const ref = this.dialogService.open(SubscriptionDetailsComponent, {
			data: {
				subscription: subscription,
			},
			width: "70%",
		});
	}

	getNumberofColumns() {
		return this.columns.length;
	}

	clear(table: Table) {
		table.clear();
	}

	getAccountSeverity(status: string): string {
		switch (status) {
			case "paid":
				return "success";
			case "unpaid":
				return "danger";
			case "overdue":
				return "warning";
			default:
				return "info";
		}
	}

	getSubscriptionSeverity(status: string): string {
		switch (status) {
			case "active":
				return "success";
			case "suspended_payment":
				return "danger";
			case "cancelled":
				return "warning";
			default:
				return "info";
		}
	}
}
