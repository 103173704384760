<div class="flex flex-column h-full">
  <div class="surface-card shadow-1 p-3 z-0 flex-1">
    <div class="grid nested-grid" style="max-width: 100vw">
      <div class="col-12">
        <div class="grid">
          <div class="col-12">
            <p-tabView [activeIndex]="activeTab" (onChange)="changeTab($event)">
              <!-- fixme: this is excessive for loop and switch to select the tabs -->
              <p-tabPanel *ngFor="let tab of this.tabs" [header]="tab.name">
                <div [ngSwitch]="tab.name">
                  <div
                    class="grid grid-nogutter"
                    style="max-width: 100vw"
                    *ngSwitchCase="'Devices'"
                  >
                    <!-- current action buttons -->
                    <div class="col-12 md:col-12">
                      <div class="">
                        <div class="surface-card p-2 shadow-2">
                          <span class="p-buttonset">
                            <button
                              pButton
                              class="p-button-info"
                              icon="pi pi-plus"
                              iconPos="left"
                              label="Add new item to {{ tab.name }}"
                              (click)="showAdd(tab)"
                            ></button>
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- statistics -->
                    <div class="col-12" *ngIf="true">
                      <app-statistics-summary
                        [summaryData]="devicesSummaryStatistics"
                        [summaryTopic]="'device'"
                      ></app-statistics-summary>
                    </div>
                    <div class="col-12 md:col-3" *ngIf="message[tab?.key]">
                      <div class="surface-card shadow-1 p-1 lg:p-4">
                        <h2>{{ tab.name | removeS }} Information</h2>
                        <!-- <p-divider></p-divider> -->

                        <app-details
                          [rowData]="message[tab?.key]"
                          [selectedTab]="tab.name"
                        ></app-details>

                        <!-- chart -->
                        <div class="col-12" *ngIf="false">
                          <div class="shadow-1 border-custom p-1 lg:p-4">
                            <div class="card">
                              <p-chart
                                type="bar"
                                [data]="basicData"
                                [options]="horizontalOptions"
                              ></p-chart>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- share table -->
                    <div
                      [ngClass]="{
                        'col-12 md:col-9': message[tab?.key],
                        'col-12 md:col-12': !message[tab?.key]
                      }"
                    >
                      <div class="surface-card shadow-1 p-1 lg:p-4">
                        <share-table
                          [data]="tabDataSources[tab?.key] || []"
                          [columns]="schemaDefinitions[tab.key]?.columns"
                          [menuItems]="menuItems"
                          [(metaData)]="
                            entityService.metaDataEntityMap[tab.key]
                          "
                          (metaDataChange)="
                            entityService.refreshEntities(tab.key)
                          "
                          (selectedRowCMObservable$)="
                            showContextAction($event, tab)
                          "
                          (selectedRowObservable$)="showDetails($event, tab)"
                          (selectedRelatedEntityObservable$)="
                            showRelatedEntity($event)
                          "
                          
                        >
                        </share-table>
                      </div>
                    </div>
                  </div>
                 

                  <div
                    *ngSwitchDefault
                    class="grid grid-nogutter"
                    style="max-width: 100vw"
                  >
                    <!-- current action buttons -->
                    <div class="col-12 md:col-12">
                      <div class="">
                        <div class="surface-card p-2 shadow-2">
                          <span class="p-buttonset">
                            <button
                              pButton
                              class="p-button-info"
                              icon="pi pi-plus"
                              iconPos="left"
                              label="Add new item to {{ tab.name }}"
                              (click)="showAdd(tab)"
                            ></button>
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- share table -->
                    <div class="col-12 md:col-12 py-2">
                      <div class="surface-card shadow-1 p-3 lg:p-4">
                        <share-table
                          [data]="tabDataSources[tab?.key] || []"
                          [columns]="schemaDefinitions[tab.key]?.columns"
                          [queryParams]="queryParams"
                          [menuItems]="menuItems"
                          [(metaData)]="
                            entityService.metaDataEntityMap[tab.key]
                          "
                          (metaDataChange)="
                            entityService.refreshEntities(tab.key)
                          "
                          (selectedRowCMObservable$)="
                            showContextAction($event, tab)
                          "
                          (selectedRowObservable$)="showDetails($event, tab)"
                          (selectedRelatedEntityObservable$)="
                            showRelatedEntity($event)
                          "
                           
                        >
                        </share-table>
                      </div>
                    </div>
                  </div>
                </div>
              </p-tabPanel>
            </p-tabView>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-confirmDialog
  header="Confirmation of Bulk Delete"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>
