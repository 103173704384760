<div class="flex card-container m-5">
	<div class="flex-grow-1 flex align-items-center justify-content-center">
		<div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
			<div
				*ngIf="state !== 'setPassword'; else showSetPassword"
				class="text-center mb-2"
			>
				<!-- <img src="assets/images/blocks/logos/hyper.svg" alt="Image" height="50" class="mb-3"> -->
				<div class="text-900 text-3xl font-medium mb-3">Forgot Password</div>
				<span class="text-600 font-medium line-height-3"
					>Don't remember your password?</span
				>
				<!-- <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
            >Create today!</a
          > -->
			</div>
			<ng-template #showSetPassword>
				<div class="text-center mb-2">
					<div class="text-900 text-3xl font-medium mb-3">Set Password</div>
					<span class="text-600 font-medium line-height-3"
						>Please Set Your Password</span
					>
				</div>
			</ng-template>
			<div *ngIf="state === 'setPassword'">
				<form
					(ngSubmit)="onResetSubmit()"
					[formGroup]="newPasswordForm"
				>
					<label
						for="password"
						class="block text-900 font-medium mb-2"
						>New Password</label
					>
					<p-password
						[toggleMask]="true"
						formControlName="password"
						inputStyleClass="w-full"
						styleClass="w-full mb-3"
						(blur)="fn.password.markAsDirty()"
						(input)="clearError()"
						mediumRegex="^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})."
					></p-password>
					<div
						*ngIf="submitted && fn.password.errors"
						class="my-3 invalid-feedback"
					>
						<p-message
							*ngIf="fn.password.errors.required"
							severity="error"
							text="Password is required"
							styleClass="w-full"
						></p-message>
					</div>

					<label
						for="confirmPassword"
						class="block text-900 font-medium mb-2"
						>Confirm New Password</label
					>
					<input
						type="password"
						formControlName="confirmPassword"
						class="form-control"
						pInputText
						class="w-full mb-3"
						(input)="clearError()"
					/>
					<div
						*ngIf="submitted && fn.confirmPassword.errors"
						class="invalid-feedback my-3"
					>
						<p-message
							*ngIf="fn.confirmPassword.errors.required"
							severity="error"
							text="Password is required"
							styleClass="w-full"
						></p-message>
					</div>
					<button
						pButton
						pRipple
						[disabled]="loading"
						label="Change Password"
						class="w-full"
						type="submit"
						[loading]="loading"
					>
						<span
							*ngIf="loading"
							class="spinner-border spinner-border-sm mr-1"
						></span>
					</button>
					<div
						*ngIf="error"
						class="alert alert-danger mt-3 mb-0"
					>
						<p-message
							severity="error"
							[text]="error"
							styleClass="w-full"
						></p-message>
					</div>
				</form>
			</div>

			<div *ngIf="state === 'forgotPassword'">
				<form
					(ngSubmit)="onForgotSubmit()"
					[formGroup]="forgotPasswordForm"
				>
					<label
						for="email"
						class="block text-900 font-medium mb-2"
						>Email</label
					>
					<input
						type="text"
						formControlName="email"
						class="form-control"
						pInputText
						class="w-full mb-3"
						(input)="clearError()"
					/>
					<div
						*ngIf="submitted && f.email.errors"
						class="invalid-feedback"
					>
						<p-message
							*ngIf="f.email.errors.required"
							severity="error"
							text="Email is required"
							styleClass="w-full"
						></p-message>
						<p-message
							*ngIf="f.email.errors.email"
							severity="error"
							text="Please enter a valid email"
							styleClass="w-full"
						></p-message>
					</div>

					<!-- <label for="password" class="block text-900 font-medium mb-2"
              >Password</label
            >
            <input
              type="password"
              formControlName="password"
              class="form-control"
              pInputText
              class="w-full mb-3"
            />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div> -->

					<div
						class="flex align-items-center justify-content-between mb-6"
					></div>

					<button
						pButton
						pRipple
						[disabled]="loading"
						label="Send Password Reset Request"
						class="w-full"
						[loading]="loading"
					>
						<span
							*ngIf="loading"
							class="spinner-border spinner-border-sm mr-1"
						></span>
					</button>
					<div
						*ngIf="error"
						class="alert alert-danger mt-3 mb-0"
					>
						<p-message
							severity="error"
							[text]="error"
							styleClass="w-full"
						></p-message>
					</div>
					<!-- <div *ngIf="error" class="alert alert-danger mt-3 mb-0">
              {{ error }}
            </div> -->
				</form>
			</div>

			<div *ngIf="state === 'requestSent'">
				<!-- <div *ngIf="error" class="alert alert-danger mt-3 mb-0">
              {{ error }}
            </div> -->
				<!-- make a piece of text saying that the password reset request -->
				<!-- has been sent to the email address provided -->

				<p class="text-900 text-xl font-medium mb-3 text-center">
					Password Reset Request Sent, Please Check Your Email...
				</p>
			</div>

			<div *ngIf="state === 'resetSent'">
				<!-- <div *ngIf="error" class="alert alert-danger mt-3 mb-0">
              {{ error }}
            </div> -->
				<!-- make a piece of text saying that the password reset request -->
				<!-- has been sent to the email address provided -->

				<p class="text-900 text-xl font-medium mb-3 text-center">
					Your password has been reset, please login with your new password...
				</p>
				<a
					class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
					[routerLink]="['/login']"
					>Login</a
				>
			</div>

			<div
				*ngIf="state === 'resetPassword'"
				class="col-12 md:col-12"
			>
				<form
					(ngSubmit)="onResetSubmit()"
					[formGroup]="newPasswordForm"
				>
					<label
						for="password"
						class="block text-900 font-medium mb-2"
						>New Password</label
					>
					<p-password
						[toggleMask]="true"
						formControlName="password"
						inputStyleClass="w-full"
						styleClass="w-full mb-3"
						(blur)="fn.password.markAsDirty()"
						(input)="clearError()"
						mediumRegex="^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})."
					></p-password>
					<div
						*ngIf="submitted && fn.password.errors"
						class="my-3 invalid-feedback"
					>
						<p-message
							*ngIf="fn.password.errors.required"
							severity="error"
							text="Password is required"
							styleClass="w-full"
						></p-message>
					</div>

					<label
						for="confirmPassword"
						class="block text-900 font-medium mb-2"
						>Confirm New Password</label
					>
					<input
						type="password"
						formControlName="confirmPassword"
						class="form-control"
						pInputText
						class="w-full mb-3"
						(input)="clearError()"
					/>
					<div
						*ngIf="submitted && fn.confirmPassword.errors"
						class="invalid-feedback my-3"
					>
						<p-message
							*ngIf="fn.confirmPassword.errors.required"
							severity="error"
							text="Password is required"
							styleClass="w-full"
						></p-message>
					</div>

					<!-- <div
              class="flex align-items-center justify-content-between mb-6"
            ></div> -->

					<button
						pButton
						pRipple
						[disabled]="loading"
						label="Change Password"
						class="w-full"
						type="submit"
						[loading]="loading"
					>
						<span
							*ngIf="loading"
							class="spinner-border spinner-border-sm mr-1"
						></span>
					</button>
					<div
						*ngIf="error"
						class="alert alert-danger mt-3 mb-0"
					>
						<p-message
							severity="error"
							[text]="error"
							styleClass="w-full"
						></p-message>
					</div>
					<!-- <div *ngIf="error" class="alert alert-danger mt-3 mb-0">
              {{ error }}
            </div> -->
				</form>
			</div>
		</div>
	</div>
</div>
