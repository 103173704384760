import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PortalAuthenticationService {
  private portalUserSubject: BehaviorSubject<any>;
  public portalUser: Observable<any>;
  public isPortalLoggedIn: boolean = false;

  constructor(
    private router: Router,
    private http: HttpClient,
  ) {
    this.portalUserSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem('portalUser')!),
    );
    this.portalUser = this.portalUserSubject.asObservable();
  }

  public get portalUserValue() {
    return this.portalUserSubject.value;
  }

  login(username: any, password: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/portal/user/login`,
        new URLSearchParams({
          grant_type: 'password',
          username: username,
          password: password,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
        },
      )
      .pipe(
        map((portalUser: any) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('portalUser', JSON.stringify(portalUser));
          localStorage.setItem(
            'portalUserPass',
            JSON.stringify({ password: password }),
          );
          this.portalUserSubject.next(portalUser);
          this.isPortalLoggedIn = true;
          return portalUser;
        }),
      );
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('portalUser');
    localStorage.removeItem('portalUserPass');
    this.portalUserSubject.next(null);
    this.router.navigate(['portal']);
  }

  localAuth(
    username: string,
    password: string,
    dstUrl: string,
    loginUrl: string,
  ) {
    const params = new URLSearchParams({
      username: username ?? '',
      password: password ?? '',
      dst: dstUrl,
      popup: 'true',
    });

    return this.http
      .jsonp(`${loginUrl}?${params.toString()}`, 'callbackHandler')
      .pipe(
        map((res) => {
          return res;
        }),
      );
  }

  callbackHandler() {
    console.log('callback');
  }
}
