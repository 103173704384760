import { EndCustomerCircuit, IspCircuit } from 'src/app/_share/_models/schema';
import { NetworkPortalProducts } from './network-portal-products.model';
import { NetworkServicesChangeLog } from './network-services-change-log.model';

export interface NetworkServices {
  service_status: string;
  date_activated: Date;
  date_suspended?: Date;
  date_cancelled?: Date;
  date_upgraded?: Date;
  date_downgraded?: Date;
  recurring_product_id?: number;
  recurring_product?: NetworkPortalProducts;
  change_logs?: NetworkServicesChangeLog[];
  end_customer_circuit?: EndCustomerCircuit[];
  isp_circuit?: IspCircuit[];
}

export interface NetworkSeviceActions {
  date_actioned?: Date;
  circuit_no?: string;
  recurring_product_id?: number;
}
