import { Component } from '@angular/core';

interface Product {
  name: string;
  basePrice: number;
  phonePrice: number;
}

interface AIFeature {
  name: string;
  description: string;
  price: number;
  selected: boolean;
}

@Component({
  selector: 'app-voice-product-wizard',
  templateUrl: './voice-product-wizard.component.html',
  styleUrls: ['./voice-product-wizard.component.scss'],
})
export class VoiceProductWizardComponent {
  activeStepIndex = 0;
  selectedProduct: Product | null = null;

  // Configuration options
  channels: number = 1;
  sipAccounts: number = 1;
  phoneNumbers: number = 1;
  physicalPhones: number = 0;

  products: Product[] = [
    { name: 'AI Voice Basic', basePrice: 30, phonePrice: 1200 },
    { name: 'AI Voice Plus', basePrice: 20, phonePrice: 1000 },
  ];

  aiFeatures: AIFeature[] = [
    {
      name: 'Call Transcription',
      description: 'Automatically transcribe all your calls',
      price: 10,
      selected: false,
    },
    {
      name: 'Sentiment Analysis',
      description: 'Analyze customer sentiment during calls',
      price: 15,
      selected: false,
    },
    {
      name: 'Context Aware Recommendations',
      description: 'Get real-time recommendations during calls',
      price: 20,
      selected: false,
    },
    {
      name: 'CRM Integration',
      description: 'Integrate with your existing CRM system',
      price: 25,
      selected: false,
    },
  ];

  steps = [
    { label: 'Select Product' },
    { label: 'Basic Config' },
    { label: 'AI Features' },
    { label: 'Review & Confirm' },
  ];

  get monthlyTotal(): number {
    if (!this.selectedProduct) return 0;
    const baseTotal = this.selectedProduct.basePrice * this.channels;
    const aiTotal = this.aiFeatures
      .filter((f) => f.selected)
      .reduce((sum, feature) => sum + feature.price * this.channels, 0);
    return baseTotal + aiTotal;
  }

  get onceOffTotal(): number {
    if (!this.selectedProduct) return 0;
    return this.selectedProduct.phonePrice * this.physicalPhones;
  }

  selectProduct(product: Product) {
    this.selectedProduct = product;
    this.nextStep();
  }

  nextStep() {
    if (this.activeStepIndex < this.steps.length - 1) {
      this.activeStepIndex++;
    }
  }

  previousStep() {
    if (this.activeStepIndex > 0) {
      this.activeStepIndex--;
    }
  }

  toggleAIFeature(feature: AIFeature) {
    feature.selected = !feature.selected;
  }

  confirmSelection() {
    console.log('Order Summary:', {
      product: this.selectedProduct,
      configuration: {
        channels: this.channels,
        sipAccounts: this.sipAccounts,
        phoneNumbers: this.phoneNumbers,
        physicalPhones: this.physicalPhones,
      },
      aiFeatures: this.aiFeatures.filter((f) => f.selected),
      pricing: {
        monthly: this.monthlyTotal,
        onceOff: this.onceOffTotal,
      },
    });
  }
}
