import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../_services/authentication.service';
import { PortalAuthenticationService } from 'src/app/features/portal/_services/portal-authentication.service';
import { environment } from 'src/environments/environment';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private sas: AuthenticationService,
    private pas: PortalAuthenticationService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          // Auto logout if 401 Unauthorized or 403 Forbidden response returned from API
          if (
            request.url.startsWith(environment.apiUrl) &&
            request.url.includes('/portal/')
          ) {
            this.pas.logout();
          } else {
            this.sas.logout();
          }
        }

        // Bubble up the error
        return throwError(error);
      })
    );
  }
}
