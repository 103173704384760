import { Injectable } from "@angular/core";
import { ApiService } from "../../../_core/_services/api.service";
import { map } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class PortalTransactionsService {
	constructor(private api: ApiService) {}

	getPortalTransactions() {
		return this.api.getAPI("portal/transactions").pipe(
			map((res: any) => {
				return res.body;
			})
		);
	}

	getPortalTransactionsById(transaction_id: number) {
		return this.api.getAPI(`portal/transactions/${transaction_id}`).pipe(
			map((res: any) => {
				return res.body.data;
			})
		);
	}

	getPortalTransactionsByEndCustomerId(portal_end_customer_id: number) {
		return this.api
			.getAPI(`portal/transactions/customer/${portal_end_customer_id}`)
			.pipe(
				map((res: any) => {
					return res.body.data;
				})
			);
	}

	getPortalTransactionPayLink(
		transaction: any,
		portal_end_customer_id: number,
		payment_method_override?: string
	) {
		let queryParamters = {};

		if (payment_method_override) {
			queryParamters = { payment_method_override: payment_method_override };
		}

		return this.api
			.getAPI(
				`portal/transactions/${portal_end_customer_id}/pay/${
					transaction && transaction.id
				}`,
				queryParamters
			)
			.pipe(
				map((res: any) => {
					return res.body;
				})
			);
	}

	overrideTransactionPaymentMethod(
		transaction: any,
		portal_end_customer_id: number
	) {
		return this.api
			.postAPI(
				`portal/management/transactions/override-transaction/${portal_end_customer_id}`,
				transaction
			)
			.pipe(
				map((res: any) => {
					return res.body;
				})
			);
	}
}
