import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { User } from '../_models/entities/user';
import { environment } from 'src/environments/environment';

// todo: should merge with auth service as "accounts service"
@Injectable({ providedIn: 'root' })
export class UserService {
  currentUserQuery$: any;
  currentUser: User = {};
  currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>({});

  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<User[]>(`${environment.apiUrl}/users`);
  }

  getById(id: number) {
    return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
  }

  getCurrentLoggedInUser() {
    this.currentUserQuery$ = this.http.get<User>(
      `${environment.apiUrl}/users/me`
    );

    this.currentUserQuery$.subscribe((user: User) => {
      this.currentUser = user;
      this.currentUser$.next(user);
      console.log('current user', user);
    });
  }

  clearCurrentLoggedInUser() {
    this.currentUser = {};
    this.currentUser$.next({});
  }

  forgotPassword(email: string) {
    return this.http.post(
      `${environment.apiUrl}/forgot-password`,
      {},
      {
        params: {
          email: email,
        },
      }
    );
  }

  resetPassword(email: string, token: string, password: string) {
    return this.http.post(
      `${environment.apiUrl}/reset-password`,
      {  },
      {
        params: {
          new_password: password,
          token: token,
          email: email,
        },
      }
    );
  }

  switchTenant(tenant_id: number) {
    return this.http.post(
      `${environment.apiUrl}/switch-default-system-tenant`,
      { tenant_id: tenant_id }
    );
  }

  switchPortalTenant(portal_tenant_id: number) {
    return this.http.post(
      `${environment.apiUrl}/switch-default-portal-tenant`,
      { portal_tenant_id: portal_tenant_id }
    );
  }
}
