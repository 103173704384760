
<p-toast key="override-error"></p-toast>
<div>
	<small>* The following will will be changed upon overriding</small>
	<form
		action=""
		[formGroup]="overrideForm"
		(ngSubmit)="overrideTransaction()"
		
	>
		<div class="grid">
			<div class="col-12 md:col-6 flex flex-column">
				<div><h3>Status</h3></div>

				<div class="">
					<p-tag
						value="COMPLETED"
						severity="success"
						styleClass=""
					></p-tag>
				</div>
			</div>

			<div class="col-12 md:col-6 flex flex-column">
				<div><h3>Transaction Method</h3></div>
				<div class="">
					<label
						for="description"
						class="text-gray-800"
						>Override</label
					>
				</div>
			</div>
			<div class="col-12 flex flex-column">
				<div><h3>Transaction Date</h3></div>
				<div class="">
					<p-calendar
						[showIcon]="true"
						[inputStyle]="{ width: '100%' }"
						[monthNavigator]="true"
						[showOnFocus]="true"
						appendTo="body"
						dateFormat="yy-mm-dd"
						formControlName="transaction_date"
						styleClass="w-full"
						(blur)="overrideForm.controls['transaction_date'].markAsDirty()"
					></p-calendar>
					<div
						*ngIf="submitted && overrideForm.get('transaction_date')?.errors"
						class="invalid-feedback mt-2"
					>
						<small
							*ngIf="
								overrideForm.controls &&
								overrideForm.controls['transaction_date'] &&
								overrideForm.controls['transaction_date'].errors &&
								overrideForm.controls['transaction_date'].errors['required'] ||
								overrideForm.controls['transaction_date'].dirty
							" 
							class="p-error text-base"
							><strong >Transaction Date</strong> is required</small
						>
					</div>
				</div>
			</div>
			<div class="col-12 flex flex-column">
				<div><h3>Override Reason</h3></div>
				<div class="">
					<p-dropdown
						[options]="overrideReasons"
						optionLabel="label"
						optionValue="value"
						placeholder="Select Reason"
						formControlName="transaction_override_reason"
						styleClass="w-full"
						(blur)="overrideForm.controls['transaction_override_reason'].markAsDirty()" 
					></p-dropdown>
					<div
						*ngIf="submitted && overrideForm.get('transaction_override_reason')?.errors"
						class="invalid-feedback mt-2"
					>
						<small
							*ngIf="
								overrideForm.controls &&
								overrideForm.controls['transaction_override_reason'] &&
								overrideForm.controls['transaction_override_reason'].errors &&
								overrideForm.controls['transaction_override_reason'].errors['required'] ||
								overrideForm.controls['transaction_override_reason'].dirty
							" 
							class="p-error text-base"
							><strong >Override Reason</strong> is required</small
						>
					</div>
				</div>
			</div>
			<div class="surface-border border-top-1 opacity-50 mt-3 col-12"></div>
			<div class="col-12">
				<button
					pButton
					label="Override"
					class="w-full p-button-primary"
					type="submit"
					[loading]="loading"
				></button>
			</div>
		</div>
	</form>
</div>
