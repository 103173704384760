import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { UsersService } from "src/app/_share/_services/users.service";
import { CreateDialogComponent } from "./create-dialog/create-dialog.component";
import { UpdateDialogComponent } from "./update-dialog/update-dialog.component";
import { DialogService } from "primeng/dynamicdialog";
import { MenuItem } from "primeng/api";
@Component({
	selector: "app-tenant-users",
	templateUrl: "./tenant-users.component.html",
	styleUrls: ["./tenant-users.component.scss"],
})
export class TenantUsersComponent implements OnInit {
	tenantUsers: any[] = [];
	tenantUsers$: Subject<any[]> = new Subject<any[]>();
	ref: any;
	selectedRow: any = {};
	userStatus: string = "Disable";
	menuItems!: MenuItem[];

	columns = [
		{ field: "first_name", header: "First Name" },
		{ field: "last_name", header: "Last Name" },
		{ field: "email", header: "Email" },
		{ field: "username", header: "Username" },
		{ field: "role", header: "Role" },
		{ field: "tenant", header: "Tenants" },
		{ field: "enabled", header: "Status" },
	];

	storeRowDetails(details: any) {
		this.selectedRow = details;
	}

	constructor(
		private usersService: UsersService,
		private dialogService: DialogService
	) {}

	ngOnInit(): void {
		this.usersService.getAll().subscribe((data: any) => {
			console.log("users", data);
			this.tenantUsers = data;
			this.tenantUsers$.next(this.tenantUsers);
		});
	}

	getNumberofColumns() {
		return this.columns.length;
	}

	onContextMenu($event: Event) {
		console.log("Context Menu", $event);
	}

	onContextMenuSelect($event: any) {
		console.log("Context Menu Select", $event.data.enabled);
		this.menuItems = [
			{
				label: "Edit",
				icon: "pi pi-pencil",
				command: () => {
					this.openUpdateDialog(this.selectedRow);
				},
			},
			{
				label: $event.data.enabled ? "Disable" : "Enable",
				icon: $event.data.enabled ? "pi pi-times" : "pi pi-check",
				command: () => this.toggleUserStatus($event.data),
			},
		];
	}

	getUserDisabledStatus(tenant: any) {
		this.menuItems = [
			{
				items: [
					{
						label: "Edit",
						icon: "pi pi-pencil",
						command: () => {
							this.openUpdateDialog(this.selectedRow);
						},
					},
					{
						label: tenant.enabled ? "Disable" : "Enable",
						icon: tenant.enabled ? "pi pi-times" : "pi pi-check",
						command: () => this.toggleUserStatus(tenant),
					},
				],
			},
		];
	}

	toggleUserStatus(tenant: any) {
		this.usersService.disableSystemUser(tenant.id).subscribe(() => {
			this.usersService.getAll().subscribe((users: any) => {
				this.tenantUsers = users;
				this.tenantUsers$.next(this.tenantUsers);

				// Refresh menuItems after toggle
				this.getUserDisabledStatus(tenant);
			});
		});
	}

	openCreateDialog() {
		this.ref = this.dialogService.open(CreateDialogComponent, {
			header: "Create User",
			width: "70%",
		});

		this.ref.onClose.subscribe((data: any) => {
			if (data) {
				this.usersService.getAll().subscribe((users: any) => {
					this.tenantUsers = users;
					this.tenantUsers$.next(this.tenantUsers);
				});
			}
		});
	}

	openUpdateDialog(user: any) {
		this.ref = this.dialogService.open(UpdateDialogComponent, {
			header: "Update User",
			width: "70%",
			data: { user_data: user },
		});

		this.ref.onClose.subscribe((data: any) => {
			if (data) {
				this.usersService.getAll().subscribe((users: any) => {
					this.tenantUsers = users;
					this.tenantUsers$.next(this.tenantUsers);
				});
			}
		});
	}
}
