import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DialogService,
} from 'primeng/dynamicdialog';
import { PortalAuthenticationService } from '../../_services/portal-authentication.service';
import { MessageService } from 'primeng/api';
import { PortalPasswordResetComponent } from '../portal-password-reset/portal-password-reset.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-portal-login-uk',
  templateUrl: './portal-login-uk.component.html',
  styleUrls: ['./portal-login-uk.component.scss'],
})
export class PortalLoginUkComponent implements OnInit {
  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public messageService: MessageService,
    private pas: PortalAuthenticationService,
  ) {}

  portalLoginForm = this.fb.group({
    username: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
  });

  loading = false;
  submitted = false;
  error = '';
  errorMessages: any;
  countries: any;

  get f() {
    return this.portalLoginForm.controls;
  }

  ngOnInit(): void {
    console.log(this.config.data.parkDetails);

    this.http.get('/assets/countries.json').subscribe((countries: any) => {
      this.countries = countries;
    });
  }

  onLoginSubmit() {
    console.log('Form valid: ', this.portalLoginForm.valid);
    console.log('Form values: ', this.portalLoginForm.value);
    console.log('Original form values: ', this.portalLoginForm);

    this.submitted = true;
    // stop here if form is invalid
    if (this.portalLoginForm.invalid) {
      return;
    }

    if (this.portalLoginForm.valid) {
      this.pas
        .login(
          this.portalLoginForm.value.username,
          this.portalLoginForm.value.password,
        )
        .subscribe(
          (response) => {
            console.log('Response: ', response);
            this.ref.close();
            this.messageService.add({
              key: 'portal',
              severity: 'success',
              summary: 'Success',
              detail: 'Login successful',
            });
          },
          (error) => {
            this.error = error;
            this.errorMessages = error.error.detail;
          },
        );
    }
  }

  closeLoginDialog() {
    this.ref.close();
  }

  openPasswordResetDialog(event: any) {
    event.stopPropagation();
    event.preventDefault();
    console.log('Open password reset dialog');
    this.ref.close();
    this.ref = this.dialogService.open(PortalPasswordResetComponent, {
      header: 'Password Reset',
      width: '70%',
      showHeader: false,
      closeOnEscape: true,
      data: {
        username: this.portalLoginForm.value.username,
      },
    });
  }
}
