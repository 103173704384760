import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-update-registration-dialog',
  templateUrl: './update-registration-dialog.component.html',
  styleUrls: ['./update-registration-dialog.component.scss']
})
export class UpdateRegistrationDialogComponent implements OnInit {
  data: any;

  updateRegistrationForm = this.fb.group({
    label: ['', Validators.required],
    value: ['', Validators.required],
    option_type: ['', Validators.required],
  });

  optionTypes: any[] = [
    { label: 'Suburb', value: 'suburb' },
    { label: 'Complex', value: 'complex' },
    { label: 'Agent', value: 'agent' },
  ];

  constructor(
    private config: DynamicDialogConfig,
    private fb: FormBuilder,
    private ref: DynamicDialogRef
  ) { }



  ngOnInit(): void {
    this.data = this.config.data.data;
    console.log(this.data);
    
    this.updateRegistrationForm.patchValue({
      label: this.data.label,
      value: this.data.value,
      option_type: this.data.option_type,
    });
  }

  onSubmit() {
    console.log(this.updateRegistrationForm.value);

    // mark all fields as touched
    this.updateRegistrationForm.markAllAsTouched();

    // is valid
    if (this.updateRegistrationForm.valid) {
      this.ref.close(this.updateRegistrationForm.value);
    }
  }

}
