import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { PortalTransactionsService } from 'src/app/features/portal/_services/portal-transactions.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent implements OnInit {
  transactions: any[] = [];
  transactions$: Subject<any[]> = new Subject<any[]>();
  isLoading: boolean = true;
  selectedRow: any = {};
  menuItems: MenuItem[] = [];

  columns: any[] = [
    { field: 'transaction_date', header: 'Transaction Date' },
    { field: 'transaction_date_due', header: 'Transaction Date Due' },
    { field: 'status', header: 'Status' },
    { field: 'transaction_type', header: 'Transaction Type' },
    { field: 'transaction_method', header: 'Transaction Method' },
    { field: 'transaction_description', header: 'Transaction Description' },
    { field: 'portal_end_customer.first_name', header: 'End Customer Name' },
  ];

  constructor(private transactionsService: PortalTransactionsService) {}

  ngOnInit(): void {
    this.retrievePortaltransactions();

    this.menuItems = [
      {
        label: 'Edit',
        icon: 'pi pi-fw pi-pencil',
        command: () => {
          console.log('Edit', this.selectedRow);
        },
      },
      {
        label: 'Delete',
        icon: 'pi pi-fw pi-trash',
        command: () => {
          console.log('Delete', this.selectedRow);
        },
      }
    ];
  }

  retrievePortaltransactions() {
    this.isLoading = true;
    this.transactionsService.getPortalTransactions().subscribe((res: any) => {
      this.transactions = res;
      console.log(this.transactions);
      this.transactions$.next(this.transactions);
      this.isLoading = false;
    });
  }

  getNumberofColumns() {
    return this.columns.length;
  }

  onContextMenu($event: Event) {
		console.log("Context Menu", $event);
	}

  storeRowDetails(details: any) {
    this.selectedRow = details;
  }

  getColumnValueFromFieldPath(path: string, data: any) {
    if (path.includes('.')) {
      const fields = path.split('.');
      let value = data;
      fields.forEach((field) => {
        value = value[field];
      });
      return value;
    } else {
      return data[path];
    }
  }
}
