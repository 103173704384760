import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ApiService } from 'src/app/_core/_services/api.service';
import { NetworkServices, NetworkSeviceActions } from '../_models/network-services.model';

@Injectable({
  providedIn: 'root',
})
export class NetworkServicesService {
  constructor(private api: ApiService) {}

  getNetworkServices(queryParams?: any) {
    if (queryParams) {
      queryParams = {
        ...queryParams,
      };
    } else {
      queryParams = {
        limit: 100,
        offset: 0,
        q: '',
        filter: [],
      };
    }

    return this.api.getAPI('network-portal/services', queryParams).pipe(
      map((res: any) => {
        return res.body;
      }),
    );
    
  }

  createNetworkService(data: NetworkSeviceActions, circuit_no: string) {
    return this.api.postAPI(`network-portal/services/${circuit_no}/create`, data);
  }

  regradeNetworkService(data: NetworkSeviceActions, service_id: string) {
    return this.api.postAPI(`network-portal/services/${service_id}/regrade`, data);
  }

  suspendNetworkService(data: NetworkSeviceActions, service_id: string) {
    return this.api.postAPI(`network-portal/services/${service_id}/suspend`, data);
  }

  activateNetworkService(data: NetworkSeviceActions, service_id: string) {
    return this.api.postAPI(`network-portal/services/${service_id}/activate`, data);
  }

  cancelNetworkService(data: NetworkSeviceActions, service_id: string) {
    return this.api.postAPI(`network-portal/services/${service_id}/cancel`, data);
  }
}
