<div>
	<h1>{{ selected_action }} Service</h1>
</div>
<div>
	<div class="grid">
		<div class="col-12 flex flex-column">
			<div><h3>Product ID</h3></div>

			<div class="">
				<p-dropdown
					[(ngModel)]="selectedProduct"
					[options]="products"
					optionLabel="name"
					optionValue="id"
					placeholder="Select Product"
					styleClass="w-full"
					[disabled]="
						selected_action !== 'Regrade' && selected_action !== 'Create'
					"
					appendTo="body"
				></p-dropdown>
			</div>
		</div>

		<div class="col-12 flex flex-column">
			<div><h3>Circuit Number</h3></div>
			<div
				class=""
				*ngIf="selected_action !== 'Create'; else createCircuit"
			>
				<label
					for="description"
					class="text-gray-800"
					>{{ data.end_customer_circuit[0].circuit_no }}</label
				>
			</div>

			<ng-template #createCircuit>
				<p-dropdown
					[(ngModel)]="selectedCircuit"
					[options]="circuits"
					optionValue="circuit_no"
					optionLabel="circuit_no"
					styleClass="w-full"
					placeholder="Select Circuit"
					appendTo="body"
				>
				</p-dropdown>
			</ng-template>
		</div>
		<div
			class="col-12 flex flex-column"
		>
			<div><h3>Date Actioned</h3></div>
			<div class="">
				<p-calendar
					[(ngModel)]="selectedDate"
					appendTo="body"
					dateFormat="yy-mm-dd"
					styleClass="w-full"
				></p-calendar>
			</div>
		</div>
		<div class="surface-border border-top-1 opacity-50 mt-3 col-12"></div>
		<div class="grid flex flex-row">
			<div class="col-6">
				<button
					pButton
					label="Submit"
					class="w-full mr-2 p-button-primary"
					(click)="submitAction()"
					[loading]="loading"
				></button>
			</div>
			<div class="col-6">
				<button
					pButton
					label="Cancel"
					class="w-full p-button-danger"
					(click)="cancelAction()"
					[loading]="loading"
				></button>
			</div>
		</div>
	</div>
</div>
