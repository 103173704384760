import { Component, OnInit } from "@angular/core";
import { IspsService } from "src/app/features/main-application/_services/entities/isps.service";
import { RegionsService } from "src/app/features/main-application/_services/entities/regions.service";
import { VlansService } from "src/app/features/main-application/_services/entities/vlans.service";
import { VnosService } from "src/app/features/main-application/_services/entities/vnos.service";
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import {
	FormComponent,
	JsonFormData,
} from "src/app/features/main-application/components/form/form.component";
import { ConfirmationService, MenuItem } from "primeng/api";
import { BehaviorSubject, Subject } from "rxjs";
import { ProductsService } from "src/app/features/main-application/_services/entities/products.service";
import { ContactsService } from "src/app/features/main-application/_services/entities/contacts.service";
import { EndCustomersService } from "src/app/features/main-application/_services/entities/end-customers.service";
import { EquipmentTypesService } from "src/app/features/main-application/_services/entities/equipment-types.service";
import { SitesService } from "src/app/features/main-application/_services/entities/sites.service";
import { DevicesService } from "src/app/features/main-application/_services/entities/devices.service";
import { EntityService } from "src/app/features/main-application/_services/entity.service";
import { EndCustomerCircuitsService } from "src/app/features/main-application/_services/entities/end-customer-circuits.service";
import { TocsService } from "src/app/features/main-application/_services/entities/tocs.service";
import { TenantsService } from "src/app/_share/_services/tenants.service";
import { RelationshipService } from "../../_services/relationship.service";
import { UserPreferencesService } from "src/app/_share/_services/user-preferences.service";
import { StatisticsService } from "../../_services/statistics.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ViewDetailsComponent } from "../../components/view-details/view-details.component";
import { IspCircuitsService } from "../../_services/entities/isp-circuits.service";
import { ChangeDetectorRef } from "@angular/core";

@Component({
	selector: "app-operations",
	templateUrl: "./operations.component.html",
	styleUrls: ["./operations.component.scss"],
})
export class OperationsComponent implements OnInit {
	ref: DynamicDialogRef | undefined;
	selectedRowCMObservable$: BehaviorSubject<any> = new BehaviorSubject<any>(
		null
	);

	message: { [key: string]: any } = {};
	basicData: any;
	horizontalOptions: any;
	selectedRow: any;
	dataSource = [];
	tabs: any[];
	activeTab = 0;
	selectedRowCM: any;
	configuredDevices: any;
	billedDevices: any;
	availableDevices: any;
	notifiedDevices: any;
	totalDevices: any;
	currentActiveTab: any;
	schemaDefinitions: any = {};
	tabDataSources: { [index: string]: any };
	routeActiveTab$: any;
	devicesSummaryStatistics: any;
	queryParams: string = '';
	menuItems: MenuItem[] = [];

	public formData: JsonFormData | undefined;
	public vnoFormData: JsonFormData | undefined;
	public regionFormData: JsonFormData | undefined;
	public ispFormData: JsonFormData | undefined;
	public productFormData: JsonFormData | undefined;

	constructor(
		private http: HttpClient,
		private cdr: ChangeDetectorRef,
		public dialogService: DialogService,
		private confirmationService: ConfirmationService,
		private route: ActivatedRoute,
		private router: Router,
		public vnosService: VnosService,
		public regionsService: RegionsService,
		public ispsService: IspsService,
		public ispCircuitsService: IspCircuitsService,
		public vlansService: VlansService,
		public productsService: ProductsService,
		public contactsService: ContactsService,
		public sitesService: SitesService,
		public endCustomersService: EndCustomersService,
		public equipmentTypesService: EquipmentTypesService,
		public devicesService: DevicesService,
		public endCustomerCircuitsService: EndCustomerCircuitsService,
		public tocsService: TocsService,
		public entityService: EntityService,
		public relationshipsService: RelationshipService,
		public userPreferencesService: UserPreferencesService,
		public statisticsService: StatisticsService
	) {
		// todo: derive this from the schema
		this.tabs = [
			{ key: "vno", name: "VNOs" },
			{ key: "region", name: "Regions" },
			{ key: "isp", name: "ISPs" },
			{ key: "ispcircuit", name: "ISP Circuits" },
			{ key: "vlan", name: "VLANs" },
			{ key: "product", name: "Products" },
			{ key: "equipmenttype", name: "Equipment" },
			{ key: "site", name: "Sites" },
			{ key: "contact", name: "Contacts" },
			{ key: "device", name: "Devices" },
			{ key: "endcustomer", name: "End Customers" },
			{ key: "endcustomercircuit", name: "End Customer Circuits" },
			{ key: "toc", name: "TOCs" },
		];

		this.tabDataSources = {
			vno: vnosService.vnos$,
			region: regionsService.regions$,
			isp: ispsService.isps$,
			ispcircuit: ispCircuitsService.ispCircuits$,
			vlan: vlansService.vlans$,
			product: productsService.products$,
			equipmenttype: equipmentTypesService.equipmentTypes$,
			site: sitesService.sites$,
			contact: contactsService.contacts$,
			endcustomer: endCustomersService.endCustomers$,
			device: devicesService.devices$,
			endcustomercircuit: endCustomerCircuitsService.endCustomerCircuits$,
			toc: tocsService.tocs$,
		};

		this.entityService.entityServices["Vlans"].service = vlansService;
		this.entityService.entityServices["Vnos"].service = vnosService;
		this.entityService.entityServices["Regions"].service = regionsService;
		this.entityService.entityServices["Isps"].service = ispsService;
		this.entityService.entityServices["IspCircuits"].service =
			ispCircuitsService;
		this.entityService.entityServices["Products"].service = productsService;
		this.entityService.entityServices["Contacts"].service = contactsService;
		this.entityService.entityServices["Sites"].service = sitesService;
		this.entityService.entityServices["Devices"].service = devicesService;
		this.entityService.entityServices["EquipmentTypes"].service =
			equipmentTypesService;
		this.entityService.entityServices["EndCustomers"].service =
			endCustomersService;
		this.entityService.entityServices["EndCustomerCircuits"].service =
			endCustomerCircuitsService;
		this.entityService.entityServices["Tocs"].service = tocsService;
	}

	ngOnInit(): void {
		// fixme: device statistics should be called from the device service/tab etc
		this.statisticsService.getDeviceStatistics().subscribe((data: any) => {
			this.devicesSummaryStatistics = data;
		});

		// 1 get path params
		this.routeActiveTab$ = this.route.params.subscribe((params) => {
			const activeRouteTab = params["activeTab"] || "vno";
			const activeRouteId = params["baseEntityId"] || "";

			if (activeRouteTab) {
				const activeTabIndex = this.tabs.findIndex(
					(tab) => tab.key === activeRouteTab
				);
				this.activeTab = activeTabIndex;
				this.queryParams = '';
			}

			if (activeRouteId) {
				this.router.navigate([]).then(() => {
					this.getTabData(activeRouteTab, activeRouteId);
					this.queryParams = activeRouteId;
				});
			}
		});

		this.http
			.get("/assets/schema-definitions.json")
			.subscribe((schemaData: any) => {
				console.log("schema data", schemaData);

				this.schemaDefinitions = schemaData;
				this.cdr.detectChanges();
			});
	
	}

	openMenu($event: any): MenuItem[] {
		console.log("nmmp open menu", event);
		const selectedRow = $event;
		this.selectedRowCMObservable$.next(selectedRow);

		return [
			{
				label: "Edit",
				icon: "pi pi-pencil",
				command: () => this.showEdit(selectedRow, this.tabs[this.activeTab]),
			},
			{
				label: "View",
				icon: "pi pi-eye",
				command: () => this.showView(selectedRow, this.tabs[this.activeTab]),
			},
			{
				label: "Delete",
				icon: "pi pi-trash",
				command: () => this.showDelete(selectedRow, this.tabs[this.activeTab]),
			},
		];
	}

	showContextAction($event: any, tab: any) {
		this.menuItems = this.openMenu($event);
	}

	changeTab(tabevent: any) {
		// todo: get new data for tab when made active

		const currentActiveTab = this.tabs[tabevent.index];

		const tab: any = currentActiveTab;
		this.currentActiveTab = tab["key"];
		console.log("tab", tab);

		this.router.navigate(["operations", "tab", tab.key]);
		this.getTabData(tab.key);
	}

	getTabData(tab: any, id?: string) {
		const entityMap = {
			totalRecords: 0,
			rows: 100,
			first: 0,
			loading: true,
			sort: "",
			globalFilter: "",
			filter: [],
			id: id || "", // Assign id if provided, otherwise keep it empty
		};

		this.entityService.metaDataEntityMap[tab] = entityMap;
		this.entityService.getEntities(tab);
	}

	recieveMessage(selectedRowData: any, tab: any) {
		this.message[tab.key] = selectedRowData;
	}

	showAdd(tab: any) {
		this.ref = this.dialogService.open(FormComponent, {
			data: {
				formdata: null,
				schema: this.schemaDefinitions[tab.key],
				tab: tab,
			},
			header: "Create a new entity in " + tab.name,
			width: "70%",
			height: "70%",
			closeOnEscape: true,
		});
	}

	showEdit($event: any, tab: any) {
		//console.log('row', $event);
		const row = $event;
		this.ref = this.dialogService.open(FormComponent, {
			data: {
				formdata: row,
				schema: this.schemaDefinitions[tab.key],
				tab: tab,
			},
			header: "Edit " + tab.name,
			width: "70%",
			height: "70%",
			closeOnEscape: true,
		});
	}

	showView($event: any, tab: any) {
		//console.log('row', $event);
		console.log("event", $event);
		console.log("tab", tab);

		const row = $event;
		this.ref = this.dialogService.open(ViewDetailsComponent, {
			data: {
				entityData: row,
				schema: this.schemaDefinitions[tab.key],
				tab: tab,
				mode: "view",
			},
			header: "View " + tab.name,
			width: "70%",
			height: "90%",
			closeOnEscape: true,
		});
	}

	showDeleteAll(tab: any) {
		this.confirmationService.confirm({
			message: "Are you sure that you want to delete all " + tab.name + "?",
			accept: () => {
				//Actual logic to perform a confirmation
				//console.log('delete', $event);
				this.entityService.deleteAllEntities(tab.key);
			},
		});
	}

	getInputValue(e: any): any {
		const inputelement = e.target as HTMLInputElement;
		return inputelement.value;
	}

	onContextMenu($event: any) {
		this.selectedRowCM = $event.data;
		//console.log('cm', $event);
	}

	// showContextAction($event: any, tab: any) {
	// 	console.log("context action", $event);
	// 	console.log("tab", tab);

	// 	switch ($event.action) {
	// 		case "edit":
	// 			this.showEdit($event.rowData, tab);
	// 			break;
	// 		case "delete":
	// 			this.showDelete($event.rowData, tab);
	// 			break;
	// 		case "view":
	// 			this.showView($event.rowData, tab);
	// 			break;
	// 		default:
	// 			break;
	// 	}
	// }
	showDelete(data: any, tab: any) {
		this.confirmationService.confirm({
			message:
				"Are you sure that you want to delete the selected " + tab.name + "?",
			accept: () => {
				//Actual logic to perform a confirmation
				//console.log('delete', $event);
				this.entityService.deleteEntity(tab.key, data.id);
			},
		});
	}

	showDetails(selectedRowData: any, tab: any) {
		console.log("show details", selectedRowData);

		this.message[tab.key] = selectedRowData;
	}

	showRelatedEntity($event: any) {
		console.log("show related entity", $event);
		const baseEntityTab = $event.path.split(".")[0].replace(/s$/, "");

		const formattedBaseEntityTab = baseEntityTab.replace(/_/g, "");

		const queryParams: { id: any | undefined } = {
			id: $event.entity[baseEntityTab]?.id || $event.entity.id,
		};

		console.log("Base entity tab", baseEntityTab);
		console.log("Formatted base entity tab", formattedBaseEntityTab);
		

		this.router
			.navigate(["operations", "tab", formattedBaseEntityTab, queryParams.id])
			.then(() => {
				this.getTabData(baseEntityTab, queryParams.id);
			});

		this.showView($event.entity[baseEntityTab] || $event.entity, { key: baseEntityTab, name: baseEntityTab });
	}
}
