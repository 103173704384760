import { Component, OnInit } from "@angular/core";
import { ConfirmationService, MenuItem } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { BehaviorSubject, Observable, Subscribable } from "rxjs";
import { CreatePackageDialogComponent } from "../packages/create-package-dialog/create-package-dialog.component";
import { RegistrationOptionsService } from "../../../_services/registration-options.service";
import { CreateOptionDialogComponent } from "./create-option-dialog/create-option-dialog.component";
import { UpdateRegistrationDialogComponent } from "./update-registration-dialog/update-registration-dialog.component";
import { MessageService } from "primeng/api";

@Component({
	selector: "app-registration-options",
	templateUrl: "./registration-options.component.html",
	styleUrls: ["./registration-options.component.scss"],
})
export class RegistrationOptionsComponent implements OnInit {
	registrationOptions: any[] = [];
	registrationOptions$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	selectedOption: any;
	selectedOptionType: string = "suburb";

	menuItems!: MenuItem[];
	selectedRow: any = {};

	optionTypes: any[] = [
		{ label: "Suburb", value: "suburb" },
		{ label: "Complex", value: "complex" },
		{ label: "Agent", value: "agent" },
	];

	isLoading: boolean = false;
	isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	columns: any[] = [
		{ field: "label", header: "Label" },
		{ field: "value", header: "Value" },
		{ field: "option_type", header: "Option Type" },
	];

	constructor(
		public dialogService: DialogService,
		private confirmationService: ConfirmationService,
		private pros: RegistrationOptionsService,
		private messageService: MessageService
	) {}

	ngOnInit(): void {
		this.retrieveRegistrationOptions("suburb");
	}

	storeRowDetails(details: any) {
		this.selectedRow = details;
	}

	addRegistrationOption() {
		const ref = this.dialogService.open(CreateOptionDialogComponent, {
			data: {
				data: "",
			},
			width: "50%",

			closable: true,
			showHeader: true,
			header: "Create Registration Option",
		});

		ref.onClose.subscribe((res) => {
			if (res) {
				console.log("res of options", res);

				this.pros.addRegistrationOption(res).subscribe(
					(result: any) => {
						this.retrieveRegistrationOptions(this.selectedOptionType);
						this.messageService.add({
							severity: "success",
							summary: "Success",
							detail: "Registration Option Added Successfully",
						});
					},
					(error) => {
						this.messageService.add({
							severity: "error",
							summary: "Error",
							detail: `There was an error adding the registration option: ${error.error.message}`,
						});
					}
				);

				this.retrieveRegistrationOptions(this.selectedOptionType);
			}
		});
	}

	onContextMenuSelect($event: any) {
		console.log("Context Menu Select", $event.data);
		this.menuItems = [
			{
				label: "Edit",
				icon: "pi pi-pencil",
				command: () => {
					this.openUpdateDialog($event.data);
				},
			},
			{
				label: "Delete",
				icon: "pi pi-trash",
				command: () => {
					this.deleteRegistrationOption($event.data);
				},
			},
		];
	}

	openUpdateDialog(selectedRow: any) {
		const ref = this.dialogService.open(UpdateRegistrationDialogComponent, {
			data: {
				data: selectedRow,
			},
			width: "50%",

			closable: true,
			showHeader: true,
			header: "Update Registration Option",
		});

		ref.onClose.subscribe((res) => {
			if (res) {
				console.log("res of options", res);

				this.pros.updateRegistrationOption(res, selectedRow.id).subscribe(
					(result: any) => {
						this.retrieveRegistrationOptions(this.selectedOptionType);
						this.messageService.add({
							severity: "success",
							summary: "Success",
							detail: "Registration Option Updated Successfully",
						});
					},
					(error) => {
						this.messageService.add({
							severity: "error",
							summary: "Error",
							detail: `There was an error updating the registration option: ${error.error.message}`,
						});
					}
				);

				this.retrieveRegistrationOptions(this.selectedOptionType);
			}
		});
	}

	retrieveRegistrationOptions(option_type: string) {
		this.isLoading = true;
		this.isLoading$.next(true);
		const queryParams = {
			limit: 2000,
			offset: 0,
			option_type: option_type,
		};
		this.pros.getRegistrationOptions(queryParams).subscribe((res) => {
			this.registrationOptions = res;
			this.registrationOptions$.next(this.registrationOptions);
			console.log("registrationOptions", this.registrationOptions);
			this.isLoading = false;
			this.isLoading$.next(false);
		});
	}
	getNumberofColumns() {
		return this.columns.length;
	}

	deleteRegistrationOption(selectedRow: any) {
		this.confirmationService.confirm({
			message: "Are you sure that you want to delete this registration option?",
			key: "deleteRegistrationOption",
			accept: () => {
				this.pros.deleteRegistrationOption(selectedRow.id).subscribe(
					(res) => {
						this.retrieveRegistrationOptions(this.selectedOptionType);
						this.messageService.add({
							severity: "success",
							summary: "Success",
							detail: "Registration Option Deleted Successfully",
						});
					},
					(error) => {
						this.messageService.add({
							severity: "error",
							summary: "Error",
							detail: `There was an error deleting the registration option: ${error.error.message}`,
						});
					}
				);
			},
		});
	}
}
