<form
  [formGroup]="registrationOptionForm"
  (ngSubmit)="onRegistrationOptionSubmit()"
>
  <div class="grid formgrid p-fluid">
    <div class="field col-12">
      <label class="text-gray-800">Option Type: </label>
      <p-dropdown
        [options]="optionTypes"
        formControlName="option_type"
        optionLabel="label"
        optionValue="value"
        placeholder="Select Option Type"
        appendTo="body"
      ></p-dropdown>
      <p-message
        *ngIf="
          registrationOptionForm.controls['option_type'].invalid &&
          registrationOptionForm.controls['option_type'].touched
        "
        severity="error"
        text="Option Type is required"
      ></p-message>
    </div>
    <div class="field col-12 md:col-6">
      <label for="name" class="text-gray-800">Value: </label>
      <input
        id="value"
        type="text"
        formControlName="value"
        class="p-inputtext p-component p-filled"
      />
      <p-message
        *ngIf="
          registrationOptionForm.controls['value'].invalid &&
          registrationOptionForm.controls['value'].touched
        "
        severity="error"
        text="Value is required"
      ></p-message>
    </div>
    <div class="field col-12 md:col-6">
      <label for="description" class="text-gray-800">Label: </label>
      <input
        id="description"
        type="text"
        formControlName="label"
        class="p-inputtext p-component p-filled"
      />
      <p-message
        *ngIf="
          registrationOptionForm.controls['label'].invalid &&
          registrationOptionForm.controls['label'].touched
        "
        severity="error"
        text="Label is required"
      ></p-message>
    </div>
    <!-- submit -->
    <div class="field col-12">
      <p-button type="submit" label="Submit Option"></p-button>
    </div>
  </div>
</form>
