<div class="flex geologica-benington">
  <div class="flex-1">
    <h1 class="text-3xl font-semibold text-blue-400">
      Purchase WiFi at
      <span class="font-bold text-green-500">{{
        config.data.dialogDetails.park.name
      }}</span>
    </h1>

    <p class="text-lg text-gray-600">
      Please fill in the form below to register as a new user at
      <span class="font-bold">{{ config.data.dialogDetails.park.name }}</span>
    </p>
  </div>
  <div class="flex">
    <button
      pButton
      class=""
      icon="pi pi-times"
      class="p-button-rounded p-button-danger mt-2 p-button-text"
      (click)="closePurchaseDialog()"
    ></button>
  </div>
</div>

<!-- form section -->
<div class="geologica-benington">
  <!-- payment form -->
  <form [formGroup]="paymentForm">
    <div class="formgrid grid p-fluid">
      <div class="field col-12">
        <p-dropdown
          #planDropdown
          placeholder="Select a Wi-Fi Plan"
          [options]="packages"
          optionLabel="name"
          optionValue="value"
          formControlName="plan"
          [(ngModel)]="selectedPackage"
          [tabindex]="0"
        >
        </p-dropdown>
      </div>

      <div
        class="col-12 md:col-9 field align-items-center justify-content-center"
      >
        <p *ngIf="selectedPackage">
          You have selected
          <span class="text-lg font-bold">{{
            getPackageDetails(selectedPackage).name
          }}</span>
          which costs
          <span class="text-lg font-bold text-green-500">{{
            getPackageDetails(selectedPackage).price | currency: "GBP"
          }}</span>
        </p>
      </div>

      <div class="col-12 field">
        <ngx-stripe-card
          (change)="onCardChange($event)"
          [options]="cardOptions"
          [elementsOptions]="elementsOptions"
          [stripe]="stripe"
        ></ngx-stripe-card>
      </div>
      <div class="surface-border border-top-1 opacity-50 mb-4 col-12"></div>

      <div class="field col-12 md:col-4">
        <button
          pButton
          type="button"
          class="w-full p-button-success p-button-rounded p-button-raised white-space-nowrap hover:bg-green-200 mb-3 px-5 py-3 mx-1 my-1 lg:my-0 p-button-lg"
          label="Pay"
          (click)="onPay()"
          icon="pi pi-money-bill"
          [loading]="this.paymentLoading"
        ></button>
      </div>
      <div class="field col-12" *ngIf="errorMessage">
        <!-- <div class="flex align-items-center justify-content-between"> -->
        <p-message
          severity="error"
          [text]="errorMessage"
          class="card-errors"
        ></p-message>
        <!-- </div> -->

        <!-- <span class="text-red-500 font-bold pt-3">{{ errorMessages }}</span> -->
        <!-- </div> -->
      </div>
      <!-- <div class="field col-12">
        <p-message
          #errormsg
          *ngIf="errorMessage != ''"
          severity="error"
          [text]="errorMessage"
          class="w-full"
          hideTransitionOptions="500ms ease-out"
        ></p-message>
      </div> -->
    </div>
  </form>
</div>
