<div
  class="flex flex-column align-items-center text-center p-4 min-h-screen h-screen max-h-screen"
>
  <div class=""></div>
  <div class="flex-1 flex-grow-1">
    <p-steps [model]="steps" [(activeIndex)]="activeStepIndex"></p-steps>

    <!-- Step 1: Product Selection -->
    <div *ngIf="activeStepIndex === 0">
      <h2>Select a Product</h2>
      <div class="grid">
        <div class="col-12 md:col-6" *ngFor="let product of products">
          <div class="surface-ground p-4 h-full border-round">
            <h3 class="text-900 text-2xl mb-3">{{ product.name }}</h3>
            <div class="text-900 text-3xl mb-3">
              <span class="font-bold">R{{ product.basePrice }}.00</span>
              <span class="text-600 text-xl">/month per channel</span>
            </div>
            <button
              pButton
              label="Select"
              class="p-button-primary w-full mt-4"
              (click)="selectProduct(product)"
            ></button>
          </div>
        </div>
      </div>
    </div>

    <!-- Step 2: Basic Configuration -->
    <div *ngIf="activeStepIndex === 1" class="w-full max-w-30rem">
      <h2>Basic Configuration</h2>

      <div class="mb-4">
        <label class="block text-900 font-medium mb-2">
          Concurrent Channels: {{ channels }}
        </label>
        <p-slider
          [(ngModel)]="channels"
          [min]="1"
          [max]="10"
          class="w-full"
        ></p-slider>
      </div>

      <div class="mb-4">
        <label class="block text-900 font-medium mb-2">
          SIP Accounts: {{ sipAccounts }}
        </label>
        <p-inputNumber
          [(ngModel)]="sipAccounts"
          [showButtons]="true"
          [min]="1"
          [max]="20"
          buttonLayout="horizontal"
          spinnerMode="horizontal"
          inputId="sipAccounts"
          class="w-full"
        ></p-inputNumber>
      </div>

      <div class="mb-4">
        <label class="block text-900 font-medium mb-2">
          Phone Numbers: {{ phoneNumbers }}
        </label>
        <p-inputNumber
          [(ngModel)]="phoneNumbers"
          [showButtons]="true"
          [min]="1"
          [max]="20"
          buttonLayout="horizontal"
          spinnerMode="horizontal"
          inputId="phoneNumbers"
          class="w-full"
        ></p-inputNumber>
      </div>

      <div class="mb-4">
        <label class="block text-900 font-medium mb-2">
          Physical Phones: {{ physicalPhones }}
        </label>
        <p-inputNumber
          [(ngModel)]="physicalPhones"
          [showButtons]="true"
          [min]="0"
          [max]="20"
          buttonLayout="horizontal"
          spinnerMode="horizontal"
          inputId="physicalPhones"
          class="w-full"
        ></p-inputNumber>
      </div>

      <div class="flex justify-content-between mt-4">
        <button
          pButton
          label="Back"
          class="p-button-secondary"
          (click)="previousStep()"
        ></button>
        <button
          pButton
          label="Next"
          class="p-button-primary"
          (click)="nextStep()"
        ></button>
      </div>
    </div>

    <!-- Step 3: AI Features -->
    <div *ngIf="activeStepIndex === 2" class="w-full max-w-30rem">
      <h2>AI Features</h2>

      <div class="grid">
        <div class="col-12 mb-3" *ngFor="let feature of aiFeatures">
          <div class="surface-card p-4 border-round">
            <div class="flex align-items-center justify-content-between">
              <div>
                <h3 class="text-900 mb-2">{{ feature.name }}</h3>
                <p class="text-600 m-0">{{ feature.description }}</p>
                <div class="text-primary">+R{{ feature.price }}.00 /month</div>
              </div>
              <p-checkbox
                [(ngModel)]="feature.selected"
                [binary]="true"
                (onChange)="toggleAIFeature(feature)"
              ></p-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-content-between mt-4">
        <button
          pButton
          label="Back"
          class="p-button-secondary"
          (click)="previousStep()"
        ></button>
        <button
          pButton
          label="Next"
          class="p-button-primary"
          (click)="nextStep()"
        ></button>
      </div>
    </div>

    <!-- Step 4: Review & Confirm -->
    <div *ngIf="activeStepIndex === 3" class="w-full max-w-30rem">
      <h2>Review & Confirm</h2>

      <div class="surface-card p-4 border-round mb-4">
        <h3 class="text-900 mb-2">Selected Product</h3>
        <p class="text-600">{{ selectedProduct?.name }}</p>

        <h3 class="text-900 mb-2">Configuration</h3>
        <ul class="list-none p-0 m-0">
          <li class="mb-2">Concurrent Channels: {{ channels }}</li>
          <li class="mb-2">SIP Accounts: {{ sipAccounts }}</li>
          <li class="mb-2">Phone Numbers: {{ phoneNumbers }}</li>
          <li class="mb-2">Physical Phones: {{ physicalPhones }}</li>
        </ul>

        <h3 class="text-900 mb-2">Selected AI Features</h3>
        <ul class="list-none p-0 m-0">
          <li class="mb-2" *ngFor="let feature of aiFeatures">
            <span *ngIf="feature.selected">
              {{ feature.name }} (+R{{ feature.price }}.00 /month)
            </span>
          </li>
        </ul>
      </div>

      <div class="flex justify-content-between">
        <button
          pButton
          label="Back"
          class="p-button-secondary"
          (click)="previousStep()"
        ></button>
        <button
          pButton
          label="Confirm Order"
          class="p-button-success"
          (click)="confirmSelection()"
        ></button>
      </div>
    </div>
    <!-- Price Summary - Always Visible -->
    <div class="surface-card p-4 shadow-2 border-round w-full mb-4">
      <div class="text-900 font-medium text-xl mb-2">Price Summary</div>
      <div class="grid">
        <div class="col-6">
          <div class="text-500">Monthly Total</div>
          <div class="text-900 text-2xl font-medium">
            R{{ monthlyTotal }}.00
          </div>
        </div>
        <div class="col-6">
          <div class="text-500">Once-off Total</div>
          <div class="text-900 text-2xl font-medium">
            R{{ onceOffTotal }}.00
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
