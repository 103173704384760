import { Injectable } from '@angular/core';
import { ApiService } from '../../../_core/_services/api.service';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PortalUsersService {
  constructor(
    private api: ApiService,
    private http: HttpClient,
  ) {}

  getPortalUsers() {
    return this.api.getAPI('portal/users/get');
  }

  getPortalUserById(portalUserId: number) {
    return this.api.getAPI(`portal/user/${portalUserId}`).pipe(
      map((res: any) => {
        return res.body.data;
      }),
    );
  }

  addPortalUser(portalUser: any) {
    return this.api.postAPI('portal/user/register', portalUser);
  }

  confirmPortalUser(portalConfirmation: any) {
    // portalConfirmation.portal_end_customer_id = 22;
    return this.api.postAPI(`portal/user/confirmation`, portalConfirmation);
  }

  resendConfirmationOTP(portal_end_customer_id: any) {
    // portal_end_customer_id = 22;
    return this.api.getAPI(
      `portal/user/resend-confirmation/${portal_end_customer_id}`,
    );
  }

  updatePortalUser(user_id: any, portalUser: any) {
    return this.api.putAPI(`portal/user/update`, user_id, portalUser).subscribe(
      (res) => {
        console.log('res', res);
      },
      (error) => {
        console.log('error', error);
      },
    );
  }

  deletePortalUser(portalUserId: number) {
    return this.api.deleteAPI(`portal/user/delete`, portalUserId).subscribe(
      (res) => {
        console.log('res', res);
      },
      (error) => {
        console.log('error', error);
      },
    );
  }

  resetPasswordCombination(portalUserDetailsCombination: any) {
    return this.api.postAPI(
      'portal/reset-password-combination',
      portalUserDetailsCombination,
    );
  }
}
