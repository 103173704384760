

<p-table
	#shareTable
	[value]="data | async"
	[lazy]="true"
	[scrollable]="true"
	[paginator]="true"
	[rowsPerPageOptions]="[50, 100, 200]"
	[showCurrentPageReport]="!metaData.loading"
	[rows]="metaData.rows"
	[totalRecords]="metaData.totalRecords || 0"
	[contextMenu]="enableContextMenu === true ? cm : null"
  [resizableColumns]="true"
	(onRowSelect)="onRowSelect($event)"
	(onLazyLoad)="loadDataLazy($event)"
	(onContextMenu)="onContextMenu($event)"
  (onContextMenuSelect)="onContextMenuSelect($event)"
	(onRowUnselect)="onRowUnselect($event)"
	[(selection)]="selectedRow"
	[(contextMenuSelection)]="selectedRowCM"
	scrollDirection="both"
	scrollHeight="55vh"
	selectionMode="single"
	responsiveLayout="scroll"
	scrollDirection="both"
	currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
	class="z-0"
>
  <ng-template pTemplate="caption">
    <div class="flex">
      <button
        pButton
        label="Clear"
        class="p-button-outlined"
        icon="pi pi-filter-slash"
        (click)="clearTableFilters(shareTable)"
      ></button>

      <p-multiSelect
        [options]="columns"
        optionLabel="header"
        optionValue="field"
        [(ngModel)]="visibleColumns"
        [selectedItemsLabel]="getTruncatedSelection()"
        
      >
      </p-multiSelect>
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          name="globalFilter"
          (input)="
            shareTable.filterGlobal(getFilterInputValue($event), 'contains')
          "
          placeholder="Search keyword"
        />
      </span>
    </div>
    <div *ngIf="queryParams">
      <button
        pButton
        label="ID: {{queryParams}}"
        class="p-button-info p-button-raised p-button-rounded p-button-sm font-bold mt-2"
        style="cursor: default"
      ></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        [hidden]="!visibleColumns.includes(col.field)"
      >
        <div class="flex align-items-center">
          {{ col.header }}
          <p-columnFilter
            type="text"
            [field]="col.field"
            display="menu"
          ></p-columnFilter>

					<!-- <p-columnFilter
            *ngIf="col.field === 'date_created'"
            [type]="getColumnFilterType(col)"
            type="date"
            [field]="col.field"
            display="menu"
          ></p-columnFilter>

          <p-columnFilter
            *ngIf="false"
            matchMode="equals"
            [field]="col.field"
            display="menu"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <p-dropdown
                [ngModel]="value"
                [options]="booleanStatuses"
                (onChange)="filter($event.value)"
                placeholder="Any"
              >
                <ng-template let-option pTemplate="item">
                  <span [class]="'badge status-' + option.value">{{
                    option.label
                  }}</span>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter> -->
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-entity>
    <tr [pSelectableRow]="entity" [pContextMenuRow]="entity" >
      <td
        *ngFor="let col of columns"
        class="white-space-nowrap"
        [hidden]="!visibleColumns.includes(col.field)"
      >
        <ng-container *ngIf="getNestedValue(entity, col.field) as result">
          <ng-container *ngIf="result.nested; else notnested">
            <ng-container *ngIf="result.plural; else singleObject">
              <ng-container *ngFor="let item of result.items">
                <!-- {{ item | json }} -->

                <button
                  (click)="onNestedObjectClick(item, col.field)"
                  pButton
                  pTooltip="Navigate to related entity"
                  class="p-button p-button-rounded"
                  [label]="getPluralNestedField(item)"
                ></button>
              </ng-container>
            </ng-container>
            <ng-template #singleObject>
              <button
                (click)="onNestedObjectClick(entity, col.field)"
                pButton
                pTooltip="Navigate to related entity"
                class="p-button p-button-link"
                [label]="result.value"
              ></button>
            </ng-template>
          </ng-container>
          <ng-template #notnested>
            <span *ngIf="result.type === 'date'">{{
              result.value | date: "yyyy-MM-dd"
            }}</span>
            <span *ngIf="result.type === 'datetime'">{{
              result.value | date: "yyyy-MM-ddTHH:mm:ss"
            }}</span>
            <span *ngIf="result.type === 'number'">{{
              result.value | number
            }}</span>
            <span
              *ngIf="result.type === 'boolean' && col.field !== 'active'"
              class="badge status-{{ result.value ? 'true' : 'false' }}"
            >
              {{ result.value ? "TRUE" : "FALSE" }}
            </span>
            <span
              *ngIf="col.field === 'toc_status'"
              class="badge status-{{ result.value }}"
            >
              {{ result.value }}
            </span>
            <span
              *ngIf="
                result.type === 'string' &&
                col.field !== 'toc_status' &&
                col.field !== 'toc_file_reference' &&
                col.field !== 'physical_status' &&
                col.field !== 'device_physical_status' &&
                col.field !== 'subscription_status' &&
                col.field !== 'account_status' &&
                col.field !== 'active' &&
                col.field !== 'service_status'
              "
              >{{ result.value }}</span
            >

            <div
              *ngIf="
                col.field === 'physical_status' ||
                col.field === 'device_physical_status'
              "
              class="text-center text-{{
                getDevicePhysicalStatusColor(entity)
              }}-500 "
            >
              <span
                class="text-center pi {{
                  getDevicePhysicalStatus(entity)
                }} text-2xl"
              ></span>
            </div>
            <ng-container *ngIf="col.field === 'toc_file_reference'">
              <button
                (click)="downloadFile(entity['toc_file_reference'])"
                pButton
                icon="pi pi-download"
                class="p-button p-button-link"
                [label]="entity['toc_file_reference']"
              ></button>
            </ng-container>
            <ng-container *ngIf="col.field === 'subscription_status'">
                <p-tag
                [value]="result.value | anyAsStatusString | uppercase"
                [severity]="getSubscriptionSeverity(result.value)"
              ></p-tag>
            </ng-container>
            <ng-container class="flex align-items-center" *ngIf="col.field === 'account_status'">
              <p-tag
                [value]="result.value | anyAsStatusString | uppercase"
                [severity]="getAccountSeverity(result.value)"
              ></p-tag>
            </ng-container>
           <ng-container *ngIf="col.field === 'active'">
              <p-tag
              [value]="result.value ? 'ACTIVE' : 'INACTIVE'"
              [severity]="result.value ? 'success' : 'danger'"
            ></p-tag>
            </ng-container>

            <ng-container *ngIf="col.field === 'service_status'">
              <p-tag
                [value]="result.value | uppercase"
                [severity]="
                  getServiceSeverity(result.value)
                "
              ></p-tag>
            </ng-container>
          </ng-template>
        </ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <ng-container *ngIf="metaData.loading">
      <tr>
        <td
          [attr.colspan]="this.getNumberOfColumns()"
          class="align-content-center"
        >
          <p-progressBar
            styleClass="align-self-center"
            *ngIf="metaData.loading"
            mode="indeterminate"
          >
          </p-progressBar>
        </td>
      </tr>
      <tr *ngFor="let number of loadingRowsNumbers">
        <td *ngFor="let col of visibleColumns; let even = even">
          <p-skeleton [ngStyle]="{ width: '10vw', height: '4vh' }"></p-skeleton>
        </td>
      </tr>
    </ng-container>

		<tr *ngIf="metaData.loading === false || shareTable._totalRecords === 0">
			<td [attr.colspan]="getNumberOfColumns()">
				There are no records to show. You might be disconnected or offline.
			</td>
		</tr>
	</ng-template>
</p-table>
<p-contextMenu
  #cm
  [model]="menuItems"
></p-contextMenu>
