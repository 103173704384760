import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from '../../../environments/environment';
import { User } from '../../_share/_models/entities/user';
import { UserService } from 'src/app/_share/_services/user.service';

@Injectable({ providedIn: "root" })
export class AuthenticationService {
	private userSubject: BehaviorSubject<User | null>;
	public user: Observable<User | null>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private userService: UserService,
  ) {
    this.userSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem('user')!),
    );
    this.user = this.userSubject.asObservable();
  }

	public get userValue() {
		return this.userSubject.value;
	}

	login(username: string, password: string) {
		return this.http
			.post<any>(
				`${environment.apiUrl}/login`,

				new URLSearchParams({
					grant_type: "password",
					username: username,
					password: password,
				}),

        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
        },
      )
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('user', JSON.stringify(user));
          this.userSubject.next(user);
          return user;
        }),
      );
  }

  logout() {
    // is called when a 401 or 403 is returned, meaning token is expired
    localStorage.removeItem('user');
    this.userSubject.next(null);
    this.router.navigate(['/login']);
  }

  sessionLogout() {
    // is called when the user clicks the logout button
    this.http.post(`${environment.apiUrl}/logout`, {}).subscribe(() => {
      localStorage.removeItem('user');
      this.userSubject.next(null);
      this.router.navigate(['/login']);
    });
  }
}
