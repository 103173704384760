import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/main-application/main.module').then(
            (m) => m.MainModule,
          ),
      },
    ],
    title: 'STEM | OM',
  },
  {
    path: 'crm',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/crm/crm.module').then((m) => m.CrmModule),
      },
    ],
    title: 'STEM | CRM',
  },
  {
    path: 'portal',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/portal/portal.module').then((m) => m.PortalModule),
      },
      { path: '**', redirectTo: 'portal' },
    ],
    title: 'Portal',
  },

  {
    path: 'business',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/business-sales/business-sales.module').then(
            (m) => m.BusinessSalesModule,
          ),
      },
      // { path: '**', redirectTo: '' },
    ],
    title: 'STEM | Business Sales',
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
