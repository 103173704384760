<div class="flex flex-column align-items-center text-center p-4">
  <h1 class="text-900 text-4xl mb-2">Stem Connect AI Voice</h1>
  <p class="text-primary text-xl mb-6">Save up to 25% today!</p>

  <div class="grid">
    <!-- Basic Plan -->
    <div class="col-12 md:col-4">
      <div class="surface-ground p-4 h-full border-round">
        <h2 class="text-900 text-2xl mb-3">AI Voice Basic</h2>
        <div class="text-900 text-3xl mb-3">
          <span class="font-bold">R30.00</span>
          <span class="text-600 text-xl">/month per channel</span>
        </div>
        <p class="text-600 mb-4">
          Subtitle of the plan maybe two lines even though lines of things.
        </p>
        <ul class="list-none p-0 m-0 text-left">
          <li class="flex align-items-center mb-3">
            <i class="pi pi-check-circle text-primary mr-2"></i>
            <span
              >1x Concurrent call channel, unlimited accounts, up to 10 numbers
              per channel</span
            >
          </li>
          <li class="flex align-items-center mb-3">
            <i class="pi pi-check-circle text-primary mr-2"></i>
            <span>AI call transcription, R10.00/month per channel</span>
          </li>
          <li class="flex align-items-center mb-3">
            <i class="pi pi-check-circle text-primary mr-2"></i>
            <span>Call forwarding, call waiting, voicemail2email included</span>
          </li>
          <li class="flex align-items-center mb-3">
            <i class="pi pi-check-circle text-primary mr-2"></i>
            <span>Mobile app or web dialing</span>
          </li>
          <li class="flex align-items-center mb-3">
            <i class="pi pi-check-circle text-primary mr-2"></i>
            <span>Fixed rate billing for local calls</span>
          </li>
        </ul>
        <button
          pButton
          label="Try Free"
          class="p-button-primary w-full mt-4"
        ></button>
      </div>
    </div>

    <!-- Plus Plan -->
    <div class="col-12 md:col-4">
      <div class="surface-900 p-4 h-full border-round">
        <div class="text-green-500 mb-3">MOST POPULAR</div>
        <h2 class="text-white text-2xl mb-3">AI Voice Plus</h2>
        <div class="text-white text-3xl mb-3">
          <span class="font-bold">R20.00</span>
          <span class="text-gray-400 text-xl">/month</span>
        </div>
        <p class="text-gray-400 mb-4">
          Subtitle of the plan maybe two lines even though lines of things.
        </p>
        <ul class="list-none p-0 m-0 text-left">
          <li class="flex align-items-center mb-3 text-white">
            <i class="pi pi-check-circle text-green-500 mr-2"></i>
            <span>Arcu vitae elementum</span>
          </li>
          <li class="flex align-items-center mb-3 text-white">
            <i class="pi pi-check-circle text-green-500 mr-2"></i>
            <span>Dui faucibus in ornare</span>
          </li>
          <li class="flex align-items-center mb-3 text-white">
            <i class="pi pi-check-circle text-green-500 mr-2"></i>
            <span>Morbi tincidunt augue</span>
          </li>
          <li class="flex align-items-center mb-3 text-white">
            <i class="pi pi-check-circle text-green-500 mr-2"></i>
            <span>Duis ultricies lacus sed</span>
          </li>
        </ul>
        <button
          pButton
          label="Buy Now"
          class="p-button-success w-full mt-4"
        ></button>
      </div>
    </div>

    <!-- Premium Plan -->
    <div class="col-12 md:col-4">
      <div class="bg-blue-900 p-4 h-full border-round">
        <h2 class="text-white text-2xl mb-3">AI Voice Premium</h2>
        <div class="text-white text-3xl mb-3">
          <span class="font-bold">$30</span>
          <span class="text-gray-400 text-xl">/month</span>
        </div>
        <p class="text-gray-400 mb-4">
          Subtitle of the plan maybe two lines even though lines of things.
        </p>
        <ul class="list-none p-0 m-0 text-left">
          <li class="flex align-items-center mb-3 text-white">
            <i class="pi pi-check-circle text-primary mr-2"></i>
            <span>Arcu vitae elementum</span>
          </li>
          <li class="flex align-items-center mb-3 text-white">
            <i class="pi pi-check-circle text-primary mr-2"></i>
            <span>Dui faucibus in ornare</span>
          </li>
          <li class="flex align-items-center mb-3 text-white">
            <i class="pi pi-check-circle text-primary mr-2"></i>
            <span>Morbi tincidunt augue</span>
          </li>
          <li class="flex align-items-center mb-3 text-white">
            <i class="pi pi-check-circle text-primary mr-2"></i>
            <span>Duis ultricies lacus sed</span>
          </li>
          <li class="flex align-items-center mb-3 text-white">
            <i class="pi pi-check-circle text-primary mr-2"></i>
            <span>Imperdiet proin</span>
          </li>
        </ul>
        <button
          pButton
          label="Contact Us"
          class="p-button-primary w-full mt-4"
        ></button>
      </div>
    </div>
  </div>
</div>
