<!-- 


 -->
<!-- <p-contextMenu #cm [model]="items"></p-contextMenu> -->
 <p-toast></p-toast>
<p-contextMenu
	#menu
	[model]="menuItems"
></p-contextMenu>
<div>
	<p-table
		styleClass="p-datatable-striped "
		#optionsTable
		[value]="(registrationOptions$ | async) || []"
		scrollHeight="30vh"
		[(contextMenuSelection)]="selectedOption"
		[scrollable]="true"
		[contextMenu]="menu"
		(onContextMenuSelect)="onContextMenuSelect($event)"
	>
		<ng-template pTemplate="caption">
			<div class="flex">
				<p-dropdown
					[options]="optionTypes"
					[(ngModel)]="selectedOptionType"
					optionLabel="label"
					optionValue="value"
					[style]="{ width: '150px' }"
					(onChange)="retrieveRegistrationOptions(selectedOptionType)"
				></p-dropdown>
				<button
					pButton
					pRipple
					icon="pi pi-plus"
					label="Add Option"
					class="p-button-success ml-auto"
					(click)="addRegistrationOption()"
				></button>
			</div>
		</ng-template>
		<ng-template pTemplate="header">
			<tr>
				<th
					*ngFor="let col of columns"
					[pSortableColumn]="col.field"
				>
					<div class="flex align-items-center">
						{{ col.header }}
					</div>
				</th>
			</tr>
		</ng-template>
		<ng-template
			pTemplate="body"
			let-package
		>
			<tr
				(click)="storeRowDetails(package)"
				style="cursor: pointer"
				[pSelectableRow]="package"
				[pContextMenuRow]="package"
			>
				<td *ngFor="let col of columns">
					<div
						class="flex align-items-center"
						*ngIf="col.field !== 'option_type'"
					>
						{{ package[col.field] }}
					</div>
					<div
						class="flex align-items-center"
						*ngIf="col.field === 'option_type'"
					>
						<p-badge
							[value]="package[col.field] | uppercase"
							severity="info"
						></p-badge>
					</div>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="footer">
			<tr>
				<td
					[attr.colspan]="this.getNumberofColumns()"
					class="align-content-center justify-content-center"
				>
					<p>
						<span class="text-600 text-sm">Total Options: </span>
						{{ optionsTable._totalRecords }}
					</p>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td
					[attr.colspan]="this.getNumberofColumns()"
					class="align-content-center"
				>
					<p-progressBar
						styleClass="align-self-center"
						*ngIf="isLoading$ | async"
						mode="indeterminate"
					>
					</p-progressBar>
					<div *ngIf="!(isLoading$ | async)">
						<p>
							There are no records to show. You might be disconnected or
							offline.
						</p>
					</div>
				</td>
			</tr>
		</ng-template>
	</p-table>

	<p-confirmDialog header="Delete"></p-confirmDialog>
	<p-confirmDialog header="Delete" key="deleteRegistrationOption"></p-confirmDialog>
</div>
