import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { map, Observable, of } from "rxjs";
import { ApiService } from "src/app/_core/_services/api.service";
import { TableColumns } from "src/app/_share/_models/components/table-columns.model";

import { EntityService } from "src/app/features/main-application/_services/entity.service";
import { DropdownFilterOptions } from "primeng/dropdown";

interface JsonFormValidators {
	min?: number;
	max?: number;
	required?: boolean;
	requiredTrue?: boolean;
	email?: boolean;
	minLength?: boolean;
	maxLength?: boolean;
	pattern?: string;
	nullValidator?: boolean;
}
interface JsonFormControlOptions {
	min?: string;
	max?: string;
	step?: string;
	icon?: string;
}
interface JsonFormControls {
	name: string;
	label: string;
	value: string;
	type: string;
	options?: JsonFormControlOptions;
	required: boolean;
	validators: JsonFormValidators;
}

export interface JsonFormData {
	controls: JsonFormControls[];
	columns: TableColumns[];
}

@Component({
	selector: "app-form",
	templateUrl: "./form.component.html",
	styleUrls: ["./form.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent implements OnInit, OnChanges {
	@Input() jsonForm: any;
	@Output() formEvent = new EventEmitter<FormGroup>();
	@Output() myForm: FormGroup = this.fb.group({});
	@Output() myFormArray: FormArray = this.fb.array([]);

	@ViewChild("toc_file") tocFileUpload: any;
	// todo: consider using formarray
	// https://blog.angular-university.io/angular-form-array/
	tab: any;

	selectOptions: any[] = [];
	formData: any;
	selectedOptions: any;
	options: any[] = [];
	multiselectOptions: any[] = [];
	dropdownOptions: { [key: string]: any[] } = {};

	tabEntityMap = {
		vlan: "Vlans",
		vno: "Vnos",
		region: "Regions",
		isp: "Isps",
		product: "Products",
		contact: "Contacts",
		site: "Sites",
		endcustomer: "EndCustomers",
		device: "Devices",
		equipmenttype: "EquipmentTypes",
		endcustomercircuit: "EndCustomerCircuits",
	};

	controlIDTypeMap: any = {
		vlan_id: { key: "vlan", descriptorFields: "description" },
		vno_id: { key: "vno", descriptorFields: "name" },
		region_id: { key: "region", descriptorFields: "name" },
		isp_id: { key: "isp", descriptorFields: "name" },
		product_id: { key: "product", descriptorFields: "name" },
		contact_id: { key: "contact", descriptorFields: "name" },
		site_id: { key: "site", descriptorFields: "name" },
		end_customer_id: { key: "endcustomer", descriptorFields: "name" },
		device_id: { key: "device", descriptorFields: "name" },
		equipment_type_id: { key: "equipmenttype", descriptorFields: "model" },
		end_customer_circuit_id: {
			key: "endcustomercircuit",
			descriptorFields: "circuit_no",
		},
	};

	controlPluralTypeMap: any = {
		// TESTING HERE!!!!
		vnos: { key: "vno", descriptorFields: "name" },
		equipment_types: { key: "equipmenttype", descriptorFields: "model" },
	};

	uploadedFile: any = null;
	uploadProgress: number = 0;

	filterValue: string = "";

	constructor(
		private fb: FormBuilder,
		public config: DynamicDialogConfig,
		private entityService: EntityService,
		private ref: DynamicDialogRef,
		private api: ApiService
	) {}

	/**
	 * @function
	 * @name getValueFromId
	 * @description
	 * Get the value from the id
	 * @param id
	 * @returns
	 */
	getValueFromId(id: number) {
		return this.selectOptions.find((x) => x.value === id).label;
	}

	ngOnInit(): void {
		this.jsonForm = this.config.data.schema;
		this.formData = this.config.data.formdata;
		this.tab = this.config.data.tab;

		this.createForm(this.jsonForm.controls);
		this.populateFormData(this.formData);

		this.jsonForm?.controls.forEach((control: any) => {
			this.getSelectOptions(control.name, control.type).subscribe(
				(options) => {
					// You can process options here if needed
				},
				(error) => {}
			);
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes["jsonForm"].firstChange) {
			this.createForm(changes["jsonForm"].currentValue.controls);
		}
	}

	ngAfterViewInit() {
		// //console.log(this.tocFileUpload.files);
	}

	createFormArray() {
		return this.fb.array([]);
	}

	filter(
		event: KeyboardEvent,
		controlName: string,
		options: DropdownFilterOptions
	) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.filterValue = filterValue;

		// Find the specific control by name
		const control = this.jsonForm?.controls.find(
			(c: any) => c.name === controlName
		);
		if (control) {
			this.getSelectOptions(
				control.name,
				control.type,
				this.filterValue
			).subscribe(
				(newOptions) => {
					// Update only the specific control's options
					control.options = newOptions;
				},
				(error) => {
					console.error("Error fetching options:", error);
				}
			);
		}
	}

	createForm(controls: JsonFormControls[]) {
		// takes controls form scheme definition and creates form controls
		//console.log('controls', controls);
		for (const control of controls) {
			const validatorsToAdd = [];
			for (const [key, value] of Object.entries(control.validators)) {
				switch (key) {
					case "min":
						validatorsToAdd.push(Validators.min(value));
						break;
					case "max":
						validatorsToAdd.push(Validators.max(value));
						break;
					case "required":
						if (value) {
							validatorsToAdd.push(Validators.required);
						}
						break;
					case "requiredTrue":
						if (value) {
							validatorsToAdd.push(Validators.requiredTrue);
						}
						break;
					case "email":
						if (value) {
							validatorsToAdd.push(Validators.email);
						}
						break;
					case "minLength":
						validatorsToAdd.push(Validators.minLength(value));
						break;
					case "maxLength":
						validatorsToAdd.push(Validators.maxLength(value));
						break;
					case "pattern":
						validatorsToAdd.push(Validators.pattern(value));
						break;
					case "nullValidator":
						if (value) {
							validatorsToAdd.push(Validators.nullValidator);
						}
						break;
					default:
						break;
				}
			}

			this.myForm.addControl(
				control.name,
				this.fb.control(control.value, validatorsToAdd)
			);
		}
	}

	populateFormData(formData: any) {
		// fixme: this throws away data if it is not in the form
		if (formData != null) {
			for (const controlName of Object.keys(formData)) {
				if (controlName in this.controlPluralTypeMap) {
					const dataSelected = this.mapDataToSelectedOptions(
						formData[controlName]
					);

					// todo: implement a retrieval service that gets the full data
					this.myForm.controls[controlName]?.setValue(dataSelected);
				} else {
					this.myForm.controls[controlName]?.setValue(formData[controlName]);
				}
			}
		}
	}

	getSelectOptions(
		controlName: string,
		controlType: string,
		filterString?: string
	): Observable<any[]> {
		if (!controlName) return of([]); // Return an empty array if controlName is not provided

		let dataMapObject = this.getControlMapping(controlName, controlType);

		if (dataMapObject?.key) {
			// Return an Observable that will emit the dropdown options
			return this.entityService
				.getEntityData(dataMapObject.key, filterString)
				.pipe(
					map((dataList: any) => {
						this.dropdownOptions[controlName] = this.mapDataToOptions(
							dataList,
							dataMapObject
						);
						// Check if there's already selected value and set it
						if (this.formData && this.formData[controlName]) {
							// Set the selected value in the form control
							this.setControlValue(controlName, this.formData[controlName]);
						}

						console.log("this.dropdownOptions", this.dropdownOptions);

						return this.dropdownOptions[controlName]; // Return the mapped options
					})
				);
		} else if (this.isPredefinedOptions(controlName)) {
			// For predefined options, just return them
			this.dropdownOptions[controlName] =
				this.getPredefinedOptions(controlName);
			return of(this.dropdownOptions[controlName]);
		}

		return of([]); // Return an empty array if no matching options are found
	}

	private setControlValue(controlName: string, value: any) {
		const control = this.myForm.get(controlName);
		if (control) {
			if (Array.isArray(value)) {
				// Ensure that only IDs are stored in the form control
				control.setValue(value.map(option => option?.id ?? option), { emitEvent: false });
			} else {
				// If it's a single value, ensure only the ID is stored
				control.setValue(value?.id ?? value, { emitEvent: false });
			}
		}
	}

	private getControlMapping(controlName: string, controlType: string) {
		if (controlType === "multiselect") {
			return this.controlPluralTypeMap[controlName] || null;
		}
		if (controlType === "select" && controlName.includes("_id")) {
			return this.controlIDTypeMap[controlName] || null;
		}
		return null;
	}

	private isPredefinedOptions(controlName: string): boolean {
		return (
			controlName.includes("port_options") || controlName.includes("toc_status")
		);
	}

	private getPredefinedOptions(controlName: string): any[] {
		const control = this.jsonForm.controls.find(
			(ctrl: any) => ctrl.name === controlName
		);
		return control?.options || [];
	}

	mapDataToOptions(dataList: any, dataMapObject: any): any {
		return dataList.map((data: any) => {
			return { label: data[dataMapObject.descriptorFields], value: data.id };
		});
	}

	mapDataToSelectedOptions(dataList: any): any {
		return dataList.map((data: any) => {
			return data.id;
		});
	}

	onUpload($event: any) {
		//console.log('start of onUpload');
		// // convert file to base64 encoding
		const file = $event.files[0];
		//console.log('file', file);

		this.uploadedFile = null;
		//console.log('end of onUpload');
		// return [file];
	}

	uploadFile(event$: any) {
		const file = event$.files[0];
		// //console.log('file', file);

		const formData = new FormData();

		formData.append("toc_file", file);

		this.api.postFileAPI("upload-file", formData).subscribe((response: any) => {
			//console.log('response', response);

			const data = response.body;

			// fixme: this is a hack to get the file reference into the form
			// todo: disable the controls
			// fixme: disabling the controls leaves out the value from the form submission
			this.myForm.controls["toc_file_reference"]?.setValue(data.uniqueId);
			// this.myForm.controls['toc_file_reference']?.disable();

			this.myForm.controls["original_filename"]?.setValue(
				data.originalFileName
			);
			// this.myForm.controls['original_filename']?.disable();
		});

		this.uploadedFile = file;
		this.tocFileUpload.files = [];

		// return [file];
	}

	onFormSubmit() {
		//console.log('Form valid: ', this.myForm.valid);
		//console.log('Form values: ', this.myForm.value);
		//console.log('Original form values: ', this.formData);

		if (this.myForm.valid) {
			// todo: possibly introduce entityrelationship calls form here, using the relationship service

			if (this.formData != null) {
				// editing an existing entity

				this.entityService.updateEntity(
					this.tab.key,
					this.formData.id,
					this.myForm.value,
					this.formData
				);
			} else {
				// adding a new entity
				this.entityService.addEntity(this.tab.key, this.myForm.value);
			}
			if (this.ref) {
				// closes the dialog/modal
				this.ref.close();
			}
		} else {
			// todo: show error message if form is invalid
			//console.log('Form invalid');
			throw new Error("Form invalid");
		}
	}
}
