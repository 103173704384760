import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';

import { Router } from '@angular/router';
import { NotificationService } from '../../_services/notification.service';
import { AuthenticationService } from 'src/app/_core/_services/authentication.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  mainitems: MenuItem[];
  profileitems: MenuItem[];
  @Input() user: any = this.authenticationService.userValue;

  showFiller = false;
  currentYear: number = 2024;
  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    public authenticationService: AuthenticationService,
    public notificationService: NotificationService,
    public router: Router,
  ) {
    this.user = this.authenticationService.userValue;

    if (this.user) {
      this.mainitems = [{ label: 'Home', routerLink: '/' }];
    } else {
      this.mainitems = [{ label: 'Login', routerLink: 'login' }];
    }

    this.profileitems = [
      {
        label: 'Logout',
        command: () => {
          this.authenticationService.sessionLogout();
        },
      },
    ];
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(1),
    );

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }
}
