import { Injectable } from '@angular/core';
import { ApiService } from '../../../_core/_services/api.service';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ParksService {
  constructor(private api: ApiService) {}

  getParks() {
    return this.api.getAPI('portal/parks').pipe(map((res: any) => res.body));
  }

  buyPackageAndRegisterUser(data: any) {
    return this.api
      .postAPI('portal/create-customer-and-package', data)
      .pipe(map((res: any) => res.body));
  }

  buyPackage(data: any) {
    return this.api
      .postAPI('portal/create-package', data)
      .pipe(map((res: any) => res.body));
  }

  listUserPackages(data: any) {
    return this.api
      .postAPI('portal/list-packages', data)
      .pipe(map((res: any) => res.body));
  }

  findUser(data: any) {
    return this.api
      .postAPI('portal/find-customer', data)
      .pipe(map((res: any) => res.body));
  }
}
