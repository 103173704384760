import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import {
	FormGroup,
	FormControl,
	Validators,
} from "@angular/forms";
import { UsersService } from "src/app/_share/_services/users.service";
import { TenantsService } from "src/app/_share/_services/tenants.service";
import { RolesService } from "src/app/_share/_services/roles.service";
import { confirmPasswordValidator } from "src/app/_share/_validators/confirm-password.validator";
import { MessageService } from "primeng/api";

@Component({
  selector: 'app-update-dialog',
  templateUrl: './update-dialog.component.html',
  styleUrls: ['./update-dialog.component.scss']
})
export class UpdateDialogComponent implements OnInit {
  submitted: boolean = false;
  tenants: any[] = [];
  portal_tenants: any[] = [];
  loading: boolean = false;
  data: any;

  userForm: FormGroup = new FormGroup(
      {
        username: new FormControl("", [Validators.required]),
				first_name: new FormControl("", [Validators.required]),
				last_name: new FormControl("", [Validators.required]),
				email: new FormControl("", [Validators.required, Validators.email]),
				enabled: new FormControl(true),
				tenant_id: new FormControl("", [Validators.required]),
				portal_tenant_id: new FormControl(""),
				role_id: new FormControl("", [Validators.required]),
				password: new FormControl(""),
				confirm_password: new FormControl(""),
				portal_tenants: new FormControl([]),
				tenants: new FormControl("", [Validators.required]),
      },
      { validators: confirmPasswordValidator }
    );

  constructor(
    public ref: DynamicDialogRef,
		public config: DynamicDialogConfig,
		private usersService: UsersService,
		private tenantsService: TenantsService,
		private rolesService: RolesService,
		private messageService: MessageService
  ) {
    
   }

  ngOnInit(): void {
    this.data = this.config.data.user_data;
    console.log(this.data);
    
    this.userForm.patchValue({
      username: this.data.username,
      first_name: this.data.first_name,
      last_name: this.data.last_name,
      email: this.data.email,
      enabled: this.data.enabled,
      tenant_id: this.data.tenant_id,
      portal_tenant_id: this.data.portal_tenant_id,
      role_id: this.data.role_id,
    
      // Extract tenant IDs from array of objects
      tenants: this.data.tenants.map((t: any) => t.id),  
    
      // Extract portal tenant IDs
      portal_tenants: this.data.portal_tenants.map((pt: any) => pt.id),
    });
    

    this.tenantsService.getPortalTenants().subscribe((portalTenants) => {
			this.portal_tenants = portalTenants;
		});

		this.tenantsService.getAll().subscribe((tenants) => {
			this.tenants = tenants;
		});

    this.userForm.get("role_id")?.setValue(1);
  }

  onCancel() {
    this.ref.close();
  }

  onSubmit() {
    this.loading = true;
    this.submitted = true;

    if (this.userForm.invalid) {
      this.loading = false;

      this.userForm.markAllAsTouched();

      Object.keys(this.userForm.controls).forEach((key) => {
        this.userForm.controls[key].markAsDirty();
      });

      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Please fill in all required fields",
        key: "error-user",
      });

      return;
    }
    
    if(this.userForm.get("password")?.value === "") {
      this.userForm.removeControl("password");
      this.userForm.removeControl("confirm_password");
    }

    console.log(this.userForm.value);
    
    
    const data = this.userForm.value;
    data.id = this.data.id;
    this.usersService.updateSystemUser(data, data.id).subscribe(
      (response) => {
        this.loading = false;
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "User updated successfully",
          key: "create-user",
        });
        this.ref.close(response);
      },
      (error) => {
        this.loading = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "An error occurred while updating user",
          key: "error-user",
        });
      }
    );

  }
}
