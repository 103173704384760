<p-toast key="error-user"></p-toast>
<div>
	<form
		[formGroup]="userForm"
		(ngSubmit)="onSubmit()"
	>
		<div class="grid">
			<div class="flex flex-column col-6">
				<div>
					<span> Username </span>
				</div>
				<div>
					<input
						pInputText
						formControlName="username"
						placeholder="Username"
						class="w-full"
						(blur)="userForm.controls['username'].markAsDirty()" 
					/>
					<div
						*ngIf="submitted && userForm.get('username')?.errors"
						class="invalid-feedback mt-2"
					>
						<small
							*ngIf="
								userForm.controls &&
								userForm.controls['username'] &&
								userForm.controls['username'].errors &&
								userForm.controls['username'].errors['required'] ||
								userForm.controls['username'].dirty
							" 
							class="p-error text-base"
							><strong >Username</strong> is required</small
						>
					</div>
				</div>
			</div>
			<div class="flex flex-column col-6">
				<div>
					<span> First Name </span>
				</div>
				<div>
					<input
						pInputText
						formControlName="first_name"
						placeholder="First Name"
						class="w-full"
						(blur)="userForm.controls['first_name'].markAsDirty()"
					/>
					<div
						*ngIf="submitted && userForm.get('first_name')?.errors"
						class="invalid-feedback mt-2"
					>
						<small
							*ngIf="
								userForm.controls &&
								userForm.controls['first_name'] &&
								userForm.controls['first_name'].errors &&
								userForm.controls['first_name'].errors['required']
							"
							class="p-error text-base"
							><strong >First Name</strong> is required</small
						>
					</div>
				</div>
			</div>

			<div class="flex flex-column col-6">
				<div>
					<span> Last Name </span>
				</div>
				<div>
					<input
						pInputText
						formControlName="last_name"
						placeholder="Last Name"
						class="w-full"
						(blur)="userForm.controls['last_name'].markAsDirty()"
					/>
					<div
						*ngIf="submitted && userForm.get('last_name')?.errors"
						class="invalid-feedback mt-2"
					>
						<small
							*ngIf="
								userForm.controls &&
								userForm.controls['last_name'] &&
								userForm.controls['last_name'].errors &&
								userForm.controls['last_name'].errors['required']
							"
							class="p-error text-base"
							><strong>Last Name</strong> is required</small
						>
					</div>
				</div>
			</div>
			<div class="flex flex-column col-6">
				<div>
					<span> Email </span>
				</div>
				<div>
					<input
						pInputText
						formControlName="email"
						placeholder="Email"
						class="w-full"
						(blur)="userForm.controls['email'].markAsDirty()"
					/>
					<div
						*ngIf="submitted && userForm.get('email')?.errors"
						class="invalid-feedback mt-2"
					>
						<small
							*ngIf="
								userForm.controls &&
								userForm.controls['email'] &&
								userForm.controls['email'].errors &&
								userForm.controls['email'].errors['required']
							"
							class="p-error text-base"
							><strong>Email</strong> is required</small
						>
						<small
							*ngIf="userForm.controls['email']?.errors?.['email']"
							severity="error"
							text="Please enter a valid email"
							styleClass="w-full"
							><strong>Please enter a valid email</strong></small
						>
					</div>
				</div>
			</div>
			<div class="flex flex-column col-6">
				<label
					for="password"
					class="font-medium text-900"
					>Password</label
				>
				<input
					id="password"
					type="password"
					pInputText
					formControlName="password"
					
				/>
			</div>
			<div class="flex flex-column col-6">
				<label for="">Confirm Password</label>
				<input
					id="confirm_password"
					type="password"
					pInputText
					formControlName="confirm_password"
					
				/>
			</div>
			<div class="flex flex-column col-12">
				<small
					*ngIf="userForm.errors?.['PasswordNoMatch'] && userForm.get('confirm_password')?.touched && userForm.get('password')?.touched"
					class="p-error text-base"
					><strong>The passwords do not match</strong></small
				>
				<small
					*ngIf="!userForm.errors?.['PasswordNoMatch'] && 
					userForm.get('confirm_password')?.touched && 
					userForm.get('password')?.touched && 
					userForm.get('confirm_password')?.dirty && 
					userForm.get('password')?.dirty &&
					userForm.get('confirm_password')?.value &&
					userForm.get('password')?.value"
					class="text-green-500 text-base"
					><strong>The passwords match!</strong>
				</small>
			</div>
			
			<div class="col-12">
				<div>
					<span> Tenants </span>
				</div>
				<div>
					<p-multiSelect
						[options]="tenants"
						[formControl]="$any(userForm.get('tenants'))"
						optionLabel="name"
						optionValue="id"
						display="chip"
						placeholder="Select Tenants"
						styleClass="w-full"
						appendTo="body"
						(onBlur)="userForm.controls['tenants'].markAsDirty()"
					></p-multiSelect>
					<div
						*ngIf="submitted && userForm.get('tenants')?.errors"
						class="invalid-feedback mt-2"
					>
						<small
							*ngIf="
								userForm.controls &&
								userForm.controls['tenants'] &&
								userForm.controls['tenants'].errors &&
								userForm.controls['tenants'].errors['required']
							"
							class="p-error text-base"
							><strong>Please select tenants</strong></small
						>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div>
					<span> Portal Tenants </span>
				</div>
				<div>
					<p-multiSelect
						[options]="portal_tenants"
						[formControl]="$any(userForm.get('portal_tenants'))"
						optionLabel="name"
						optionValue="id"
						display="chip"
						placeholder="Select Portal Tenants"
						styleClass="w-full"
						appendTo="body"
					></p-multiSelect>

				</div>
			</div>
		</div>
		<div class="flex justify-end">
			<button
				pButton
				label="Create"
				type="submit"
				class="w-10rem mt-4 p-button-outlined mr-2"
				[loading]="loading"
			></button>
			<button
				pButton
				label="Cancel"
				type="button"
				class="w-10rem mt-4 p-button-outlined p-button-danger"
				(click)="onCancel()"
			></button>
		</div>
	</form>
</div>
