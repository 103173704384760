<div>
	<form
		[formGroup]="updateRegistrationForm"
		(ngSubmit)="onSubmit()"
	>
		<div class="grid formgrid p-fluid">
			<div class="field col-12 flex flex-column">
				<label class="text-gray-800">Option Type: </label>
				<div class="">
					<p-dropdown
						styleClass="w-full"
						[options]="optionTypes"
						optionLabel="label"
						optionValue="value"
						formControlName="option_type"
						appendTo="body"
					>
					</p-dropdown>
					<p-message
						*ngIf="
							updateRegistrationForm.controls['option_type'].invalid &&
							updateRegistrationForm.controls['option_type'].touched
						"
						severity="error"
						text="Option Type is required"
					></p-message>
				</div>
			</div>
			<div class="field col-12 md:col-6 flex flex-column">
				<label
					for="name"
					class="text-gray-800"
					>Value:
				</label>
				<div class="">
					<input
						formControlName="value"
						pInputText
						type="text"
						class=""
					/>
					<p-message
						*ngIf="
							updateRegistrationForm.controls['value'].invalid &&
							updateRegistrationForm.controls['value'].touched
						"
						severity="error"
						text="Value is required"
					></p-message>
				</div>
			</div>
			<div class="field col-12 md:col-6 flex flex-column">
				<label
					for="description"
					class="text-gray-800"
					>Label:
				</label>

				<div class="">
					<input
						formControlName="label"
						pInputText
						type="text"
						class=""
					/>
					<p-message
						*ngIf="
							updateRegistrationForm.controls['label'].invalid &&
							updateRegistrationForm.controls['label'].touched
						"
						severity="error"
						text="Label is required"
					></p-message>
				</div>
			</div>
		</div>
		<div class="w-full mt-4">
			<button
				pButton
				label="Update Option"
				class="p-button-primary w-full"
				type="submit"
			></button>
		</div>
	</form>
</div>
