<!-- billing history -->
<p-contextMenu
	#cm
	[model]="items"
></p-contextMenu>
<p-toast></p-toast>

<div class="grid">
	<div [ngClass]="change_logs.length > 0 ? 'col-8' : 'col-12'">
		<div class="surface-card shadow-2 p-4">
			<div class="flex align-items-center justify-content-between mb-4">
				<div class="text-900 font-medium text-xl">Services</div>
				<!-- <div><button pButton icon="pi pi-refresh"></button></div> -->
			</div>
			<div class="col-12 md:col-12">
				<div class="">
				  <div class="surface-card p-2 shadow-2">
					<span class="p-buttonset">
					  <button
						pButton
						class="p-button-info"
						icon="pi pi-plus"
						iconPos="left"
						label="Create"
						(click)="openCreateServiceDialog()"
					  ></button>
					</span>
				  </div>
				</div>
			  </div>
			<!-- <p-table
				#servicesTable
				[value]="(services$ | async) || []"
				sortMode="single"
				dataKey="id"
				[scrollable]="true"
				scrollHeight="400px"
				groupRowsBy="representative.name"
				selectionMode="single"
				[contextMenu]="cm"
				(onContextMenuSelect)="openMenu()"
				[(contextMenuSelection)]="selectedRow"
				[(selection)]="selectedRow"
				(onRowSelect)="onRowSelect($event)"
				[tableStyle]="{ 'min-width': '60rem' }"
				[globalFilterFields]="[
					'service_status',
					'date_activated',
					'circuitNumberSearch'
				]"
			>
				<ng-template pTemplate="caption">
					<div class="flex">
						<button
							pButton
							label="Clear"
							class="p-button-outlined"
							icon="pi pi-filter-slash"
						></button>
						<button
							pButton
							label="Create"
							icon="pi pi-plus"
							class="p-button-outlined p-button-success ml-2"
							(click)="openCreateServiceDialog()"
						></button>
						<span class="p-input-icon-left ml-auto">
							<i class="pi pi-search"></i>
							<input
								#inputField
								pInputText
								type="text"
								(input)="
									servicesTable.filterGlobal(inputField.value, 'contains')
								"
								placeholder="Search keyword"
							/>
						</span>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th *ngFor="let col of columns">{{ col.name }}</th>
					</tr>
				</ng-template>
				<ng-template
					pTemplate="groupheader"
					let-item
				>
					<tr pRowGroupHeader>
						<td [attr.colspan]="getNumberOfColumns()">
							<span class="font-bold ml-2">{{ item.product_type }}</span>
						</td>
					</tr>
				</ng-template>
				<ng-template
					pTemplate="body"
					let-item
					let-rowIndex="rowIndex"
					let-expanded="expanded"
				>
					<tr
						style="cursor: pointer"
						[pContextMenuRow]="item"
					>
						<td
							*ngFor="let col of columns"
							class="white-space-nowrap"
							(click)="onRowSelect(item)"
						>
							<ng-container *ngIf="col.field === 'service_status'">
								<p-tag
									[value]="resolveFieldData(item, col.field) | uppercase"
									[severity]="
										getServiceSeverity(resolveFieldData(item, col.field))
									"
								></p-tag>
							</ng-container>

							<ng-container *ngIf="col.field !== 'service_status'">
								{{ resolveFieldData(item, col.field) }}
							</ng-container>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td
							[attr.colspan]="this.getNumberOfColumns()"
							class="align-content-center"
						>
							<p-progressBar
								styleClass="align-self-center"
								*ngIf="loading"
								mode="indeterminate"
							>
							</p-progressBar>
							<div *ngIf="!loading">
								<p>
									There are no records to show. You might be disconnected or
									offline.
								</p>
							</div>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="#errorMessage">
					<div *ngIf="isError">
						<p>There was an error fetching the data</p>
					</div>
				</ng-template>
			</p-table> -->
			<share-table
				[data]="services$"
				[columns]="columns"
				[(metaData)]="metadata"
				[menuItems]="menuItems"
				(metaDataChange)="getNetworkServices()"
				(selectedRowObservable$)="onRowSelect($event)"
				(selectedRowCMObservable$)="showContextAction($event)"

			></share-table>
		</div>
	</div>

	<div
		*ngIf="change_logs.length > 0"
		class="col-4"
	>
		<div class="flex-1 border-round shadow-2 p-3">
			<div class="flex justify-content-end">
				<div>
					<button
						pButton
						icon="pi pi-times"
						class="p-button-outlined border-none p-button-danger mb-2"
						(click)="change_logs = []"
					></button>
				</div>
			</div>
			<p-timeline
				[value]="change_logs"
				align="alternate"
			>
				<ng-template
					pTemplate="marker"
					let-log
				>
					<span class="">
						<i [ngClass]="'pi pi-hashtag'"></i>
					</span>
				</ng-template>
				<ng-template
					pTemplate="content"
					let-log
				>
					<div class="flex flex-row">
						<div class="flex-auto p-fluid border-1 p-2 border-round border-400">
							<div class="text-900 font-semibold text-blue-600 text-lg">
								{{ log.change_type | uppercase }}
							</div>
							<div class="text-700">{{ log.reason_comments }}</div>
							<div class="text-500 text-xs">
								Date of Change Request:
								{{ log.date_change_request | date : "yyyy-MM-dd HH:mm:ss" }}
							</div>
							<div class="text-500 text-xs">
								Date Effective:
								{{ log.date_effective | date : "yyyy-MM-dd HH:mm:ss" }}
							</div>
						</div>
					</div>
				</ng-template>
			</p-timeline>
		</div>
	</div>
</div>
