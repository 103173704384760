import { Component, OnInit } from "@angular/core";
import { PortalTransactionsService } from "src/app/features/portal/_services/portal-transactions.service";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { DatePipe } from "@angular/common";
import { MessageService } from "primeng/api";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";

@Component({
	selector: "app-transaction-override-dialog",
	templateUrl: "./transaction-override-dialog.component.html",
	styleUrls: ["./transaction-override-dialog.component.scss"],
	providers: [DatePipe],
})
export class TransactionOverrideDialogComponent implements OnInit {
	data: any;
	loading: boolean = false;
	submitted: boolean = false;
	overrideReasons = [
		{
			label: "Payment made via bank deposit",
			value: "Payment made via bank deposit",
		},
	];

	overrideForm: FormGroup = new FormGroup({
		transaction_date: new FormControl("", Validators.required),
		transaction_override_reason: new FormControl("", Validators.required),
	});

	constructor(
		private portalTransactionsService: PortalTransactionsService,
		private config: DynamicDialogConfig,
		private datePipe: DatePipe,
		private ref: DynamicDialogRef,
		private messageService: MessageService,
		private fb: FormBuilder
	) {}

	ngOnInit(): void {
		this.data = this.config.data.transaction;
		this.overrideForm = new FormGroup(
			{
				transaction_date: new FormControl("", [Validators.required]),
				transaction_override_reason: new FormControl("", [Validators.required]),
			}
		);
	}

	overrideTransaction() {
		this.submitted = true;
		this.loading = true;
		if (this.overrideForm.invalid) {
			this.loading = false;
			this.overrideForm.markAllAsTouched();

			this.messageService.add({
				severity: "error",
				summary: "Error",
				detail: "Please fill in all required fields",
				key: "override-error",
			});
			console.log("invalid form", this.overrideForm.value);
			return;
		}
		
		
		// this.loading = true;

		this.overrideForm.value.transaction_date = this.datePipe.transform(
			this.overrideForm.value.transaction_date,
			"yyyy-MM-dd"
		);

		console.log("overrideForm", this.overrideForm.value);

		this.portalTransactionsService
			.overrideTransactionPaymentMethod(this.overrideForm.value, this.data.id)
			.subscribe(
				(res: any) => {
					console.log("res", res);

					this.messageService.add({
						severity: "success",
						summary: "Success",
						detail: "Transaction has been successfully overridden",
						key: "overrideTransaction",
					});
					this.ref.close(res);
					this.submitted = false;
				},
				(error) => {
					this.loading = false;
					this.messageService.add({
						severity: "error",
						summary: "Error",
						detail: "An error occurred while overriding transaction",
						key: "overrideTransaction",
					});
				}
			);
	}
}
