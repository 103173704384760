<p-toast key="create-user"></p-toast>
<p-contextMenu
	#menu
	[model]="menuItems"
></p-contextMenu>
<p-table
	#subscriptionsTable
	[value]="(tenantUsers$ | async) || []"
	scrollHeight="40vh"
	[scrollable]="true"
	[globalFilterFields]="['name', 'description']"
	(onContextMenu)="onContextMenu($event)"
	[(contextMenuSelection)]="selectedRow"
	[contextMenu]="menu"
	(onContextMenuSelect)="onContextMenuSelect($event)"
>
	<ng-template pTemplate="caption">
		<div class="flex">
			<button
				pButton
				label="Clear"
				class="p-button-outlined"
				icon="pi pi-filter-slash"
			></button>
			<button
				pButton
				label="Add User"
				class="p-button-outlined p-button-success ml-4"
				icon="pi pi-plus"
				(click)="openCreateDialog()"
			></button>
			<span class="p-input-icon-left ml-auto">
				<i class="pi pi-search"></i>
				<input
					#inputField
					pInputText
					type="text"
					(input)="
						subscriptionsTable.filterGlobal(inputField.value, 'contains')
					"
					placeholder="Search keyword"
				/>
			</span>
		</div>
	</ng-template>
	<ng-template pTemplate="header">
		<tr>
			<th
				*ngFor="let col of columns"
				[pSortableColumn]="col.field"
			>
				<div class="flex align-items-center">
					{{ col.header }}
				</div>
			</th>
		</tr>
	</ng-template>
	<ng-template
		pTemplate="body"
		let-tenant
	>
		<tr
			(click)="storeRowDetails(tenant); getUserDisabledStatus(tenant)"
			[pSelectableRow]="tenant"
			[pContextMenuRow]="tenant"
		>
			<td *ngFor="let col of columns">
				<ng-container *ngIf="col.field === 'tenant'">
					<ng-container *ngFor="let tenant of tenant.tenants ?? []">
						<button
							pButton
							class="p-button-rounded p-button-small m-2"
							[label]="tenant.name"
						></button>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="col.field === 'enabled'; else other">
					<p-tag
						[severity]="tenant[col.field] ? 'success' : 'danger'"
						[value]="tenant[col.field] ? 'ENABLED' : 'DISABLED'"
					>
					</p-tag>
				</ng-container>
				<!-- if contains role -->

				<ng-template #other>
					<span *ngIf="col.field === 'role'; else notRole">
						{{ tenant[col.field].name }}
					</span>
					<ng-template #notRole>
						{{ tenant[col.field] }}
					</ng-template>
				</ng-template>
			</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="emptymessage">
		<tr>
			<td
				[attr.colspan]="this.getNumberofColumns()"
				class="align-content-center"
			>
				<p>
					There are no records to show. You might be disconnected or offline.
				</p>
			</td>
		</tr>
	</ng-template>
</p-table>
