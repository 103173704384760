import { Component, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { map, Observable } from 'rxjs';
import { UserService } from 'src/app/_share/_services/user.service';

@Component({
  selector: 'app-portal-settings',
  templateUrl: './portal-settings.component.html',
  styleUrls: ['./portal-settings.component.scss'],
})
export class PortalSettingsComponent implements OnInit {
  menuItems: any[] = [];
  currentUser$: Observable<any>;
  menuItems$: Observable<any[]>;

  constructor(
    public router: Router,
    public userService: UserService,
  ) {
    this.currentUser$ = this.userService.currentUser$;
    
    this.menuItems$ = this.currentUser$.pipe(
      map((currentUser) => {
        return this.convertTenantsToMenuItems(currentUser?.portal_tenants || []);
      })
    );
  }

  ngOnInit(): void {}

  
  convertTenantsToMenuItems(tenants: any[]) {
    return tenants.map((tenant) => {
      return {
        label: tenant.name,
        icon: 'pi pi-wifi',
        command: () => {
          this.switchPortalTenant(tenant.id);
        },
      };
    });
  }
  
  switchPortalTenant(tenant_id: number) {
    this.userService.switchPortalTenant(tenant_id).subscribe((res) => {
      console.log('switched tenant', res);
      window.location.reload();
    });
  }

  getCurrentPortalTenantName(
    portal_tenant_id: number,
    portal_tenants: any[],
  ): string {
    const tenant = portal_tenants.find((t) => t.id === portal_tenant_id);
    return tenant ? tenant.name : '';
  }

  getPortalUrl(portal_tenant_id: number, portal_tenants: any[]): string {
    // console.log(portal_tenant_id, portal_tenants);
    const tenant = portal_tenants.find((t) => t.id === portal_tenant_id);
    return tenant ? tenant.portal_url : '';
  }
}
