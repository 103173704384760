import { Component, Input, OnInit } from '@angular/core';
import { ERoles } from 'src/app/_share/_models/entities/roles';

import { AuthenticationService } from 'src/app/_core/_services/authentication.service';
import { UserService } from '../../_services/user.service';
import { MenuItem } from 'primeng/api';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'app-sub-nav',
  templateUrl: './sub-nav.component.html',
  styleUrls: ['./sub-nav.component.scss'],
})
export class SubNavComponent implements OnInit {
  @Input() menuItems: any[] = [];
  @Input() rootPath: string = '';
  menuItems$: Observable<any[]>;
  tenantOptions: MenuItem[] = [];

  roles: typeof ERoles = ERoles;
  constructor(
    public authenticationService: AuthenticationService,
    public userService: UserService,
  ) {
    this.menuItems$ = this.userService.currentUser$.pipe(
      map((currentUser: any) => {
        return this.convertTenantsToMenuItems(currentUser?.tenants || []);
      })
    );
  }

  ngOnInit(): void {
    this.userService.getCurrentLoggedInUser();

    this.menuItems = [
      {
        label: 'Home',
        icon: 'pi pi-fw pi-home',
        routerLink: '/',
        rolesWithAccess: [
          this.roles.superadmin,
          this.roles.operationsadmin,
          this.roles.operationsmanager,
          this.roles.salesmanager,
          this.roles.salesagent,
          this.roles.ispadmin,
          this.roles.vnoadmin,
        ],
      },
      {
        label: 'Dashboard',
        icon: 'pi pi-fw pi-home',
        routerLink: '/dashboard',
        rolesWithAccess: [
          this.roles.superadmin,
          this.roles.operationsadmin,
          this.roles.operationsmanager,
        ],
      },
      {
        label: 'Services',
        icon: 'pi pi-fw pi-sitemap',
        routerLink: '/services',
        rolesWithAccess: [
          this.roles.superadmin,
          this.roles.operationsadmin,
          this.roles.operationsmanager,
        ],
      },

      {
        label: 'Operations',
        icon: 'pi pi-fw pi-chart-pie',
        routerLink: '/isp-operations',
        rolesWithAccess: [
          this.roles.superadmin,
          this.roles.operationsadmin,
          this.roles.operationsmanager,
          this.roles.ispadmin,
          this.roles.vnoadmin,
        ],
      },
      {
        label: 'Operations Management',
        icon: 'pi pi-fw pi-chart-pie',
        routerLink: '/operations',
        rolesWithAccess: [this.roles.superadmin, this.roles.operationsmanager],
      },
      {
        label: 'Support',
        icon: 'pi pi-fw pi-users',
        routerLink: '/support',
        rolesWithAccess: [this.roles.superadmin],
      },
      // {
      //   label: 'Sales',
      //   icon: 'pi pi-fw pi-users',
      //   routerLink: '/crm',
      //   rolesWithAccess: [
      //     this.roles.salesmanager,
      //     this.roles.superadmin,
      //     this.roles.salesagent,
      //   ],
      // },
      // {
      //   label: 'Sales Management',
      //   icon: 'pi pi-fw pi-users',
      //   routerLink: '/crm-management',
      //   rolesWithAccess: [this.roles.salesmanager, this.roles.superadmin],
      // },
      {
        label: 'Customer Portal Management',
        icon: 'pi pi-fw pi-home',
        routerLink: '/portal-management',
        rolesWithAccess: [this.roles.superadmin, this.roles.portaladmin],
      },
      {
        label: 'Tenant Management',
        icon: 'pi pi-fw pi-home',
        routerLink: '/tenant-management',
        rolesWithAccess: [this.roles.superadmin],
      },
      {
        label: 'Business Sales',
        icon: 'pi pi-fw pi-users',
        routerLink: '/business',
        rolesWithAccess: [
          this.roles.businesssalesagent,
          this.roles.salesmanager,
        ],
      },
    ];

    // if ((this.userService.currentUser.tenants || []).length > 1) {
    //   this.convertTenantsToMenuItems(
    //     this.userService.currentUser.tenants || []
    //   );
    // }
  }
  convertTenantsToMenuItems(tenants: any[]) {
    return tenants.map((tenant) => {
      return {
        label: tenant.name,
        icon: 'pi pi-wifi',
        command: () => {
          this.switchTenant(tenant.id);
        },
      };
    });
  }

  getCurrentTenantName(tenant_id: number, tenants: any[]): string {
    const tenant = tenants.find((t) => t.id === tenant_id);
    return tenant ? tenant.name : '';
  }

  switchTenant(tenant_id: number) {
    this.userService.switchTenant(tenant_id).subscribe((res) => {
      console.log('switched tenant', res);
      window.location.reload();
    });
  }
}
