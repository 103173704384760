<!-- 


 -->
<!-- <p-toast key="overrideTransaction"></p-toast>
<p-table
  styleClass="p-datatable-striped"
  #subscriptionsTable
  [value]="(subscriptions$ | async) || []"
  scrollHeight="40vh"
  [scrollable]="true"
  [globalFilterFields]="[
    'subscription_account_reference',
    'date_activated',
    'date_billing_next',
    'billing_cycle_type',
    'subscription_reference',
    'date_initialised',
    'date_activated',
  ]"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} subscriptions"
  [showCurrentPageReport]="true"
>
  <ng-template pTemplate="caption">
    <div class="flex">
      <button
        pButton
        label="Clear"
        class="p-button-outlined"
        icon="pi pi-filter-slash"
        (click)="clear(subscriptionsTable)"
      ></button>
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input
          #inputField
          pInputText
          type="text"
          (input)="
            subscriptionsTable.filterGlobal(inputField.value, 'contains')
          "
          placeholder="Search keyword"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        <div class="flex align-items-center">
          {{ col.header }}
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-subscription>
    <tr
      style="cursor: pointer"
      [pSelectableRow]="subscription"
      [pContextMenuRow]="subscription"
      (click)="showSubscriptionDetailsDialog(subscription)"
    >
      <td *ngFor="let col of columns">
        <ng-container [ngSwitch]="col.field">
          <div class="flex align-items-center" *ngSwitchCase="'date_activated'">
            {{ subscription[col.field] | date: "yyyy-MM-dd" }}
          </div>
          <div
            class="flex align-items-center"
            *ngSwitchCase="'date_billing_next'"
          >
            {{ subscription[col.field] | date: "yyyy-MM-dd" }}
          </div>
          <div
            class="flex align-items-center"
            *ngSwitchCase="'date_initialised'"
          >
            {{ subscription[col.field] | date: "yyyy-MM-dd" }}
          </div>

          <div
            class="flex align-items-center"
            *ngSwitchCase="'subscription_status'"
          >
            <p-tag
              [value]="subscription[col.field] | anyAsStatusString | uppercase"
              [severity]="getSubscriptionSeverity(subscription[col.field])"
            ></p-tag>
          </div>
          <div class="flex align-items-center" *ngSwitchCase="'account_status'">
            <p-tag
              [value]="subscription[col.field] | anyAsStatusString | uppercase"
              [severity]="getAccountSeverity(subscription[col.field])"
            ></p-tag>
          </div>

          <div class="flex align-items-center" *ngSwitchDefault>
            {{ subscription[col.field] }}
          </div>
        </ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td
        [attr.colspan]="this.getNumberofColumns()"
        class="align-content-center"
      >
        <p-progressBar
          styleClass="align-self-center"
          *ngIf="isLoading"
          mode="indeterminate"
        >
        </p-progressBar>
        <div *ngIf="!isLoading">
          <p>
            There are no records to show. You might be disconnected or offline.
          </p>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="#errorMessage">
    <div *ngIf="isError">
      <p>There was an error fetching the data</p>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <tr>
      <td
        [attr.colspan]="this.getNumberofColumns()"
        class="align-content-center justify-content-center"
      >
        <p>
          <span class="text-600 text-sm">Total Subscriptions: </span>
          {{ subscriptionsTable._totalRecords }}
        </p>
      </td>
    </tr>
  </ng-template>
</p-table> -->
<share-table
  [data]="subscriptions$"
  [columns]="columns"
  [menuItems]="menuItems"
  [(metaData)]="metadata"
  (metaDataChange)="retrievePortalSubscriptions()"
  (selectedRowObservable$)="showSubscriptionDetailsDialog($event)"
  (selectedRowCMObservable$)="showContextAction($event)"
></share-table>