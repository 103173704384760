import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  StripeCardElementChangeEvent,
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  BehaviorSubject,
  Observable,
  catchError,
  concatMap,
  finalize,
  map,
  merge,
  of,
  switchMap,
  tap,
} from 'rxjs';

import { ParksService } from 'src/app/features/portal/_services/parks.service';
import { PortalRegisterService } from 'src/app/features/portal/_services/portal-register.service';
import { confirmPasswordValidator } from 'src/app/_share/_validators/confirm-password.validator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/_share/_services/notification.service';
import { PortalAuthenticationService } from '../../_services/portal-authentication.service';

@Component({
  selector: 'app-portal-register-uk',
  templateUrl: './portal-register-uk.component.html',
  styleUrls: ['./portal-register-uk.component.scss'],
})
export class PortalRegisterUkComponent implements OnInit {
  selectedPackage: any;
  stateOptions = [
    { label: 'New User', value: 'new' },
    { label: 'Existing User', value: 'existing' },
  ];
  paymentLoading: boolean = false;
  userType: any;
  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private stripeService: StripeService,
    private ps: ParksService,
    private pas: PortalAuthenticationService,
    private notificationService: NotificationService,
  ) {}

  @ViewChild(StripeCardComponent) card!: StripeCardComponent;
  @ViewChild('planDropdown') planDropdown: ElementRef = new ElementRef(null);

  ukPhoneNumberRegex: RegExp = /^(?:\+44|0)(?:\d\s?){9,10}\d$/;

  registrationSuccess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  portalUserRegisterForm = this.fb.group(
    {
      // title: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      mobile_number: [
        '',
        [Validators.required, Validators.pattern(this.ukPhoneNumberRegex)],
      ],
      // country: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirm_password: ['', [Validators.required]],
      // plan: ['', Validators.required],
      permission_data: [true, Validators.required],
    },
    { validator: confirmPasswordValidator },
  );

  packages: any[] = [
    // 10 devices - effective from 1st July 2024
    {
      name: '1 Day - 10 Devices',
      value: '1day-10device',
      price: 6,
      enabled: true,
    },
    {
      name: '3 Days - 10 Devices',
      value: '3day-10device',
      price: 14,
      enabled: true,
    },
    {
      name: '7 Days - 10 Devices',
      value: '7day-10device',
      price: 23,
      enabled: true,
    },
    {
      name: '30 Days - 10 Devices',
      value: '30day-10device',
      price: 45,
    },
    {
      name: 'Seasonal - 10 Devices',
      value: 'Season-10device',
      price: 384,
      enabled: true,
    },
  ];

  errorMessage: string = '';
  public cardOptions: StripeCardElementOptions = {
    disableLink: true,
    hidePostalCode: true,
    style: {
      invalid: {
        color: '#FF0000',
      },
    },

    classes: {
      base: 'p-inputtext',
      complete: 'is-valid',
      empty: 'is-invalid',
      focus: 'is-valid',
      invalid: 'is-invalid',
      webkitAutofill: 'is-valid',
    },
  };

  public elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  countries: any;
  selectedCountry: any;
  selectedCountryName: any;

  stripe = this.stripeService.stripe;

  paymentAmount: number = 0.0;

  ngOnInit(): void {
    this.errorMessage = '';

    if (this.config.data.dialogDetails.park.name == 'Quarry Walk Park') {
      this.packages = [
        {
          name: '1 Day - 5 Devices',
          value: '1day-5device',
          price: 10,
          enabled: true,
        },
        {
          name: '3 Days - 5 Devices',
          value: '3day-5device',
          price: 15,
          enabled: true,
        },
        {
          name: '7 Days - 5 Devices',
          value: '7day-5device',
          price: 25,
          enabled: true,
        },
      ];
    }

    this.http.get('/assets/countries.json').subscribe((countries: any) => {
      this.countries = countries;
      console.log('countries', countries);
    });
  }

  onRegisterSubmit() {
    console.log('form values', this.portalUserRegisterForm.value);
    console.log('form valid', this.portalUserRegisterForm.valid);

    console.log('form errors', this.portalUserRegisterForm.errors);

    if (this.portalUserRegisterForm.valid) {
      this.registrationSuccess$.next(true);
      this.planDropdown.nativeElement.focus();
      // todo: could register user here
    } else {
      this.errorMessage = 'Please fill in all required fields correctly';
    }
  }

  paymentForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    amount: [0, Validators.required],
    plan: ['', Validators.required],
    account_code: [''],
  });
  /*
@deprecated
*/
  // payOld(): void {
  //   this.paymentLoading = true;
  //   this.paymentForm.patchValue({
  //     name:
  //       this.portalUserRegisterForm.get('first_name')?.value +
  //       ' ' +
  //       this.portalUserRegisterForm.get('last_name')?.value,
  //     email: this.portalUserRegisterForm.get('email')?.value,
  //     amount: this.paymentAmount,
  //   });

  //   const paymentFormForSubmit = {
  //     amount: this.paymentAmount,
  //     currency: 'gbp',
  //     email: this.portalUserRegisterForm.get('email')?.value,
  //     first_name: String(
  //       this.portalUserRegisterForm.get('first_name')?.value || '',
  //     ).trim(),
  //     last_name: String(
  //       this.portalUserRegisterForm.get('last_name')?.value || '',
  //     ).trim(),
  //     plan: this.selectedPackage.value,
  //     mobile_number: String(
  //       this.portalUserRegisterForm.get('mobile_number')?.value || '',
  //     ),
  //     account_code: '',
  //   };

  //   console.log('validity', this.paymentForm.valid);
  //   console.log('card', this.card);
  //   if (this.paymentForm.valid) {
  //     this.createPaymentIntent(paymentFormForSubmit)
  //       .pipe(
  //         switchMap((pi) =>
  //           this.stripeService.confirmCardPayment(pi.client_secret, {
  //             payment_method: {
  //               card: this.card.element,
  //               billing_details: {
  //                 name:
  //                   paymentFormForSubmit.first_name +
  //                   ' ' +
  //                   paymentFormForSubmit.last_name,
  //                 email: paymentFormForSubmit.email,
  //                 phone:
  //                   this.portalUserRegisterForm.get('mobile_number')?.value,
  //               },
  //             },
  //           }),
  //         ),
  //       )
  //       .subscribe((result: any) => {
  //         if (result.error) {
  //           // Show error to your customer (e.g., insufficient funds)
  //           console.log(result.error.message);
  //           window.alert('Payment was not successful');

  //           // todo: show customer failed dialog
  //         } else {
  //           // The payment has been processed!
  //           console.log('result', result);
  //           if (result.paymentIntent.status === 'succeeded') {
  //             // Show a success message to your customer
  //             console.log('success');

  //             // this.ref.close(this.portalUserRegisterForm.value);
  //           }
  //           console.log('package details', this.selectedPackage);
  //           // todo: show customer success dialog
  //           this.ps
  //             .buyPackageAndRegisterUser({
  //               ...this.portalUserRegisterForm.value,
  //               park_name: this.config.data.dialogDetails.park.name,
  //               product: this.selectedPackage,
  //             })
  //             .subscribe(
  //               (res) => {
  //                 console.log('res', res);
  //                 this.paymentLoading = false;

  //                 // send data to status dialog
  //                 this.ref.close({
  //                   ...res.data,
  //                   status: res.status,
  //                   success: true,
  //                   package: this.getPackageDetails(this.selectedPackage),
  //                   customer: this.portalUserRegisterForm.value,
  //                 });
  //               },
  //               (err) => {
  //                 console.log('err', err);
  //                 this.errorMessage =
  //                   err.error.detail.detail + ' - ' + err.error.detail.message;
  //                 this.paymentLoading = false;
  //                 // this.notificationService.failedPortal();
  //               },
  //             );
  //         }
  //       });
  //   } else {
  //     console.log(this.paymentForm);
  //   }
  // }

  onPayAndRegisterSubmit(): void {
    console.log('form values', this.paymentForm.value);
    console.log('form valid', this.paymentForm.valid);

    console.log('form errors', this.paymentForm.errors);

    this.paymentLoading = true;
    this.errorMessage = ''; // Reset error message at the start

    // update the form values

    this.paymentForm.patchValue({
      name: `${this.portalUserRegisterForm.get('first_name')?.value} ${this.portalUserRegisterForm.get('last_name')?.value}`,
      email: this.portalUserRegisterForm.get('email')?.value,
      amount: this.paymentAmount,
    });

    const paymentFormForSubmit = {
      amount: this.paymentAmount,
      currency: 'gbp',
      email: this.portalUserRegisterForm.get('email')?.value,
      first_name: String(
        this.portalUserRegisterForm.get('first_name')?.value || '',
      ).trim(),
      last_name: String(
        this.portalUserRegisterForm.get('last_name')?.value || '',
      ).trim(),
      plan: this.selectedPackage.value,
      mobile_number: String(
        this.portalUserRegisterForm.get('mobile_number')?.value || '',
      ),
      account_code: '',
    };

    if (!this.paymentForm.valid) {
      this.errorMessage = 'Please check the form for errors.';
      this.paymentLoading = false;

      console.log('Form is invalid', this.paymentForm);
      return;
    }
    if (this.paymentForm.valid) {
      // concat observables to ensure the sequence of operations
      this.createPaymentIntent(paymentFormForSubmit)
        .pipe(
          // Step 1: Create Payment Intent
          concatMap((pi) => {
            if (!pi || !pi.client_secret) {
              this.errorMessage = 'Failed to create payment intent';
              this.paymentLoading = false;

              throw new Error('Failed to create payment intent');
            }
            // 2. Confirm card payment
            return this.stripeService.confirmCardPayment(pi.client_secret, {
              payment_method: {
                card: this.card.element,
                billing_details: {
                  name: `${paymentFormForSubmit.first_name} ${paymentFormForSubmit.last_name}`,
                  email: paymentFormForSubmit.email,
                  phone:
                    this.portalUserRegisterForm.get('mobile_number')?.value,
                },
              },
            });
          }),

          concatMap((result: any) => {
            if (result.error) {
              console.error(result.error.message);
              this.errorMessage = result.error.message;
              throw new Error(result.error.message);
            } else if (result.paymentIntent.status !== 'succeeded') {
              throw new Error('Payment was not successful');
            }

            console.log('Payment successful, registering user...');

            // Prepare user registration data
            const userRegistrationData = {
              ...this.portalUserRegisterForm.value,
              park_name: this.config.data.dialogDetails.park.name,
              product: this.selectedPackage,
            };

            return this.ps.buyPackageAndRegisterUser(userRegistrationData);
          }),
          // 3. register user and apply package/profile
          concatMap((res) => {
            if (!res || !res.data) {
              throw new Error('User registration failed');
            }

            console.log(
              'User registered successfully, opening confirmation dialog...',
            );

            // Open dialog with user registration response
            this.ref.close({
              ...res.data,
              status: res.status,
              success: true,
              package: this.getPackageDetails(this.selectedPackage),
              customer: this.portalUserRegisterForm.value,
            });
            return this.pas.login(
              this.portalUserRegisterForm.get('email')?.value,
              this.portalUserRegisterForm.get('password')?.value,
            );
          }),
          // 4. login user after registration
          tap((loginResponse) => {
            if (!loginResponse || !loginResponse.success) {
              throw new Error('Login failed');
            }
            console.log('User logged in successfully');
            this.ref.close({
              success: true,
              package: this.getPackageDetails(this.selectedPackage),
              customer: this.portalUserRegisterForm.value,
            });
          }),
          catchError((err) => {
            this.errorMessage =
              err.message || 'An error occurred during the process.';
            return of(null); // Handle error gracefully
          }),
          finalize(() => {
            this.paymentLoading = false; // Reset loading state
          }),
        )
        .subscribe();
    }
  }

  createPaymentIntent(bodyData: any): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/portal/create-payment-intent`, bodyData)
      .pipe(
        map((res) => {
          console.log('res', res);
          return res;
        }),
      );
  }

  onCardChange(event: StripeCardElementChangeEvent) {
    const displayError = document.getElementById('card-errors');
    if (event.error) {
      displayError!.textContent = event.error.message;
    } else {
      displayError!.textContent = '';
    }
  }

  onCountryChange(event: any) {
    console.log('country change', event);

    this.selectedCountry = this.countries.filter(
      (country: any) => country.name === event.name,
    )[0];

    console.log('selected country', this.selectedCountry);
  }

  getPackageDetails(packageValue: string) {
    // console.log('value selected: ', packageValue);
    let packageDetails = { name: '', value: '', price: 0 };
    packageDetails = this.packages.filter((packageItem) => {
      return packageItem.value === packageValue; // Return true or false based on condition
    })[0];
    // console.log('package details: ', packageDetails);
    this.paymentAmount = packageDetails.price;
    return packageDetails;
  }

  closeRegisterDialog() {
    this.ref.close();
  }
}
