import { Component, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { PortalCustomersService } from "src/app/features/portal/_services/portal-customers.service";
import { CustomerDialogComponent } from "../customer-dialog/customer-dialog.component";
import { DialogService } from "primeng/dynamicdialog";
import { MenuItem } from "primeng/api";
import { UpdateCustomerComponent } from "./update-customer/update-customer.component";
import { ConfirmationService, ConfirmEventType } from "primeng/api";
import { PortalUsersService } from "src/app/features/portal/_services/portal-users.service";
import { Table } from "primeng/table";
import { Validators } from "@angular/forms";
import { TableMetaData } from "src/app/_share/_models/components/table-meta-data.model";

@Component({
	selector: "app-customers",
	templateUrl: "./customers.component.html",
	styleUrls: ["./customers.component.scss"],
})
export class CustomersComponent implements OnInit, OnDestroy {
	customers: any[] = [];
	customers$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	customerQuery$: Subscription = new Subscription();

	isLoading: boolean = true;
	selectedCustomer: any;
	items: MenuItem[] = [];
	isError: boolean = false;
	dataSources: { [index: string]: any } = {};

	metadata: TableMetaData = {
		totalRecords: 0,
		rows: 100,
		first: 0,
		loading: true,
		globalFilter: "",
		sort: "",
		id: "",
	};

	columns: any[] = [
		{ field: "first_name", header: "Customer Name" },
		{ field: "last_name", header: "Customer Surname" },
		{ field: "identification_reference", header: "ID" },
		{ field: "identification_type", header: "ID Type" },
		{ field: "mobile_number", header: "Mobile" },
		{ field: "email", header: "Email" },
		{ field: "complex_building", header: "Complex Building" },
		{ field: "unit_number", header: "Unit Number" },
		{ field: "street_address", header: "Street Address" },
		{ field: "postal_address", header: "Postal Address" },
		{ field: "suburb", header: "Suburb" },
		{ field: "city", header: "City" },
		{ field: "province", header: "Province" },
		{ field: "postal_code", header: "Postal Code" },
		{ field: "active", header: "Active" },
		// { field: 'subscriptions', header: 'Subscriptions' },
	];

	constructor(
		private customersService: PortalCustomersService,
		private dialogService: DialogService,
		private confirmationService: ConfirmationService,
		private portalUserService: PortalUsersService
	) {}

	ngOnInit(): void {
		this.retrievePortalcustomers();
	}

	ngOnDestroy(): void {
		if (this.customers$) {
			this.customers$.unsubscribe();
		}
		if (this.customerQuery$) {
			this.customerQuery$.unsubscribe();
		}
	}

	openMenu(event: any): MenuItem[] {
		return [
			{
				label: "Edit",
				icon: "pi pi-fw pi-pencil",
				command: () => {
					this.showUpdateUserDialog(event);
				},
			},
			{
				label: "Delete",
				icon: "pi pi-fw pi-trash",
				command: () => {
					this.confirmUser(event);
				},
			},
			{
				label: "View",
				icon: "pi pi-fw pi-eye",
				command: () => {
					this.showDialog(event);
				},
			},
		];
	}

	showContextAction($event: any) {
		console.log("context action", $event);
		this.items = this.openMenu($event);
	}
		

	retrievePortalcustomers() {
		this.isLoading = true;

		// if(this.customerQuery$){

		// }

		this.customerQuery$ = this.customersService
			.getPortalCustomers({
				limit: this.metadata.rows,
				offset: this.metadata.first,
				q: this.metadata.globalFilter,
				sort: this.metadata.sort,
				id: this.metadata.id,
			})
			.subscribe(
				(res: any) => {
					console.log("Customers: ", res);

					this.customers = res.data;
					this.customers$.next(this.customers);
					this.metadata.loading = false;
					this.metadata.totalRecords = res.count;
				},
				(error) => {
					console.log("Error ", error);
				}
			);
	}

	getNumberofColumns() {
		return this.columns.length;
	}

	// showContextAction($event: any) {
	// 	console.log("context action", $event);

	// 	switch ($event.action) {
	// 		case "edit":
	// 			this.showUpdateUserDialog($event.rowData);
	// 			break;
	// 		case "delete":
	// 			this.confirmUser($event.rowData);
	// 			break;
	// 		case "view":
	// 			this.showDialog($event.rowData);
	// 			break;
	// 		default:
	// 			break;
	// 	}
	// }

	showDialog(event: any) {
		const ref = this.dialogService.open(CustomerDialogComponent, {
			data: {
				user: event,
			},

			width: "90%",
		});
	}

	showUpdateUserDialog(rowData: any) {
		const ref = this.dialogService.open(UpdateCustomerComponent, {
			data: {
				user: rowData,
			},
			width: "75%",
			contentStyle: {
				margin: "0",
				padding: "0",
				height: "100%",
				overflow: "auto",
			},
		});
	}

	confirmUser($event: any) {
		console.log("Event: ", $event);

		this.confirmationService.confirm({
			message: "Are you sure you want to delete this user?",
			header: "Delete Confirmation",
			icon: "pi pi-info-circle",
			key: "delete",
			accept: () => {
				this.portalUserService.deletePortalUser(this.selectedCustomer.id);
			},
			reject: () => {
				console.log("Delete cancelled");
			},
		});
	}

	clear(table: Table) {
		table.clear();
	}
}
