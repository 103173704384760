import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { TableColumns } from "../../_models/components/table-columns.model";
import { LazyLoadEvent, MenuItem } from "primeng/api";

import { TableMetaData } from "../../_models/components/table-meta-data.model";
import { ApiService } from "src/app/_core/_services/api.service";
import { Menu } from "primeng/menu";
import { Router, ActivatedRoute } from "@angular/router";

interface FilterCondition {
	value: any;
	matchMode: string;
	operator: string;
}

interface Filters {
	[key: string]: FilterCondition[];
}

interface Filter {
	value: string | Date | null;
	matchMode: string;
	operator: string;
}

interface FilterObject {
	[key: string]: Filter[];
}

interface QueryParams {
	first?: number;
	sortField?: string;
	sortOrder?: number;
	filters?: Filters;
	globalFilter?: string | null;
	id?: string;
}

@Component({
	selector: "share-table",
	templateUrl: "./share-table.component.html",
	styleUrls: ["./share-table.component.scss"],
})
export class ShareTableComponent implements OnInit {
	// definitions
	// ===========

	// properties and configuration
	// ----------------------------

	@ViewChild("menu") menu!: Menu;

	@Input() enableContextMenu?: boolean = true;
	@Input() queryParams?: string = ''
	@Input() serviceMenuItem?: MenuItem[] = [] 

	// private
	// -------

	refreshTrigger: any;
	filterValue: string = "";
	filteringFor: string = "";

	private _metaData: TableMetaData = {
		totalRecords: 100,
		rows: 100,
		first: 0,
		loading: true,
		globalFilter: "",
		sort: "",
		id: "",
	};
	@Input() menuItems: MenuItem[] = [];

	

	

	set metaData(value: TableMetaData) {
		this._metaData = value;
		this.totalRecords = value.totalRecords;
		this.metaDataChange.emit(value);
	}

	// inputs
	// ------

	@Input() get metaData(): TableMetaData {
		return this._metaData;
	}
	@Input() data: BehaviorSubject<any> = new BehaviorSubject<any>([]);
	@Input() columns: TableColumns[] = [];

	visibleColumns: any[] = [];

	// outputs
	// -------

	@Output() metaDataChange: EventEmitter<TableMetaData> =
		new EventEmitter<TableMetaData>();
	@Output() selectedRowCMObservable$: EventEmitter<any> =
		new EventEmitter<any>();
	@Output() selectedRowObservable$: EventEmitter<any> = new EventEmitter<any>();

	@Output() selectedRelatedEntityObservable$: EventEmitter<any> =
		new EventEmitter<any>();
	@Output() selectedFilterObservable$: EventEmitter<any> =
		new EventEmitter<any>();

	// public
	totalRecords: number = 0;
	selectedRow: any;
	selectedRowCM: any;
	filter: any;
	loadingRowsNumbers = Array(15)
		.fill(1, 0, 15)
		.map((x, i) => i); // [0,1,2,3,4]

	constructor(
		private api: ApiService,

		private cdr: ChangeDetectorRef,

		private router: Router,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		// Wait for columns to populate
		this.initializeVisibleColumns();

		console.log("data", this.data);
	}

	getTruncatedSelection(): string {
		const selected = this.columns
			.filter((col) => col.header)
			.map((col) => col.header)
			.join(", ");
		return selected.length > 20 ? selected.substring(0, 20) + "..." : selected;
	}

	initializeVisibleColumns(): void {
		// Check if columns exist and have values before setting visibleColumns
		if (this.columns?.length) {
			this.visibleColumns = this.columns.map((col) => col.field);
		} else {
			// If columns are loaded asynchronously, watch for changes
			const columnsWatcher = setInterval(() => {
				if (this.columns?.length) {
					this.visibleColumns = this.columns.map((col) => col.field);
					clearInterval(columnsWatcher);
				}
			}, 100);
		}
	}

	loadDataLazy($event: LazyLoadEvent) {
		this.metaData.loading = true;
		this.data.next([]); // clear the data so that the loading indicator is triggered
		// console.log('loading data - ready to emit', $event);

		const sort = this.convertSortFieldAndOrder(
			$event.sortField || "",
			$event.sortOrder || 1
		);

		if (
			this.metaData.first !== $event.first ||
			this.metaData.rows !== $event.rows ||
			this.metaData.globalFilter !== $event.globalFilter ||
			this.metaData.sort !== sort
		) {
			this.metaDataChange.emit({
				totalRecords: this.totalRecords,
				first: $event.first || 0,
				rows: $event.rows || 0,
				loading: true,
				globalFilter: $event.globalFilter || "",
				sort: sort,
				// filter: this.convertToQueryParamsObject($event.filters as FilterObject),
				filter: [],
				id: "",
			});
		}
	}

	filterMenuItem() {
		console.log("filter menu item clicked", this.filter);
		this.selectedFilterObservable$.emit({
			id: this.filter,
		});
	}

	onRowSelect($event: any) {
		// console.log('the following row has been selected', this.selectedRow);
		this.selectedRowObservable$.emit(this.selectedRow);
	}

	onContextMenuSelect($event: any) {
		// console.log('the following row has been selected', this.selectedRowCM);
		console.log("the following row has been selected", $event);
		
		this.selectedRowCMObservable$.emit($event.data);
	}

	onRowUnselect($event: any) {
		console.log("a row has been unselected");
		this.selectedRowObservable$.emit(null);
	}

	onContextMenu($event: Event) {
		throw new Error("Method not implemented.");
	}

	getNumberOfColumns(): number {
		return this.visibleColumns?.length;
	}

	getFilterInputValue(e: Event): any {
		const inputelement = e.target as HTMLInputElement;
		this.filteringFor = "Filtering for: " + inputelement.value;
		if (inputelement.value === "") {
			this.filteringFor = "";
		}
		return inputelement.value;
	}

	getNestedValue(obj: any, path: string) {
		let returnObject = {
			value: null as any,
			type: "",
			nested: false,
			plural: false,
			pluralField: "",
			items: [] as any[],
		};

		returnObject.value = path
			.split(".")
			.reduce((acc, part) => acc && acc[part], obj);

		returnObject.nested = path.includes(".");

		returnObject.type = typeof returnObject.value;

		if (Array.isArray(returnObject.value)) {
			returnObject.plural = true;
			returnObject.nested = true;
			returnObject.items = returnObject.value;
			returnObject.pluralField = path.split(".")[1];
			returnObject.type = "array";
		} else if (
			typeof returnObject.value === "object" &&
			returnObject.value !== null
		) {
			returnObject.plural = false;
			returnObject.items = [returnObject.value];
			returnObject.type = "object";
		} else if (typeof returnObject.value === "string") {
			// fixme: remove automatic formatting of string to dates
			// const date = new Date(returnObject.value);
			// if (!isNaN(date.getTime())) {
			//   returnObject.value = date;
			//   returnObject.type = 'date';
			// }
		}

		return returnObject;
	}

	getColumnFilterType(col: TableColumns): string {
		// determine type of filter to use and derive type of value
		if (col.type) {
			return col.type;
		} else {
			return "text";
		}
	}

	clearFilterInputValue() {
		const inputelement = document.getElementsByName(
			"globalFilter"
		)[0] as HTMLInputElement;
		inputelement.value = "";
	}

	clearTableFilters(table: any) {
		const activeTab = this.route.snapshot.params["activeTab"];

		this.selectedRow = null;
		this.selectedRowCM = null;
		table.clear();
		this.clearFilterInputValue();
		this.filterValue = "";
		this.filteringFor = "";
		this.queryParams = '';
		this.queryParams = '';

		// clear the query params
		

		this.router.navigate(["/operations/tab", activeTab], {
			queryParams: {}, // Remove all query params
			queryParamsHandling: "merge",
		  });
		this.router.navigate(["/operations/tab", activeTab], {
			queryParams: {}, // Remove all query params
			queryParamsHandling: "merge",
		  });

		// reload table data
		this.metaDataChange.emit({
			totalRecords: this.totalRecords,
			first: 0,
			rows: 100,
			loading: true,
			globalFilter: "",
			sort: "",
			filter: [],
			id: "",
		});
	}

	getAccountSeverity(status: string): string {
		switch (status) {
			case "paid":
				return "success";
			case "unpaid":
				return "danger";
			case "overdue":
				return "warning";
			default:
				return "info";
		}
	}

	getSubscriptionSeverity(status: string): string {
		switch (status) {
			case "active":
				return "success";
			case "suspended_payment":
				return "danger";
			case "cancelled":
				return "warning";
			default:
				return "info";
		}
	}

	getServiceSeverity(status: string): string {
		switch (status) {
			case "active":
				return "success";
			case "cancelled":
				return "danger";
			case "suspended":
				return "warning";
			default:
				return "info";
		}
	}

	unselectRow() {
		this.selectedRow = null; // Ensure this is bound to the table's selection
	}

	convertSortFieldAndOrder(sortField: string, sortOrder: number): string {
		return sortOrder === 1 ? sortField : `-${sortField}`;
	}

	convertToQueryParamsObject(filters: FilterObject | undefined): string[] {
		if (!filters) {
			return [];
		}

		const matchModeMap: { [key: string]: string } = {
			contains: "cn",
			startsWith: "sw",
			equals: "eq",
			notEqual: "ne",
			lessThan: "lt",
			// dateBefore: 'lt',
			greaterThan: "gt",
			// dateGreater: 'gt',
			lessThanOrEqual: "le",
			greaterThanOrEqual: "ge",
			in: "in",
			notIn: "ni",
		};

		const queryParams: string[] = [];

		for (const [field, filterArray] of Object.entries(filters)) {
			for (const filter of filterArray) {
				if (filter.value !== null && matchModeMap[filter.matchMode]) {
					const op = matchModeMap[filter.matchMode];

					const paramValue = `${field}:${op}:${filter.value}`;
					queryParams.push(paramValue);
				}
			}
		}

		// return queryParams;
		return [];
	}

	getSeverityFromBool(booleanValue: boolean) {
		if (booleanValue) {
			return "success";
		} else {
			return "danger";
		}
	}

	getDevicePhysicalStatus(device: any) {
		if (
			device["physical_status"] === "active" ||
			device["device_physical_status"] === "active"
		) {
			return "pi-globe";
		} else if (
			device["physical_status"] === "los" ||
			device["physical_status"] === "los"
		) {
			return "pi-sort-alt-slash";
		} else if (
			device["physical_status"] === "inactive" ||
			device["physical_status"] === "" ||
			device["device_physical_status"] === "inactive" ||
			device["device_physical_status"] === ""
		) {
			return "pi-ban";
		} else {
			return "pi-ban";
		}
	}

	getDevicePhysicalStatusColor(device: any) {
		if (
			device["physical_status"] === "active" ||
			device["device_physical_status"] === "active"
		) {
			return "green";
		} else if (
			device["physical_status"] === "los" ||
			device["physical_status"] === "los"
		) {
			return "orange";
		} else if (
			device["physical_status"] === "inactive" ||
			device["physical_status"] === "" ||
			device["device_physical_status"] === "inactive" ||
			device["device_physical_status"] === ""
		) {
			return "red";
		} else {
			return "red";
		}
	}

	onNestedObjectClick(entity: any, path: string) {
		console.log("nested object clicked");
		console.log("entity", entity);
		console.log("path", path);
		this.selectedRelatedEntityObservable$.emit({
			entity: entity,
			path: path,
		});
	}

	getPluralNestedField(item: any) {
		if (item["name"]) {
			return item["name"];
		} else if (item["model"]) {
			return item["model"];
		} else {
			return;
		}
	}

	downloadFile(toc_file_reference: string) {
		//console.log(toc_file_reference);
		this.api
			.getFileAPI("download-file", toc_file_reference)
			.subscribe((response: any) => {
				// //console.log(data);
				// this.convertEncodingToFile(data);
				const mimetype = "application/pdf";
				const file = response.body;
				const downloadFile = new File([file], `${toc_file_reference}.pdf`, {
					type: mimetype,
				});
				const url = window.URL.createObjectURL(downloadFile);
				window.open(url, "_blank");
				URL.revokeObjectURL(url);
			});
	}
}
