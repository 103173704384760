<form
	[formGroup]="myForm"
	(ngSubmit)="onFormSubmit()"
>
	<div class="surface-section px-4 py-4 md:px-6 lg:px-8">
		<div class="grid formgrid p-fluid">
			<div
				class="field mb-4 col-12 lg:col-6"
				*ngFor="let control of jsonForm?.controls"
			>
				<div *ngIf="control.type !== 'checkbox'">
					<label [for]="control.name">{{ control.label }}</label>
				</div>

				<p-fileUpload
					#toc_file
					[id]="control.name"
					[formControlName]="control.name"
					*ngIf="control.type === 'file'"
					mode="advanced"
					chooseLabel="Choose"
					[auto]="false"
					[name]="control.name"
					accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
					[maxFileSize]="10000000"
					(onUpload)="onUpload($event)"
					[customUpload]="true"
					(uploadHandler)="uploadFile($event)"
				>
					<ng-template pTemplate="content">
						<div class="text-500 font-medium mb-3">Uploaded Files</div>
						<div
							class="flex md:align-items-center md:justify-content-between surface-border flex-column md:flex-row"
							*ngIf="uploadedFile"
						>
							<div class="flex align-items-center">
								<span class="block pi pi-file mr-2"></span>
								<span class="text-900"
									>{{ uploadedFile.name | truncateFilename : 50 }} ({{
										uploadedFile.size | filesize
									}}
									)</span
								>
							</div>
						</div>
					</ng-template>
				</p-fileUpload>

				<p-dropdown
					[id]="control.name"
					*ngIf="control.type === 'select'"
					[options]="dropdownOptions[control.name]"
					optionLabel="label"
					optionValue="value"
					placeholder="Select a {{ control.label }}"
					[formControlName]="control.name"
					[filter]="true"
					[showClear]="true"
					appendTo="body"
				>
					<ng-template
						pTemplate="filter"
						let-options="options"
					>
						<div class="flex gap-1">
							<div
								class="p-inputgroup"
								(click)="$event.stopPropagation()"
							>
								<span class="p-inputgroup-addon"
									><i class="pi pi-search"></i
								></span>
								<input
									pInputText
									placeholder="Search"
									type="text"
									(keyup)="filter($event, control.name, options)"
								/>
							</div>
						</div>
					</ng-template>
				</p-dropdown>

				<p-multiSelect
					[id]="control.name"
					*ngIf="control.type === 'multiselect'"
					[options]="dropdownOptions[control.name]"
					[formControlName]="control.name"
					optionLabel="label"
					optionValue="value"
					display="chip"
					placeholder="Select {{ control.label }}"
					appendTo="body"
				>
				</p-multiSelect>
				<!-- todo: form field errors -->

				<p-inputSwitch
					[id]="control.name"
					*ngIf="control.type === 'toggle'"
					[formControlName]="control.name"
				></p-inputSwitch>

				<p-inputMask
					[id]="control.name"
					*ngIf="control.type === 'text_masked'"
					[formControlName]="control.name"
					mask="**:**:**:**:**:**"
					placeholder="99:BB:CC:DD:EE:88"
					characterPattern="[a-fA-F]"
				></p-inputMask>

				<p-inputNumber
					[id]="control.name"
					*ngIf="control.type === 'number'"
					[formControlName]="control.name"
				></p-inputNumber>

				<input
					[id]="control.name"
					pInputText
					*ngIf="control.type === 'text'"
					[formControlName]="control.name"
				/>
				<small
					*ngIf="
						myForm.controls[control.name].hasError('required') &&
						myForm.controls[control.name].touched &&
						myForm.controls[control.name].invalid
					"
					[id]="control.name"
					class="p-error"
					>This field is required</small
				>
				<!-- <small
          *ngIf="
            myForm.controls[control.name].hasError('pattern') &&
            myForm.controls[control.name].touched &&
            myForm.controls[control.name].invalid
          "
          [id]="control.name"
          class="p-error"
          >This field is not valid</small
        > -->
				<small
					*ngIf="myForm.controls[control.name].hasError('minlength')"
					[id]="control.name"
					class="p-error"
					>This field is not the required length - should be at least
					{{
						myForm.controls[control.name].errors!["minlength"]["requiredLength"]
					}}
					characters long</small
				>

				<!-- {{ myForm.controls[control.name].errors | json }} -->

				<textarea
					[id]="control.name"
					pInputTextarea
					[rows]="5"
					*ngIf="control.type === 'textarea'"
					[formControlName]="control.name"
				></textarea>

				<p-checkbox
					[id]="control.name"
					*ngIf="control.type === 'checkbox'"
					[binary]="true"
					[value]="false"
					[label]="control.label"
					[formControlName]="control.name"
				></p-checkbox>

				<p-slider
					[id]="control.name"
					*ngIf="control.type === 'range'"
					[min]="control.options.min"
					[max]="control.options.max"
					[formControlName]="control.name"
				></p-slider>

				<p-calendar
					[id]="control.name"
					*ngIf="control.type === 'date'"
					[showIcon]="true"
					[formControlName]="control.name"
					[showTime]="false"
					dateFormat="yy-mm-dd"
					dataType="string"
					appendTo="body"
				></p-calendar>

				<p-calendar
					[id]="control.name"
					*ngIf="control.type === 'datetime'"
					[showIcon]="true"
					[formControlName]="control.name"
					[showTime]="true"
					dateFormat="yy-mm-dd"
					appendTo="body"
				></p-calendar>
			</div>
		</div>
		<p-button type="submit">Submit</p-button>
	</div>
</form>
